import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import MovieCard from '../../components/Movies/MovieCard';
import { ListContents, Grid, TitleList, WithoutContent, CardWrapper } from './styles';
import { getAlbums } from '../../services/trail'
import EmptyList from '../../assets/icon-empty.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import GridSkeleton from '../../components/Commons/GridSkeleton';
import { getContentTag, getTrailTag } from '../../services/tags';
import { Mobile } from 'utils/responsiveRules';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';

const NoContent = () => (
	<WithoutContent>
		<div>
			<img src={EmptyList} alt="" />
		</div>
		<p>Nenhum conteúdo a esta categoria no momento. Navegue pelo catálogo para encontrar mais conteúdos.</p>
	</WithoutContent>
);

export default function Album() {
	const [Albums, setAlbum] = useState<any>();
	const getData = async () => {
		const trail = await getAlbums();
		setAlbum(trail);
	};
	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<NavBar />
			<ListContents>
				<TitleList>Albuns</TitleList>
				{!Albums && <GridSkeleton />}
				{Albums?.length <= 0 && <NoContent />}
				<Grid>
					{Albums &&
						Albums.map((course: any, key: any) => {
							if (course?.is_library) {
								return (
									<CardWrapper>
										<MovieCard to={`/movie/${course.content_id}`} data={course} key={`course-${key}`} />
									</CardWrapper>
								);
							} else {
								return (
									<CardWrapper>
										<MovieCard to={`/trail/${course.trail_id}`} data={course} key={`course-${key}`} />
									</CardWrapper>
								);
							}
						})}
				</Grid>
			</ListContents>
			<Footer />
			<Mobile>
				<MobileNavigatorSpace />
			</Mobile>
		</>
	);
}
