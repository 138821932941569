import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';

import { FiHome, FiSearch } from 'react-icons/fi';
import { HiOutlineUser } from 'react-icons/hi';

import { getDefaultToken } from 'services/userToken';
import { ContextRoute } from 'utils/context';
import { Desktop, Mobile } from '../../../utils/responsiveRules';

import Logo from '../../../assets/logo-negativa.svg';
import LogoKids from '../../../assets/logo-kids.svg';
import Search from '../../../assets/icon-search.svg';
import User from '../../../assets/icon-user-negative.svg';
import { Nav, NavLogo, NavSearch, ButtonWrapper, NavButton, SubMenu, Navigator, MobileHeader } from './styles';

export default function HeaderKids({ home = false }: { home?: boolean }) {
  const [searchTerm, setSearchedTerm] = useState('');
  const [showUserMenu, setShowUserMenu] = useState(false);
  const history = useHistory();
  const { term, pathname } = useParams<any>();
  const { handleIsKidFalse } = useContext(ContextRoute);
  const user = localStorage.getItem('user') || '{}';

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (searchTerm) history.replace(`/kids-search/${searchTerm}`);
  };
  const updateTerm = (newTerm: string) => {
    setSearchedTerm(newTerm);
  };
  const handleDefaultProfile = async () => {
    const { user_id } = JSON.parse(user);
    const { api_token } = await getDefaultToken(user_id);
    localStorage.setItem('api_token', api_token);
    localStorage.removeItem('kids');
    handleIsKidFalse();
    history.push('/home');
  };

  const handleLogout = () => {
    localStorage.removeItem('api_token');
    localStorage.removeItem('user');
    localStorage.removeItem('kids');
    localStorage.removeItem('payment-token-teo');
    history.push('/');
  };
  useEffect(() => {
    if (term) updateTerm(term);
  }, [term]);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Desktop>
        <Nav isScrolled={isScrolled}>
          <div>
            <NavButton onClick={handleDefaultProfile}>
              <img src={Logo} alt="TeoMídia" />
            </NavButton>
            <img src={LogoKids} alt="Kids" />
          </div>
          <div>
            <NavSearch onSubmit={(e) => handleSearch(e)}>
              <img src={Search} alt="" />
              <input
                type="text"
                placeholder="Busca"
                value={searchTerm}
                onChange={(e) => setSearchedTerm(e.target.value)}
              />
            </NavSearch>
            <ButtonWrapper>
              <NavButton onClick={() => setShowUserMenu(!showUserMenu)}>
                <img src={User} alt="Icon usuário" />
                {showUserMenu && (
                  <SubMenu>
                    <button type="button" onClick={handleDefaultProfile}>
                      Ativar perfil padrão
                    </button>
                    <button onClick={handleLogout}>Sair</button>
                  </SubMenu>
                )}
              </NavButton>
            </ButtonWrapper>
          </div>
        </Nav>
      </Desktop>
      <Mobile>
        <MobileHeader>
          <NavButton onClick={handleDefaultProfile}>
            <img src={Logo} alt="TeoMídia" />
          </NavButton>
          <img src={LogoKids} alt="Kids" />
        </MobileHeader>
        <Navigator>
          <ul>
            <li className={history.location.pathname === '/kids' && !showUserMenu ? 'active' : ''}>
              <Link to="/kids">
                <FiHome className={history.location.pathname === '/kids' && !showUserMenu ? 'active' : ''} />
                <span className={history.location.pathname === '/kids' && !showUserMenu ? 'active' : ''}>Home</span>
              </Link>
            </li>
            <li className={history.location.pathname === '/kids-search' && !showUserMenu ? 'active' : ''}>
              <Link to="/kids-search">
                <FiSearch className={history.location.pathname === '/kids-search' && !showUserMenu ? 'active' : ''} />
                <span className={history.location.pathname === '/kids-search' && !showUserMenu ? 'active' : ''}>
                  Busca
                </span>
              </Link>
            </li>
            <li className={showUserMenu ? 'active' : ''}>
              <NavButton onClick={() => setShowUserMenu(!showUserMenu)}>
                <HiOutlineUser className={showUserMenu ? 'active' : ''} />
                <span className={showUserMenu ? 'active' : ''}>Conta</span>
                {showUserMenu && (
                  <SubMenu>
                    <button type="button" onClick={handleDefaultProfile}>
                      Ativar perfil padrão
                    </button>
                    <button onClick={handleLogout}>Sair</button>
                  </SubMenu>
                )}
              </NavButton>
            </li>
          </ul>
        </Navigator>
      </Mobile>
    </>
  );
}
