import httpClient from '../../http';

const getKidsToken = async (userId: object) => {
  const kidsToken = (await httpClient.patch(`/users/kids`)).data;

  return kidsToken;
};

const getDefaultToken = async (userId: object) => {
  const defaultToken = (await httpClient.patch(`/users/padrao`)).data;
  return defaultToken;
};


const renewToken = async () => {
  const renewToken = (await httpClient.patch(`/cognito/renew-token`)).data;
  return renewToken;
}

export { getKidsToken, getDefaultToken, renewToken };
