import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';

const Results = styled.div`
  background-color: #fff;
  @media screen and (min-width: 998px) {
    padding: ${px2rem(200)} 0 ${px2rem(300)};
  }
`;

const NoResultsWrapper = styled.div`
  background-color: #fff;
  padding: ${px2rem(200)} 0 ${px2rem(300)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    width: ${px2rem(180)};
    height: ${px2rem(180)};
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    color: #000;
    font-size: ${px2rem(24)};
    font-weight: bold;
    margin: ${px2rem(60)} 0 ${px2rem(10)};
    strong {
      color: var(--default-orange);
    }
  }
  small {
    color: #000;
    font-size: ${px2rem(18)};
  }

  a {
    width: ${px2rem(350)};
    height: ${px2rem(56)};
    margin-top: ${px2rem(80)};
    border-radius: 8px;
    background-color: var(--default-orange);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: #fff;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    p {
      margin: 40px 24px 16px;
      text-align: center;
      font-size: 16px;
    }

    small {
      margin: 0 93px;
      text-align: center;
      padding-bottom: 25px;
      font-size: 12px;
    }

    div {
      margin-top: 20px;
    }

    a {
      margin: 0 0 30px;
      width: 90%;
    }
  }
`;

const WrapperMobile = styled.div`
  background-color: #fff;
  padding: 30px 20px 30px;
  h1 {
    font-size: 24px;
    color: #000;
    margin-bottom: 45px;
  }
`;
const SearchInput = styled.form`
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 0 ${px2rem(20)};
  height: ${px2rem(40)};
  border-radius: ${px2rem(20)};
  input {
    background-color: transparent;
    border: none;
    font-family: 'Lato';
    outline: none;
    color: #000;
    width: 100%;
    height: 100%;
    padding: 0 ${px2rem(10)};
  }
  img {
    width: ${px2rem(19)};
  }
  @media screen and (min-width: 998px) {
    width: ${px2rem(332)};
    margin: 0 ${px2rem(70)} 0 ${px2rem(10)};
  }

  @media screen and (max-width: 480px) {
    input {
      font-size: 16px;
    }
  }
`;

const SearchedTerms = styled.div`
  width: 80%;
  margin: 0 auto ${px2rem(80)};
  p {
    color: #000;
    font-size: ${px2rem(14)};
    line-height: ${px2rem(40)};
    strong {
      color: var(--default-orange);
    }
  }
  small {
    color: #000;
    font-size: ${px2rem(14)};
    a {
      text-decoration: underline;
      color: #000;
    }
  }
  @media screen and (min-width: 998px) {
    p {
      font-size: ${px2rem(24)};
    }
    small {
      font-size: ${px2rem(18)};
    }
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 24px;
  > div,
  a {
    width: 100%;
    min-height: 200px;
    margin-bottom: 40px;
  }
  @media screen and (min-width: 998px) {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${px2rem(25)};
    row-gap: ${px2rem(50)};
    padding: 0;
    > div,
    a {
      width: 100%;
      max-height: ${px2rem(250)};
      margin: 0;
    }
  }
`;

export { Results, NoResultsWrapper, SearchedTerms, Grid, WrapperMobile, SearchInput };
