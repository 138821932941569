import { PlansWrapper, Plan, PlanName, PlanBenefits, PlanPrice, BoxModelPremium } from './style';
import IconArrowBack from '../../../assets/icon-arrow-back.svg';
import PlanCheck from '../../../assets/check.svg';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloseIcon from '../../../assets/icon-plus.svg';
import { getAllPlans } from 'services/Plans';
import { useContext, useEffect, useState } from 'react';
import PlanModelProps from 'services/Plans/models/plan-interface';
import { formatPrice } from 'utils/formatPrice';
import { checkoutPayment } from 'services/payment';
import { ContextBePremiumModal } from 'utils/context';
import Loading from 'components/Loading';

export default function BePremiumModal() {
  const [plans, setPlans] = useState([] as PlanModelProps[]);
  const [isLoading, setIsLoading] = useState(false);
  const { setOpenModalPremium } = useContext(ContextBePremiumModal);

  const history = useHistory();

  const getPlans = async () => {
    const resultPlans: PlanModelProps[] = (await getAllPlans()).filter((plan) => plan.name.includes('Premium'));

    setPlans(resultPlans);
  };

  const handleSelectPlan = async (plan: string) => {
    setIsLoading(true);
    await checkoutPayment()
      .then((data) => {
        if (data.success === true) {
          // toast.success('Você receberá um link para pagamento da assinatura em seu email em breve...');

          window.open(data.url, '_blank');
        } else if (data.success === false) {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        toast.error('Sistema de pagamentos indisponível no momento, tente novamente mais tarde.');
      });
    setIsLoading(false);
    setOpenModalPremium(false);
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <BoxModelPremium>
      {isLoading ? (
        <Loading />
      ) : (
        <PlansWrapper>
          {plans?.map((plan) => {
            return (
              <Plan>
                <div className="header-container">
                  <label className="container">
                    <input type="radio" className="checked" name="radio" onChange={() => handleSelectPlan(plan.name)} />
                    <span className="checkmark"></span>
                  </label>
                  <button
                    className="cancelButton"
                    onClick={() => {
                      setOpenModalPremium(false);
                    }}
                  >
                    <img src={CloseIcon} alt="" />
                  </button>
                </div>

                <PlanName>{plan.name}</PlanName>
                <PlanPrice>{plan.name === 'Básico' ? 'Gratuito' : formatPrice(plan.price)}</PlanPrice>
                <PlanBenefits>
                  {plan.benefits?.map((benefit) => {
                    return (
                      <li>
                        <img src={PlanCheck} alt="" />
                        {benefit}
                      </li>
                    );
                  })}
                </PlanBenefits>
              </Plan>
            );
          })}
        </PlansWrapper>
      )}
    </BoxModelPremium>
  );
}
