import { Grid } from './style';
import Skeleton from '../../Skeleton';
import { useLocation } from 'react-router';

export default function GridSkeleton({ light = false }) {
  const { pathname } = useLocation();

  return (
    <Grid>
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
      <Skeleton isKids={pathname === '/kids'} light={light} />
    </Grid>
  );
}
