import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil'

const HeaderWrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 18px;
  img{ width: 72px; }
  a{
    color: #FFF;
    border: 1px solid #FFF;
    border-radius: 8px;
    padding: ${px2rem(16)} ${px2rem(24)};
    font-size: 12px;
    text-decoration: none;
  }

  @media screen and (min-width: 998px){
    img{  width: ${px2rem(200)}; }
    padding: ${px2rem(32)};
    a{ font-size: ${px2rem(18)}; }

  }

  @media screen and (max-width: 480px) {
    &:not(.hasLogin) {
      justify-content: center;
    }

    img {
      height: 28px;
      width: 120px;
    }
  }
`;

export { HeaderWrapper }