import { useContext, useEffect, useState } from 'react';
import { Items } from './styles';
import NavBar from '../../components/Commons/NavBar';
import Footer from '../../components/Commons/Footer';
import CardAuthor from '../../components/Authors/CardAuthor';
import AuthorCarousel from '../../components/Authors/AuthorCarousel';
import { getReputedAuthors } from '../../services/authors';
import { Author } from '../../services/authors/models/author-interface';
import HighlightedContent from './HighlightedContent';
import MostWatched from './MostWatched';
import Watching from './Watching';
import WatchAgain from './WatchAgain';
import HomeCategories from './HomeCategories';
import HighlightedCommemorativeContent from './HighlightedCommemorativeContent';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';
import { ContextBePremiumModal } from 'utils/context';
import BePremiumModal from 'components/Modals/BePremiumSmallSize';
import useTotalViewd from 'hooks/useTotalViewd';

export default function Home() {
  const [authors, setAuthors] = useState<Array<Author>>([]);

  const { openModalPremium } = useContext(ContextBePremiumModal);
  const { clearTimeAtHome } = useTotalViewd();

  const getAllContent = async () => {
    const resultAuthors = await getReputedAuthors();
    setAuthors(resultAuthors);
  };

  useEffect(() => {
    getAllContent();
    clearTimeAtHome();
  }, []);

  return (
    <>
      {openModalPremium && <BePremiumModal />}
      <NavBar />
      <HighlightedContent />
      <HighlightedCommemorativeContent />
      <Watching />
      <MostWatched />
      <WatchAgain />
      {/* {authors.length > 0 && (
        <Items>
          <AuthorCarousel title="Palestrantes">
            {authors?.map((author, key) => (
              <CardAuthor key={key} data={author} />
            ))}
          </AuthorCarousel>
        </Items>
            )} */}
      <HomeCategories />
      <Footer />
      <MobileNavigatorSpace />
    </>
  );
}
