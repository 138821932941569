import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/logo.svg';
import Facebook from '../../../assets/icon-facebook.svg';
import Instagram from '../../../assets/icon-instagram.svg';
import Twitter from '../../../assets/icon-twitter.svg';
import LogoBlog from '../../../assets/logo-blog.png';
import LogoCast from '../../../assets/logo-cast.png';
import LogoRadio from '../../../assets/logo-radio.png';
import { SiYoutube } from 'react-icons/si';
import { FooterWrapper, SectionLogos } from './style';

export default function Footer() {
  const [date, setDate] = useState<number>();

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  return (
    <>
      <FooterWrapper>
        <div>
          <img src={Logo} alt="" />
          <span>© TEOmídia {date} | Todos os Direitos Reservados</span>
        </div>
        <div className="links">
          <SectionLogos>
            <div>
              <a href="http://www.teomidia.blog.br/" target="_blank" rel="noreferrer" title="Logo Blog Teomídia">
                <img src={LogoBlog} alt="Logo Blog Teomídia" className="blog" />
              </a>
              <a href="https://www.teomidiacast.org/" target="_blank" rel="noreferrer" title="Logo Teomídia Cast">
                <img src={LogoCast} alt="Logo Teomídia Cast" className="cast" />
              </a>

              <a href="http://teomidia.radio.br/" target="_blank" rel="noreferrer" title="Logo Teomídia Rádio">
                <img src={LogoRadio} alt="Logo Teomídia Rádio" className="radio" />
              </a>
            </div>
          </SectionLogos>
          <ul>
            <li>
              <Link to="/termos-de-uso" title="Termos de uso">
                Termos de uso
              </Link>
            </li>
            <li>
              <Link to="/politica-de-privacidade" title="Política de Privacidade">
                Política de Privacidade
              </Link>
            </li>
            <li>
              <a href="mailto:sac@teomidia.com">sac@teomidia.com</a>
            </li>
          </ul>
        </div>
        <div className="social">
          <a href="https://www.facebook.com/Teomidia/" target="_blank" rel="noreferrer" title="Facebook">
            <img src={Facebook} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/teomidia/" target="_blank" rel="noreferrer" title="Instagram">
            <img src={Instagram} alt="Instagram" />
          </a>
          <a href="https://twitter.com/teomidiavideos" target="_blank" rel="noreferrer" title="Twitter">
            <img src={Twitter} alt="Twitter" />
          </a>

          <a href="https://www.youtube.com/teomidia" target="_blank" rel="noreferrer" title="Youtube">
            <span>
              <SiYoutube />
            </span>
          </a>
        </div>
      </FooterWrapper>
    </>
  );
}
