import NavBar from '../../components/Commons/NavBar';
import { Wrapper, ContainerImage, ListContents, SeasonSelector, TitleList, WrapperSeason } from './style';
import Footer from '../../components/Commons/Footer';
import { useParams } from 'react-router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getContent } from '../../services/contents';
import MovieHeader from '../../components/Movies/MovieHeader';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getCourse } from '../../services/courses';
import { getTrails } from '../../services/trail';
import MovieCard from '../../components/Movies/MovieCard';
import RelatedCarousel from '../../components/Commons/RelatedCarousel';
import { getSimilar } from '../../services/search';
import { Spacer } from 'components/Commons/MobileNavigatorSpace/style';
import { Desktop, Mobile } from 'utils/responsiveRules';

import BePremiumModal from 'components/Modals/BePremiumSmallSize';
import { ContextBePremiumModal } from 'utils/context';
import SeasonEpisodeCard from 'components/Movies/SeasonEpisodeCard';
import { IEpisodeData } from './types';
import GridSkeleton from 'components/Commons/GridSkeleton';
import { SkeletonContainer } from 'pages/DetailKids/style';
import HomeSlider from 'components/Home/HomeSlider';

export default function Detail() {
  const [data, setData] = useState<any>();
  const [currentSeason, setCurrentSeason] = useState<any>(0);
  const [similar, setSimilarContent] = useState<any>();

  const { openModalPremium, setOpenModalPremium } = useContext(ContextBePremiumModal);

  const { id, trail } = useParams<any>();
  const { path } = useRouteMatch();
  const {
    location: { pathname },
  } = useHistory();

  const getSimilarData = async () => {
    const categories = data?.categories?.map((category: any) => category.id);
    const similar = await getSimilar(categories);
    setSimilarContent(similar);
  };

  const getMovieDetail = useCallback(
    async (id: string, trail?: string) => {
      if (path === '/movie/:id/:trail?') {
        const data = await getContent(id, trail);
        setData(data);
      }
      if (path === '/course/:id') {
        const data = await getCourse(id);
        setData(data);
      }
      if (path === '/trail/:id') {
        const data = await getTrails(id);
        setData(data);
      }
    },
    [path]
  );

  const userCanAccessContent = useMemo(() => {
    if(!data?.info?.premium || data?.user?.premium) {
      return true
    }
    
    return false;
  }, [data]);

  useEffect(() => {
    if (id) {
      getMovieDetail(id, trail);
      getSimilarData();
    }
  }, [id, trail]);

  return (
    <Wrapper>
      <NavBar />
      {openModalPremium && <BePremiumModal />}
      {!data && (
        <SkeletonContainer>
          <GridSkeleton />
        </SkeletonContainer>
      )}
      {data && (
        <>
          <Desktop>
            <ContainerImage image={data?.trail_thumbnail || data?.info?.banner_url || data.thumbnail}>
              <MovieHeader data={data} />
            </ContainerImage>
          </Desktop>
          <Mobile>
          <ContainerImage image={data?.trail_thumbnail_mobile || data.thumbnail_mobile}>
              <MovieHeader data={data} />
            </ContainerImage>
          </Mobile>
        </>
      )}
      {data?.courses && (
        <ListContents>
          <TitleList>Mais episódios</TitleList>
          <SeasonSelector onChange={(e) => setCurrentSeason(e.target.value)} value={currentSeason}>
            {data?.courses
              ?.sort((a: any, b: any) => {
                if (a.position < b.position) {
                  return -1;
                }
                if (a.position > b.position) {
                  return 1;
                }
                return 0;
              })
              ?.map((season: any, key: any) => (
                <option value={key}>{season.title}</option>
              ))}
          </SeasonSelector>
          <Desktop>
            {data && (
              <RelatedCarousel title="Mais episódios">
                {data &&
                  data?.courses[currentSeason]?.contents
                    ?.sort((a: { position: number }, b: { position: number }) => {
                      if (a.position < b.position) {
                        return -1;
                      }
                      if (a.position > b.position) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.map((content: IEpisodeData) => {
                      const cardProps = userCanAccessContent 
                        ? { to: `/show/${data?.trail_id}/season/${content?.course_id}/episode/${content.content_id}` }
                        : { onClick: () => setOpenModalPremium(true) };

                      return (
                        <MovieCard
                          width={305}
                          info={true}
                          tail
                          data={content}
                          key={`movie-${content.content_id}`}
                          trailThumbnail={data?.trail_id}
                          {...cardProps}
                        />
                      );
                    })}
              </RelatedCarousel>
            )}
          </Desktop>
          <Mobile>
            {data &&
              data?.courses[currentSeason]?.contents
                ?.sort((a: { position: number }, b: { position: number }) => {
                  if (a.position < b.position) {
                    return -1;
                  }
                  if (a.position > b.position) {
                    return 1;
                  }
                  return 0;
                })
                .map((content: IEpisodeData) => {
                  return (
                    <SeasonEpisodeCard
                      content={content}
                      key={`movie-${content.content_id}`}
                      to={
                        !data?.user?.premium && data?.info?.premium
                          ? pathname
                          : `/show/${data?.trail_id}/season/${content?.course_id}/episode/${content.content_id}`
                      }
                      trailThumbnail={data?.trail_id}
                    />
                  );
                })}
          </Mobile>
        </ListContents>
      )}
      <WrapperSeason>
        <HomeSlider title="Títulos Semelhantes" data={similar} />
      </WrapperSeason>

      <Footer />
      <Spacer />
    </Wrapper>
  );
}
