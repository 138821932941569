import styled, { css } from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface Props {
  bookmark?: boolean;
}
const AddToList = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${px2rem(15)} ${px2rem(20)};
  border: 1px solid #fff;
  border-radius: ${px2rem(8)};
  background-color: transparent;
  font: bold 16px 'Lato', sans-serif;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;

  p {
    white-space: nowrap;
    font-size: ${px2rem(16)};
  }

  img {
    margin-right: 5px;
  }

  ${(props) =>
    props.bookmark
      ? css`
          border-color: var(--default-orange);
          color: var(--default-orange);
          img {
            width: ${px2rem(20)};
          }
        `
      : css`
          border-color: #fff;
          color: #fff;
        `}

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }
`;

export { AddToList };
