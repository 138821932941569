import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const AppWrapper = styled.div`
  background-color: var(--default-background);
  padding-top: ${px2rem(40)};

  > div {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding: 0 20px;
    h3 {
      color: #fff;
      text-align: center;
      font-size: ${px2rem(24)};
    }
    > img {
      width: 100%;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: ${px2rem(48)};
      a:first-of-type {
        margin-right: ${px2rem(16)};
      }
      a img {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 998px) {
    max-width: 100%;

    > div {
      flex-direction: row;
      gap: ${px2rem(74)};
      > img {
        width: ${px2rem(777)};
      }
      h3 {
        width: ${px2rem(333)};
        font-size: ${px2rem(32)};
        text-align: inherit;
      }
      .buttons {
        flex-direction: row;
        a img {
          width: ${px2rem(216)};
        }
      }
    }
  }
`;

export { AppWrapper };
