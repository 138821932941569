import httpClient from '../../http';

const getTrailTag = async (tag: string) => {
  const trailTag = (
    await httpClient.get('/trails', {
      params: {
        tags: tag,
        is_active: true,
      },
    })
  ).data;
  return trailTag;
};

const getContentTag = async (tag: string) => {
  const contentTag = (
    await httpClient.get('/contents', {
      params: {
        tag,
        is_active: true,
      },
    })
  ).data;
  return contentTag;
};

export { getTrailTag, getContentTag };
