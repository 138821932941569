import httpClient from '../../http';
import UserRegister from './models/user-register-interface';

const login = async (email: string, password: string) => {
  const loginResult = (
    await httpClient.post('/cognito/login', {
      email,
      password,
    })
  ).data;
  return loginResult;
};

const recoverPassword = async (email: string) => {
  const recoverResult = (
    await httpClient.post('/cognito/forgot-password', {
      email,
    })
  ).data;

  return recoverResult;
};

const confirmNewPassword = async (email: string, password: string, code: string) => {
  const newPasswordResult = (
    await httpClient.post('/cognito/confirm-new-password', {
      email,
      password,
      code,
    })
  ).data;

  return newPasswordResult;
};

const registerUser = async (userData: UserRegister) => {
  const register = (await httpClient.post('/cognito/signup', userData)).data;
  return register;
};

const verifyCodeOfUserEmail = async (email: string, code: string) => {
  const data = {
    email,
    code,
  };

  const verifyResponse = (await httpClient.post('/cognito/verify-code', data)).data;

  return verifyResponse;
};

const changePassword = async (body : {old_password: string, new_password: string}) => {
  const data = (await httpClient.post("/cognito/change-password", body))
    .data;
  return data;
};

export { login, recoverPassword, registerUser, verifyCodeOfUserEmail, changePassword, confirmNewPassword };
