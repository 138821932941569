import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';
import ArrowRight from '../../../assets/arrow-right.svg';
import ArrowLeft from '../../../assets/arrow-left.svg';
import { Link } from 'react-router-dom';

interface IsKids {
  isKids?: boolean;
}

const Wrapper = styled.div`
  .image-item {
    padding-left: 24px;
  }
`;

const WrapperHeader = styled.div`
  padding: 0 ${px2rem(24)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CarouselTitle = styled.h1`
  font-size: ${px2rem(16)};
  margin-bottom: ${px2rem(10)};
  color: #fff;
  font-weight: 700;

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(40)};
    margin-bottom: ${px2rem(20)};
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
    padding-bottom: 8px;
  }
`;

const SeeAll = styled(Link)`
  color: var(--default-orange);
  text-decoration: underline;
  font-size: ${px2rem(16)};
`;

const RightArrow = styled.button<IsKids>`
  right: 0;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) -2.54%, rgba(0, 0, 0, 0.3) 64.44%), url(${ArrowRight});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0;
  min-width: ${px2rem(60)};
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 1000;
  border: 0;
  min-width: 80px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
`;
const LeftArrow = styled.button<IsKids>`
  left: 0;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) -2.54%, rgba(0, 0, 0, 0.3) 64.44%), url(${ArrowLeft});
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0;
  min-width: ${px2rem(60)};
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 1000;
  border: 0;
  min-width: 80px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
`;

export { Wrapper, WrapperHeader, CarouselTitle, SeeAll, LeftArrow, RightArrow };
