import { Wrapper, FlowTitle, Form, Grid, RightContent, LeftContent } from '../style';
import IconArrowBack from 'assets/icon-arrow-back.svg';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { RecoverSchema } from './recoverValidation';
import { recoverPassword } from 'services/cognito';
import { toast } from 'react-toastify';

export default function RecoverPasswordBlock() {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: RecoverSchema,
    validateOnMount: true,
    onSubmit: async ({ email }) => {
      await recoverPassword(email)
        .then(() => {
          history.push('/recover/new-password', {
            email,
          });
        })
        .catch(() => {
          toast.error('Falha ao enviar email de recuperação de senha');
        });
    },
  });

  return (
    <Wrapper>
      <Grid>
        <RightContent>
          <Link to="/" className="back backArrow">
            <img src={IconArrowBack} alt="" />
          </Link>
          <FlowTitle className='title'>
            Recupere
            <br /> sua senha.
          </FlowTitle>
        </RightContent>
        <LeftContent>
          <hr />
          <Form onSubmit={formik.handleSubmit}>
            <div className="default-input">
              <input
                name="email"
                type="email"
                placeholder="E-mail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.errors.email && formik.touched.email ? 'error' : ''}
              />
              <label>E-mail</label>
              {formik.touched.email && formik.errors.email && <span className="error">{formik.errors.email}</span>}
            </div>
            <div className="buttons">
              <Link to="/">Cancelar</Link>
              <button type="submit" className="submit" disabled={!formik.isValid || formik.isSubmitting}>
                Enviar
              </button>
            </div>
          </Form>
        </LeftContent>
      </Grid>
    </Wrapper>
  );
}
