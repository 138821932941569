import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';
import ArrowRight from '../../../assets/arrow-right.svg';
import ArrowLeft from '../../../assets/arrow-left.svg';

interface IsKids {
  isKids?: boolean;
}

const Container = styled.div`
  .image-item {
    padding-left: 24px;
  }
`;

const Header = styled.div`
  padding: 0 20px;
  margin: 40px 0 20px;
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 18px;
    color: #fff;
  }
  a {
    color: var(--default-orange);
  }
`;

const RightArrow = styled.button<IsKids>`
  right: 0;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) -2.54%, rgba(0, 0, 0, 0.3) 64.44%), url(${ArrowRight});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0;
  min-width: ${px2rem(60)};
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 1000;
  border: 0;
  min-width: 80px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
`;
const LeftArrow = styled.button<IsKids>`
  left: 0;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.8) -2.54%, rgba(0, 0, 0, 0.3) 64.44%), url(${ArrowLeft});
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0;
  min-width: ${px2rem(60)};
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 1;
  border: 0;
  min-width: 80px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
`;

export { LeftArrow, RightArrow, Header, Container };
