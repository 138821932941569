import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const PlansWrapper = styled.div`
  background-color: var(--default-background);
  padding: 56px 20px 70px;
  @media screen and (min-width: 998px) {
    padding: ${px2rem(170)} 0 ${px2rem(108)};
    > .grid {
      max-width: var(--home-grid);
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
      }
    }
  }
`;

const PlansTitle = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  text-align: center;

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(56)};
    width: ${px2rem(400)};
    text-align: inherit;
  }

  @media screen and (max-width: 480px) {
    margin: 56px 70px;
  }
`;

const Plan = styled.div`
  background-color: #000;
  padding: ${px2rem(36)};
  border-radius: ${px2rem(8)};
  align-self: flex-start;
  margin: 0 0 25px 0;
  @media screen and (min-width: 998px) {
    width: ${px2rem(335)};
    &:first-of-type {
      margin: 0 ${px2rem(16)} 0 0;
    }
  }
`;

const PlanName = styled.p`
  font-size: ${px2rem(20)};
  color: #fff;
`;

const PlanPrice = styled.strong`
  font-size: ${px2rem(36)};
  color: #fff;
  margin: ${px2rem(18)} 0 ${px2rem(50)};
  display: block;
`;

const PlanBenefits = styled.ul`
  list-style: none;
  li {
    color: #fff;
    font-size: ${px2rem(16)};
    margin-bottom: ${px2rem(15)};
    img {
      margin-right: ${px2rem(15)};
    }
  }
`;

const PlanButton = styled.a`
  background-color: var(--default-orange);
  width: ${px2rem(192)};
  height: ${px2rem(56)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: ${px2rem(55)};
  border-radius: ${px2rem(8)};
  cursor: pointer;
`;

export { PlansWrapper, PlansTitle, Plan, PlanName, PlanPrice, PlanBenefits, PlanButton };
