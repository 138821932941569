import httpClient from '../../http';
import { IHighlightedContent, IMostWatched, IContent, Watching } from './models/home-interfaces';

const getWatching = async (kids?: boolean) => {
  const watching: Watching = (
    await httpClient.get('/home/watching', {
      params: {
        is_active: true,
        kids
      },
    })
  ).data;
  if (!watching) return null;
  return watching;
};

const getWatchAgain = async (kids?: boolean) => {
  const watchAgain: IContent[] = (
    await httpClient.get(`/home/watch-again`, {
      params: {
        is_active: true,
        kids
      },
    })
  ).data;
  return watchAgain;
};

const getMostWatched = async (kids?: boolean) => {
  const mostViewed: IMostWatched = (await httpClient.get(`/home/most-viewed`, {
    params: {
      kids
    },
  })).data;
  return mostViewed;
};

const getHighlighted = async () => {
  const highlighted: IHighlightedContent = (
    await httpClient.get(`/home/highlighted`, {
      params: {
        is_active: true,
      },
    })
  ).data;
  return highlighted;
};

const getHighlightedKids = async () => {
  const highlightedKids: IHighlightedContent = (
    await httpClient.get(`/home/highlighted-kids`, {
      params: {
        is_active: true,
      },
    })
  ).data;
  return highlightedKids;
};

const getTrending = async () => {
  const trending = (
    await httpClient.get(`/home/top-trending`, {
      params: {
        is_active: true,
      },
    })
  ).data;
  return trending;
};

const getContentTerms = async () => {
  const terms = (await httpClient.get(`/home/1/home-info`)).data;
  return terms;
};

const getContentCategory = async (categories: any) => {
  const content = (
    await httpClient.get(`/home`, {
      params: {
        categories: categories,
      },
    })
  ).data;
  return content;
};

const getHomeCategories = async () => {
  const content = (await httpClient.get(`/home/home-categories`)).data;
  return content;
};

const getHomeCategoriesKids = async () => {
  const content = (await httpClient.get(`/home/home-kids`)).data;
  return content;
};

const getHomeHighlightedCommemorative = async () => {
  const highlightedCommemorative = (await httpClient.get('/home/commemorative')).data;
  return highlightedCommemorative;
};

export {
  getWatching,
  getWatchAgain,
  getMostWatched,
  getHighlighted,
  getTrending,
  getContentTerms,
  getContentCategory,
  getHomeCategories,
  getHighlightedKids,
  getHomeHighlightedCommemorative,
  getHomeCategoriesKids,
};
