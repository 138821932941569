import styled from 'styled-components';

const LazyLoadImage = styled.img`
  width: 100%;
  opacity: 0;
  transition: all 0.2s ease-in-out !important;
  &.loaded {
    opacity: 1;
  }
`;

export { LazyLoadImage };
