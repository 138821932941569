import * as Yup from 'yup';
import { passwordPattern } from 'utils/regex';

const RecoverSchema = Yup.object().shape({
  password: Yup.string()
    .required('Obrigatório')
    .min(8, 'Senha deve conter pelo menos 8 caracteres')
    .matches(passwordPattern, 'Deve conter uma letra maiúscula, um número e um caracter especial'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais')
    .required('Obrigatório'),
});

export { RecoverSchema };
