import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    height: 100%;
    background: #222;
    display: flex;
    flex-direction: column;
    padding: ${px2rem(24)};
    position: relative;

    > button {
      font-size: ${px2rem(24)};
      border-radius: 50%;
      border: 1px solid #fff;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      position: absolute;
      right: ${px2rem(16)};
      top: ${px2rem(16)};
      cursor: pointer;
    }

    > h2 {
      font-size: ${px2rem(22)};
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      line-height: ${px2rem(32)};
      margin-bottom: ${px2rem(32)};
      color: #fff;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: ${px2rem(24)};
      line-height: ${px2rem(32)};
      color: #fff;
      text-align: center;

      span {
        margin-bottom: ${px2rem(24)};
        svg {
          font-size: ${px2rem(80)};
          opacity: 0.2;
        }
      }
    }

    > footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        background-color: transparent;
        text-transform: uppercase;
        border: 1px solid #fff;
        height: ${px2rem(40)};
        padding: 0 ${px2rem(24)};
        border-radius: ${px2rem(8)};
        color: #fff;
        font-size: ${px2rem(18)};
        font-family: 'Lato';
        font-weight: 700;
        padding: 0 ${px2rem(20)};
        cursor: pointer;
      }

      button + button {
        background-color: var(--default-orange);
        border: none;
        margin-left: ${px2rem(16)};
      }
    }
  }
  @media screen and (min-width: 998px) {
    > div {
      width: ${px2rem(500)};
      height: ${px2rem(500)};
      border-radius: 8px;
      > button {
        height: ${px2rem(40)};
        width: ${px2rem(40)};
        font-size: ${px2rem(24)};
      }
      > h2 {
        font-size: ${px2rem(24)};
      }
    }
  }
  @media screen and (max-width: 997px) {
    footer {
      flex-direction: column-reverse;
      button {
        margin: ${px2rem(10)} 0 ${px2rem(15)};
      }
    }
  }
`;
