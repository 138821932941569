import React from "react";

import { InputWrapper } from "./styles";

interface ICodeInput {
  autoFocus?: boolean;
  onChange: (e: any) => any;
  onComplete?: () => any;
  values: string;
}

const CodeVerificationInput = (props: ICodeInput) => {
  const { autoFocus, onChange, onComplete, values } = props;
  return <InputWrapper autoFocus={autoFocus || false} onChange={onChange} />;
};

export default CodeVerificationInput;