import React, { useEffect, useState } from 'react';
import Script from 'react-load-script';
interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface PlayerSambaVideosProps {
  contentUrl: string;
  controlsToEnable?: string[];
  resume?: number | boolean;
  getEventListeners: (player: PlayerEventListener) => void;
}

declare let SambaPlayer: any;

const DEFAULT_PLAN_RESOLUTIONS = ['240p', '360p', '480p'];
const PREMIUM_PLAN_RESOLUTION = ['240p', '360p', '480p', '720p', '1080p'];

const PlayerSambaVideos: React.FC<PlayerSambaVideosProps> = ({
  contentUrl,
  controlsToEnable,
  resume,
  getEventListeners,
}) => {
  const [player, setPlayer] = useState(null);
  const [playerWasInstantiated, setPlayerWasInstantiated] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [playerCleanUpWasMade, setPlayerCleanUpWasMade] = useState(false);
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  if (!playerCleanUpWasMade) {
    const script = document.querySelector('[src="https://fast.player.liquidplatform.com/pApiv2/embed/"]');
    if (script) {
      script.remove();
    }

    setPlayerCleanUpWasMade(true);
  }

  useEffect(() => {
    const validUrlStart = 'https://fast.player.liquidplatform.com/pApiv2/embed/';
    let localContentUrl = contentUrl;
    localContentUrl = localContentUrl.replace(validUrlStart, '');

    if (localContentUrl.includes('/')) {
      const splitedLocalContentUrl = localContentUrl.split('/');
      const playerHash = splitedLocalContentUrl[0];
      const midiaId = splitedLocalContentUrl[1];

      process.nextTick(() => {
        const divPlayer = document.getElementById('player');

        if (
          divPlayer &&
          !divPlayer.children.length &&
          !player &&
          scriptLoaded &&
          !playerWasInstantiated &&
          SambaPlayer
        ) {
          let playerParams = {
            enableShare: false,
            resume: resume || false,
            outputs: user?.plan !== 'premium' ? DEFAULT_PLAN_RESOLUTIONS : PREMIUM_PLAN_RESOLUTION,
          } as any;
          if (controlsToEnable) {
            playerParams.enableControls = controlsToEnable;
          }

          setPlayer(
            new SambaPlayer(divPlayer, {
              ph: playerHash,
              m: midiaId,
              playerParams,
              events: {
                '*': getEventListeners,
              },
            })
          );

          setPlayerWasInstantiated(true);
        }
      });
    }
  }, [contentUrl, controlsToEnable, getEventListeners, player, scriptLoaded, playerWasInstantiated, resume]);

  return (
    <>
      {playerCleanUpWasMade && (
        <Script
          onLoad={() => {
            setScriptLoaded(true);
          }}
          url="https://player.sambatech.com.br/stable/js/samba.player.api.js"
        />
      )}

      <div id="player"></div>
    </>
  );
};

export default PlayerSambaVideos;
