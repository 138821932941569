import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

export const PlayerContentContainer = styled.div`
  width: 100%;
  height: 100%;

  #player {
    width: 100%;
    height: 100%;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;

interface ContainerButtonProps {
  isHideButton: boolean;
}

export const ContainerButton = styled.div<ContainerButtonProps>`
  max-width: 400px;
  width: 100%;
  height: 200px;
  position: absolute;
  opacity: ${(props) => (props.isHideButton ? 0 : 1)};

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${px2rem(768)}) {
    margin-top: ${px2rem(20)};
    width: 300px;
    opacity: 1;
  }
`;

export const ReturnToDetailPage = styled.div`
  position: absolute;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 4px;
  top: ${px2rem(40)};
  left: ${px2rem(33)};
  border-radius: 8px;
  padding: 2.5px ${px2rem(9)};
  cursor: pointer;

  span {
    font-size: ${px2rem(18)};
  }

  img {
    width: ${px2rem(25)};
    height: ${px2rem(25)};
  }
`;
