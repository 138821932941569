import { Link } from "react-router-dom";
import { Footer } from './style';

interface Iprops {
  question: string;
  path: string;
  title: string;
}

export default function MobileFooter({question, path, title}: Iprops) {
  return (
    <Footer>
      <p>{question}</p>
      <Link to={path}>{title}</Link>
    </Footer>
  )
}