import HeaderKids from '../../components/Kids/HeaderKids';
import {
  Wrapper,
  ContainerImage,
  Image,
  ListContents,
  SeasonSelector,
  TitleList,
  WrapperSeason,
  Grid,
  SkeletonContainer,
} from './style';
import Footer from '../../components/Commons/Footer';
import { useParams } from 'react-router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getContent } from '../../services/contents';
import MovieHeader from '../../components/Movies/MovieHeader';
import { useRouteMatch } from 'react-router-dom';
import { getCourse } from '../../services/courses';
import { getTrails } from '../../services/trail';
import MovieCard from '../../components/Movies/MovieCard';
import RelatedCarousel from '../../components/Commons/RelatedCarousel';
import { getSimilar } from '../../services/search';
import { Spacer } from 'components/Commons/MobileNavigatorSpace/style';
import GridSkeleton from 'components/Commons/GridSkeleton';
import KidsSlider from 'components/Home/KidsSlider';
import { Desktop, Mobile } from 'utils/responsiveRules';
import SeasonEpisodeCard from 'components/Movies/SeasonEpisodeCard';
import { IEpisodeData } from 'pages/Detail/types';
import { ContextBePremiumModal } from 'utils/context';
import BePremiumModal from 'components/Modals/BePremiumSmallSize';

export default function DetailKids() {
  const [data, setData] = useState<any>();
  const [currentSeason, setCurrentSeason] = useState<any>(0);
  const [similar, setSimilarContent] = useState<any>();
  const { id, trail } = useParams<any>();
  const { path } = useRouteMatch();

  const { openModalPremium, setOpenModalPremium } = useContext(ContextBePremiumModal);

  const getSimilarData = async () => {
    const categories = data?.categories?.map((category: any) => category.id);
    const similar = await getSimilar(categories);
    setSimilarContent(similar);
  };

  const getMovieDetail = useCallback(
    async (id: string, trail?: string) => {
      if (path === '/kids-movie/:id/:trail?') {
        const data = await getContent(id, trail);
        setData(data);
      }
      if (path === '/kids-course/:id') {
        const data = await getCourse(id);
        setData(data);
      }
      if (path === '/kids-trail/:id') {
        const data = await getTrails(id);
        setData(data);
      }
    },
    [path]
  );

  const userCanAccessContent = useMemo(() => {
    if(!data?.info?.premium || data?.user?.premium) {
      return true
    }
    
    return false;
  }, [data]);

  useEffect(() => {
    if (id) {
      getMovieDetail(id, trail);
      getSimilarData();
    }
  }, [id, trail]);

  return (
    <Wrapper>
      {openModalPremium && <BePremiumModal />}
      <HeaderKids />
      {!data && (
        <SkeletonContainer>
          <GridSkeleton light />
        </SkeletonContainer>
      )}
      {data && (
        <>
          <Desktop>
            <ContainerImage image={data?.trail_thumbnail || data?.thumbnail}>
              <MovieHeader data={data} />
            </ContainerImage>
          </Desktop>
          <Mobile>
            <ContainerImage image={data?.trail_thumbnail_mobile || data?.thumbnail_mobile}>
              <MovieHeader data={data} />
            </ContainerImage>
          </Mobile>
        </>
      )}
      {data?.courses && (
        <ListContents>
          <WrapperSeason>
            <TitleList>Mais episódios</TitleList>
            <SeasonSelector onChange={(e) => setCurrentSeason(e.target.value)} value={currentSeason}>
              {data?.courses.map((season: any, key: any) => (
                <option key={season.course_id} value={key}>
                  {season.title}
                </option>
              ))}
            </SeasonSelector>
            <Desktop>
              {data && (
                <RelatedCarousel title="Mais episódios">
                  {data &&
                    data?.courses[currentSeason]?.contents
                      ?.sort((a: { position: number }, b: { position: number }) => {
                        if (a.position < b.position) {
                          return -1;
                        }
                        if (a.position > b.position) {
                          return 1;
                        }
                        return 0;
                      })
                      ?.map((content: IEpisodeData) => {
                        const cardProps = userCanAccessContent 
                          ? { to: `/show/${data?.trail_id}/season/${content?.course_id}/episode/${content.content_id}` }
                          : { onClick: () => setOpenModalPremium(true) };

                        return (
                          <MovieCard
                            info={true}
                            tail
                            data={content}
                            key={`movie-kids-${content.content_id}`}
                            isKids
                            trailThumbnail={data?.trail_id}
                            {...cardProps}
                          />
                        );
                      })}
                </RelatedCarousel>
              )}
            </Desktop>
            <Mobile>
              {data &&
                data?.courses[currentSeason]?.contents
                  ?.sort((a: { position: number }, b: { position: number }) => {
                    if (a.position < b.position) {
                      return -1;
                    }
                    if (a.position > b.position) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((content: IEpisodeData) => {
                    return (
                      <SeasonEpisodeCard
                        content={content}
                        key={`movie-${content.content_id}`}
                        light
                        to={`/show/${data?.trail_id}/season/${content?.course_id}/episode/${content.content_id}`}
                        isKids
                        trailThumbnail={data?.trail_id}
                      />
                    );
                  })}
            </Mobile>
          </WrapperSeason>
        </ListContents>
      )}

      {similar && similar.length > 0 && (
        <ListContents>
          <WrapperSeason>
            <KidsSlider title="Títulos Semelhantes" data={similar} />
          </WrapperSeason>
        </ListContents>
      )}

      <Footer />
      <Spacer />
    </Wrapper>
  );
}
