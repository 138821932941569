import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const RelatedWrapper = styled.div`
  padding: 40px 20px;

  @media screen and (min-width: 998px) {
    > div ul {
      padding: ${px2rem(20)} 0;
    }
    margin-left: ${px2rem(108)};
    padding-top: ${px2rem(64)};
  }
`;

const WarningNotification = styled.h1`
  color: white;
  width: 100%;
  text-align: center;
`;

export { RelatedWrapper, WarningNotification };
