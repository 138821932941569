import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface WrapperProps {
  background?: string;
}

const WrapperMovieDetail = styled.div`
  background: linear-gradient(transparent 0%, #1a1e21fa 100%), ${(props: WrapperProps) => `url(${props.background})`};
  background-repeat: no-repeat;
  margin-top: ${px2rem(32)};
  background-size: cover;
  background-position: top;
  position: relative;
  height: 75vh;

  @media screen and (max-width: 480px) {
    height: 80vh;
  }
`;

const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  img {
    width: 25px;
  }
  @media screen and (min-width: 998px) {
    top: ${px2rem(70)};
    right: ${px2rem(95)};
    img {
      width: ${px2rem(48)};
    }
  }
`;

export { WrapperMovieDetail, ButtonClose };
