import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PlayerSambaVideos from 'components/PlayerSambaVideos';
import { Content } from 'services/contents/models/content-interface';
import {
  getContent,
  updateProgressSingleContent,
  finishSingleContent as finishSingleContentService,
  startSingleContent as startSingleContentService,
  totalViewed as totalViewedService,
} from 'services/contents';
import KeepWatchingCard from 'components/KeepWatchingCard';

import { ContainerButton, PlayerContentContainer, ReturnToDetailPage } from './style';
import BackArrow from 'assets/icons/arrow-back-black.svg';
import { getSimilar } from 'services/search';
import useTotalViewd from 'hooks/useTotalViewd';

interface IPlayerParams {
  id: string;
}

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface ExtendedWindow extends Window {
  hasStartedContent: boolean;
  hasFinishedContent: boolean;
}

declare let window: ExtendedWindow;

const PlayerContent: React.FC = () => {
  const { id } = useParams() as IPlayerParams;
  const history = useHistory();
  const [content, setContent] = useState<Content>();
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [showNextCard, setShowNextCard] = useState<boolean>(false);
  const [nextContent, setNextContent] = useState<Content>();
  const [hideBackButton, setHideBackButton] = useState(false);
  const [cardClosed, setCardClosed] = useState(false);

  const { addContentId, checkView, tick, removeLocalStorageTime } = useTotalViewd(id);

  const getActualContent = useCallback(async () => {
    const localContent = await getContent(id);

    setContent(localContent);
  }, [id]);

  const getNextContent = useCallback(async () => {
    const categories = content?.categories?.map((category: any) => category.id);
    const localNextContent = await getSimilar(categories, true);
    setNextContent(localNextContent);
  }, [id]);

  useEffect(() => {
    window.hasStartedContent = false;
    window.hasFinishedContent = false;

    addContentId(id);
    getActualContent();
    getNextContent();
  }, [getActualContent, id]);

  const resumeTime = useMemo(() => {
    if (!content) {
      return false;
    }
    if (content.user?.content_user) {
      setLastWatchedTime(Number(content.user.content_user.content_view));
      return Number(content.user?.content_user.content_view);
    }

    return false;
  }, [content]);

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateProgressSingleContent({ content_id: id, content_view: time });
    },
    [id]
  );

  const updateWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setProgress(eventParam);
    }

    return null;
  }, []);

  const finishContent = async () => {
    if (content) {
      if (!window.hasFinishedContent && !content.alreadyFinished) {
        try {
          window.hasFinishedContent = true;
          await finishSingleContentService(content.content_id);
          content.alreadyFinished = true;
        } catch {
          window.hasFinishedContent = false;
        }
      }
    }
  };

  const startContent = async () => {
    if (content) {
      if (!window.hasStartedContent && !content.alreadyStarted) {
        try {
          window.hasStartedContent = true;
          content.alreadyStarted = true;

          await startSingleContentService(content.content_id, Number(content.duration));
        } catch {
          window.hasStartedContent = false;
          content.alreadyStarted = false;
        }
      }
    }
  };

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const numberCurrentTime = Math.floor(Number(currentTime.toFixed(0)));
    return numberCurrentTime >= duration - 3 * 60;
  };

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }

    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [lastWatchedTime, progress, updateContentProgress]);

  const getEventListeners = async (player: PlayerEventListener) => {
    if (window.location.href.endsWith(`player/${id}`)) {
      if (content && content.content_id) {
        switch (player.event) {
          case 'onProgress':
            tick(player.event);
            if (hasReachedCompletionTime(player.duration, player.eventParam)) {
              await finishContent();
              setShowNextCard(true);
            }

            updateWatchTime(player);
            break;

          case 'onStart':
            startContent();
            break;

          case 'onPause':
            tick(player.event);
            break;

          case 'onFinish':
            tick(player.event);
            removeLocalStorageTime();
            break;

          default:
            break;
        }
      }
    }
  };

  const handleReturnToDetails = () => {
    const isKids = localStorage.getItem('kids');

    removeLocalStorageTime();

    if (isKids) {
      history.push(`/kids-movie/${id}`);
    } else if (content?.tag === 'lives_anteriores') {
      history.push(`/live`);
    } else {
      history.push(`/movie/${id}`);
    }
  };

  const closeCard = () => {
    setCardClosed(true);
    setShowNextCard(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setHideBackButton(true);
    }, 5000);

    checkView();
  }, [checkView, id]);

  return (
    <PlayerContentContainer>
      {content ? (
        <>
          <ContainerButton isHideButton={hideBackButton}>
            <ReturnToDetailPage onClick={handleReturnToDetails}>
              <img src={BackArrow} alt="back arrow" />

              <span>{content.title}</span>
            </ReturnToDetailPage>
          </ContainerButton>

          <PlayerSambaVideos
            controlsToEnable={[
              'play',
              'pause',
              'quality',
              'fullscreen',
              'time',
              'volume',
              'backward',
              'forward',
              'seekbar',
              'cast',
              'subtitles',
              'settings',
            ]}
            contentUrl={`${content.reference}?captionTheme=[fffff,null,pt-br,null]`}
            resume={resumeTime}
            getEventListeners={getEventListeners}
          />
        </>
      ) : null}

      {showNextCard && nextContent && !cardClosed ? (
        <KeepWatchingCard nextContent={nextContent} onClose={() => closeCard()} />
      ) : null}
    </PlayerContentContainer>
  );
};

export default PlayerContent;
