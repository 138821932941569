import styled from 'styled-components';

const Footer = styled.footer`
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 123px;
  p{
    color: #FFF;
    font-size: 16px;
    margin-bottom: 10px;
  }
  a{
    color: var(--default-orange);
    font-size: 16px;
    text-decoration: underline;
  }
`;

export { Footer }