import { useState, useEffect } from 'react';

import HomeSlider from 'components/Home/HomeSlider';
import { getMostWatched } from 'services/home';
import { IContent, ITrail } from 'services/home/models/home-interfaces';

type IMostWatched = (IContent & ITrail)[];

export default function MostWatched() {
  const [mostWatched, setMostWatched] = useState<IMostWatched>([]);

  useEffect(() => {
    const getContent = async () => {
      const resultMostWatched = await getMostWatched(false);
      setMostWatched([...resultMostWatched.contents, ...resultMostWatched.trails] as IMostWatched);
    };
    getContent();
  }, []);

  return <HomeSlider title="Mais assistidos" data={mostWatched} />;
}
