import { Link, useHistory } from 'react-router-dom';
import { BiographyWrapper, Biography, BiographyTitle, BiographyParagraph } from './style';
import ArrowBack from '../../../assets/arrow-back.svg';

export default function AuthorBiography({ author }: any) {
  const history = useHistory();
  return (
    <BiographyWrapper>
      <button title="Voltar" onClick={() => history.goBack()}>
        <img src={ArrowBack} alt="" />
      </button>
      <Biography>
        <BiographyTitle>{author.name}</BiographyTitle>
        <BiographyParagraph>{author.description}</BiographyParagraph>
      </Biography>
    </BiographyWrapper>
  )
}