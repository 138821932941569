import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const LiveCardWrapper = styled.div`
  margin-bottom: 24px;
  img {
    width: 100%;
  }
  p {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: ${px2rem(18)};
    line-height: ${px2rem(24)};
    color: #fff;
  }
  @media screen and (min-width: 998px) {
    width: ${px2rem(375)};
    margin-bottom: 0;
  }
`;

export { LiveCardWrapper };
