import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

export const ErrorWrapper = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: 14px;
  margin-top: 4px;
  text-align: left;
`;

export const Error = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 32px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background: #222;
  line-height: 24px;

  svg {
    margin-right: 16px;
    font-size: 32px;
  }
`;

export const Form = styled.form`
  margin: auto;
  flex: 1;

  @media screen and (min-width: 998px) {
    margin-left: auto;
  }

  div.one-row {
    width: 100%;
    @media screen and (min-width: 998px) {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
    }
  }

  div.default-input {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${px2rem(48)};

    label {
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      color: #fff;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s linear;
      font-weight: lighter;
    }

    input {
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      margin: 0;
      border: none;
      background-color: var(--default-background) !important;
      color: #fff;
      outline: none;
      border-bottom: 1px solid #ffffff;
      padding-bottom: ${px2rem(10)};
      &::placeholder {
        font-family: 'Lato', sans-serif;
        color: #fff;
        opacity: 0;
      }
      &:not(:placeholder-shown),
      &:focus {
        ~ label {
          transform: translate(0, -1.75rem);
          font-size: 16px;
        }
      }

      &.error {
        color: #ff3030;
        ~ label {
          color: #ff3030;
        }
        border-color: #ff3030;
      }
    }
    span.error {
      color: #ff3030;
      font-size: ${px2rem(14)};
      margin-top: ${px2rem(3)};
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  @media screen and (min-width: 998px) {
    div.default-input {
      input {
        font-size: ${px2rem(18)};
      }
      label {
        font-size: ${px2rem(18)};
      }
    }
  }
`;

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  overflow-y: auto;

  > h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: ${px2rem(24)};
    line-height: ${px2rem(32)};
    margin-bottom: ${px2rem(32)};
    color: #fff;
  }

  > div {
    max-width: 998px;
    width: 100%;
    background: var(--default-background);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: ${px2rem(80)} ${px2rem(24)} ${px2rem(24)};
    position: relative;
    justify-content: center;

    aside {
      min-width: 100%;
      text-align: left;
      background: #ebebeb;
      border-radius: 8px;
      color: var(--default-background);
      padding: ${px2rem(40)};
      margin-right: 0;
      margin-bottom: ${px2rem(32)};

      @media screen and (min-width: 998px) {
        min-width: ${px2rem(300)};
        margin-right: ${px2rem(24)};
        margin-bottom: 0;
      }

      h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: ${px2rem(20)};
        line-height: ${px2rem(32)};
      }

      h2 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: ${px2rem(36)};
        line-height: ${px2rem(48)};
        letter-spacing: -1px;
        margin-bottom: ${px2rem(48)};
      }

      ul {
        list-style: none;
        text-align: left;

        li {
          img {
            margin-right: ${px2rem(8)};
          }

          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: ${px2rem(16)};
          line-height: ${px2rem(32)};
          margin-bottom: ${px2rem(8)};
        }
      }
    }

    > button {
      height: ${px2rem(40)};
      width: ${px2rem(40)};
      border-radius: 50%;
      border: 1px solid #fff;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: ${px2rem(24)};
      position: absolute;
      right: ${px2rem(16)};
      top: ${px2rem(16)};
      cursor: pointer;
    }

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: ${px2rem(24)};
      line-height: ${px2rem(32)};
      color: #fff;
      text-align: center;
      flex-direction: column;

      @media screen and (min-width: 998px) {
        flex-direction: row;
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      background-color: transparent;
      text-transform: uppercase;
      border: 1px solid #fff;
      height: ${px2rem(40)};
      padding: 0 ${px2rem(24)};
      border-radius: ${px2rem(8)};
      color: #fff;
      font-size: ${px2rem(18)};
      font-family: 'Lato';
      font-weight: 700;
      padding: 0 ${px2rem(20)};
      cursor: pointer;
    }

    button + button {
      background-color: var(--default-orange);
      border: none;
      margin-left: ${px2rem(16)};
    }
    @media screen and (max-width: 997px) {
      justify-content: center;
    }
  }
  @media screen and (max-width: 997px) {
    width: 100%;
  }
`;
