import HomeSlider from 'components/Home/HomeSlider';
import React, { useEffect, useState } from 'react';
import { ContentsProps } from 'services/contents/models/content-interface';
import { getHomeHighlightedCommemorative } from 'services/home';

const HighlightedCommemorativeContent = () => {
  const [contentsCommemorative, setContentsCommemorative] = useState<ContentsProps[]>([]);

  useEffect(() => {
    const getContentsCommemorative = async () => {
      const contents = await getHomeHighlightedCommemorative();
      setContentsCommemorative(contents);
    };

    getContentsCommemorative();
  }, []);

  return (
    <>
      {contentsCommemorative &&
        contentsCommemorative?.map((content, key) => {
          return <HomeSlider title={content?.category} data={content?.contents} key={`category-hom-${key}`} />;
        })}
    </>
  );
};

export default HighlightedCommemorativeContent;
