import { useState } from 'react';
import HomeSlider from 'components/Home/HomeSlider';
import { useEffect } from 'react';
import { getWatching } from 'services/home';

export default function Watching() {
  const [watching, setWatching] = useState<any>();

  useEffect(() => {
    const getContent = async () => {
      const resultWatchingContents = await getWatching(false);
      setWatching(resultWatchingContents);
    };
    getContent();
  }, []);

  return <HomeSlider title="Continue assistindo" data={watching} />;
}
