import styled, { css } from 'styled-components';
import { px2rem } from '../../utils/fontUtil';
import ArrowDrop from '../../assets/arrow-drop-black.svg';

interface ImageProps {
  image: string;
}

const Wrapper = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 135px);
`;

const SkeletonContainer = styled.div`
  padding: 100px 0 60px;
`;

const ContainerImage = styled.div`
  opacity: 0;
  top: 0;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  ${(props: ImageProps) =>
    props.image &&
    css`
      opacity: 1;
      background-image: linear-gradient(359.55deg, #fdfeff 0%, rgba(26, 30, 33, 0.7) 20%),
        url(${(props: ImageProps) => props.image});
    `}
`;

const Image = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  transition: opacity 0.3s ease;
  height: 80vh;

  ${(props: ImageProps) =>
    props.image &&
    css`
      opacity: 1;
      background-image: linear-gradient(359.55deg, #fdfeff 0%, rgba(26, 30, 33, 0.7) 10%),
        url(${(props: ImageProps) => props.image});
    `}
`;

const ListContents = styled.div`
  position: relative;
  background-color: #fff;
  padding-bottom: ${px2rem(60)};
`;

const WrapperSeason = styled.div``;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 ${px2rem(14)};
  width: 100vw;
  gap: 10px;

  justify-content: center;

  > div,
  a {
    width: 100%;
    max-width: ${px2rem(375)};
  }
`;

const TitleList = styled.h1`
  color: var(--default-background);
  font-size: ${px2rem(24)};
  padding: ${px2rem(40)} ${px2rem(24)};

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(40)};
  }
`;

const SeasonSelector = styled.select`
  border: 1px solid #000;
  background-color: transparent;
  margin: 0 ${px2rem(24)} ${px2rem(35)};
  height: ${px2rem(40)};
  padding: 0 ${px2rem(30)} 0 ${px2rem(20)};
  width: ${px2rem(250)};
  border-radius: ${px2rem(6)};
  background-image: url(${ArrowDrop});
  background-repeat: no-repeat;
  background-position: 97%;
  background-size: 12px;
  color: #000;
  &,
  option {
    font-size: ${px2rem(16)};
    font-family: 'Lato';
    font-weight: 400;
    cursor: pointer;
  }
  option {
    color: #000;
  }
`;

export {
  Wrapper,
  SkeletonContainer,
  ContainerImage,
  Image,
  ListContents,
  Grid,
  TitleList,
  SeasonSelector,
  WrapperSeason,
};
