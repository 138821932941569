import Footer from 'components/Commons/Footer';
import { Wrapper, Body } from './style';
import TermHeader from 'components/TermsHeader';
import { useEffect, useState } from 'react';
import { getContentTerms } from 'services/home';
import { Link } from 'react-router-dom';
import IconArrowBack from 'assets/icon-arrow-back.svg';

export default function Politics() {
  const [term, setTerm] = useState<any>('');

  const getTerm = async () => {
    const term = await getContentTerms();
    setTerm(term);
  };

  useEffect(() => {
    getTerm();
  }, []);
  return (
    <>
      <TermHeader />
      <Wrapper>
        <Link to="/" className="back">
          <img src={IconArrowBack} alt="voltar" />
        </Link>
        <Body>
          <div dangerouslySetInnerHTML={{ __html: term?.privacy_policy }}></div>
        </Body>
      </Wrapper>
      <Footer />
    </>
  );
}
