import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const Wrapper = styled.div``;

const Title = styled.h1`
  color: var(--default-background);
  font-weight: 700;
  padding: 0 ${px2rem(24)} 18px;

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(48)};
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const Highlights = styled.div``;

export { Wrapper, Title, Highlights };
