import BannerTop from 'assets/landing-banner.jpg';
import CoursesOverlay from 'assets/overlay-1.jpg';
import MoviesOverlay from 'assets/overlay-2.jpg';
import VideosOverlay from 'assets/overlay-3.jpg';
import {
  WrapperBanner,
  WrapperBannerContent,
  BannerTitle,
  PropositoWrapper,
  PropositoTitle,
  WrapperOverlayItems,
  OverlayItem,
} from './style';

export default function PresentationBanner() {
  const chooseYourPlan = () => {
    const violation = document.getElementById('plans'); 
    window.scrollTo({ top: violation?.offsetTop, behavior: 'smooth' });
  };

  return (
    <>
      <WrapperBanner>
        <img src={BannerTop} alt="Imagem de uma mulher orando" />
        <WrapperBannerContent>
          <BannerTitle>
            Conteúdo cristão para o seu <span>crescimento espiritual.</span>
          </BannerTitle>
          <p>Assista onde e quando quiser.</p>
          <button type="button" onClick={chooseYourPlan}>
            Escolha seu plano
          </button>
        </WrapperBannerContent>
      </WrapperBanner>
      <PropositoWrapper>
        <div>
          <PropositoTitle>
            O nosso propósito é o de ajudá-lo a crescer na graça e no conhecimento de Jesus Cristo, todos os dias.{' '}
          </PropositoTitle>
          <p>
            Uma preciosa fonte de edificação, reflexão e consagração. Atuamos lado a lado com a sua igreja local, no
            ensino, expansão e fortalecimento do Corpo de Cristo, para a Sua honra e glória.
          </p>
        </div>
      </PropositoWrapper>
      <WrapperOverlayItems>
        <OverlayItem>
          <img src={CoursesOverlay} alt="" />
          <div>
            <p>Cursos, palestras e sermões para jovens e adultos com diversos assuntos da vida cristã.</p>
          </div>
        </OverlayItem>
        <OverlayItem>
          <img src={MoviesOverlay} alt="" />
          <div>
            <p>Filmes, séries e documentários para você e toda a família.</p>
          </div>
        </OverlayItem>
        <OverlayItem>
          <img src={VideosOverlay} alt="" />
          <div>
            <p>Videoclips e shows ao vivo de diversos estilos e para todas as idades.</p>
          </div>
        </OverlayItem>
      </WrapperOverlayItems>
    </>
  );
}
