import React from 'react';
import { useLocation } from 'react-router';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { LeftArrow, RightArrow, Header, Container } from './style';
import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';

interface Props {
  children?: React.ReactNode;
  title?: string;
  seeMore?: string;
}

export default function PrincipalCarousel({ children, title, seeMore }: Props) {
  const { pathname } = useLocation();

  const responsiveKids = {
    desktop: {
      breakpoint: { max: 2560, min: 1025 },
      items: 4,
      paritialVisibilityGutter: 75,
      slidesToSlide: 4,
    },
    smallDesktop: {
      breakpoint: { max: 1024, min: 769 },
      items: 3,
      paritialVisibilityGutter: 30,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 601 },
      items: 2,
      paritialVisibilityGutter: 40,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 481 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    smallMobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      paritialVisibilityGutter: 80,
    },
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      paritialVisibilityGutter: 50,
      slidesToSlide: 5,
    },
    tablet: {
      breakpoint: { max: 768, min: 600 },
      items: 3,
      paritialVisibilityGutter: 40,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 325 },
      items: 2,
      paritialVisibilityGutter: 0,
    },
    smallMobile: {
      breakpoint: { max: 325, min: 0 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
  };
  const CustomLeftArrow = ({ onClick }: any) => (
    <LeftArrow isKids={pathname === '/kids'} onClick={() => onClick()}>
      <img src={ArrowLeft} width={40} height={40} alt="itens para esquerda" />
    </LeftArrow>
  );
  const CustomRightArrow = ({ onClick }: any) => (
    <RightArrow isKids={pathname === '/kids'} onClick={() => onClick()}>
      <img src={ArrowRight} width={40} height={40} alt="itens para direita" />
    </RightArrow>
  );
  return (
    <Container>
      {seeMore?.length && (
        <Header>
          <h1>{title}</h1>
          <Link to={seeMore}>Ver mais</Link>
        </Header>
      )}
      <Carousel
        responsive={pathname === '/kids' ? responsiveKids : responsive}
        itemClass="image-item"
        infinite={false}
        partialVisible
        draggable={false}
        removeArrowOnDeviceType={['tablet', 'mobile', 'smallMobile']}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        autoPlay={false}
      >
        {children}
      </Carousel>
    </Container>
  );
}
