import { IEpisodeData } from 'pages/Detail/types';
import { Container, Description, SeeMore, DescriptionContainer, EpisodeImage, PlayIconContainer, TitleContainer } from './style';
import PlayIcon from '../../../assets/icon-play.svg';

interface ISeasonEpisodeCardProps {
  content: IEpisodeData;
  to: string;
  light?: boolean;
  isKids?: boolean;
  trailThumbnail?: string;
}

export default function SeasonEpisodeCard({ 
  content, 
  to, 
  light = false, 
  isKids = false,
  trailThumbnail = undefined,
}: ISeasonEpisodeCardProps) {
  const hour = `${Math.floor(content.duration / (60 * 60)).toFixed(0)}`;
  const min = `${Math.floor((content.duration / 60) % 60).toFixed(0)}`;
  let time: string;

  if ((hour === '1' && min === '0') || (hour === '0' && min === '60')) {
    time = '1hr';
  } else if (hour === '0') {
    const splitMinutes = min.split('.');
    splitMinutes[1] === '00' ? (time = min) : (time = `${splitMinutes[0]} min`);
  } else {
    time = `${hour} h ${min} min`;
  }
  return (
    <Container to={to} light={light}>
      <EpisodeImage image={content.thumbnail_mobile}>
        <PlayIconContainer>
          <div>
            <img src={PlayIcon} alt="ícone de play" />
          </div>
        </PlayIconContainer>
      </EpisodeImage>
      <DescriptionContainer>
        <TitleContainer>
          <h3>{content.title}</h3>
          <p>{time}</p>
        </TitleContainer>
        <Description>{content.description}</Description>
        <SeeMore to={isKids 
            ? `/kids-movie/${content.content_id}/${trailThumbnail ? trailThumbnail : ''}` 
            : `/movie/${content.content_id}/${trailThumbnail ? trailThumbnail : ''}`
          }
        >
          Ver mais
        </SeeMore>
      </DescriptionContainer>
    </Container>
  );
}
