import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const WrapperLive = styled.div`
  background-color: #000;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  @media screen and (min-width: 998px) {
    width: ${`calc(100% - ${px2rem(390)})`};
    height: 100%;
  }
  @media screen and (max-width: 997px) {
    height: 50%;
    padding-bottom: 56%;
    position: relative;
    width: 100%;
    > div {
      position: absolute;
      top: 0;
      left: 0%;
    }
  }
`;

const Back = styled(Link)`
  padding: ${px2rem(20)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  img {
    width: 25px;
  }

  @media screen and (min-width: 998px) {
    img {
      width: 100%;
    }
  }
`;

const HeaderLive = styled.div`
  padding: calc(${px2rem(27)} + 0.25px) ${px2rem(26)};
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);

  @media screen and (max-width: 1439px) {
    padding: calc(${px2rem(27)}) ${px2rem(26)};
  }

  img {
    width: ${px2rem(28)};
  }
`;

export { WrapperLive, Back, HeaderLive };
