import HomeSlider from 'components/Home/HomeSlider';
import { useEffect } from 'react';
import { useState } from 'react';
import { ContentsProps } from 'services/contents/models/content-interface';
import { getHomeCategories } from 'services/home';
import { getReputedAuthors } from '../../services/authors';
import { Author } from '../../services/authors/models/author-interface';
import CardAuthor from '../../components/Authors/CardAuthor';
import AuthorCarousel from '../../components/Authors/AuthorCarousel';
import { Items } from './styles';

export default function HomeCategories() {
  const [contents, setContents] = useState<ContentsProps[]>([]);
  const [authors, setAuthors] = useState<Array<Author>>([]);

  const getData = async () => {
    const content = await getHomeCategories();
    setContents(content);
  };

  const getAllContent = async () => {
    const resultAuthors = await getReputedAuthors();
    setAuthors(resultAuthors);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getAllContent();
  }, []);

  return (
    <>
      {authors.length > 0 && (
        <Items>
          <AuthorCarousel title="Palestrantes">
            {authors?.map((author, key) => (
              <CardAuthor key={key} data={author} />
            ))}
          </AuthorCarousel>
        </Items>
      )}
      {contents &&
        contents?.map((category, key) => (
          <HomeSlider title={category?.category} data={category?.contents} key={`category-hom-${key}`} />
        ))}
    </>
  );
}
