import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${px2rem(10)} ${px2rem(6)};
`;

export const ContainerTitle = styled.div`
  flex-direction: row;
  align-items: center;
  padding: 0 ${px2rem(6)};
  margin-bottom: ${px2rem(7)};
`;

export const ContainerImageFilm = styled.div`
  flex: 0.45;
`;

export const ImageFilm = styled.img`
  height: 6rem;
`;

export const DetailFilmContainer = styled.div`
  align-items: flex-start;
  margin-left: 15px;
`;

export const DescriptionText = styled.p`
  color: white;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

export const SmallText = styled.p`
  color: white;
  font-size: 0.6rem;
`;

export const Label = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  color: var(--default-orange);
  margin-bottom: 0.3rem;
`;
