import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';

const ListContents = styled.div`
  position: relative;
  min-height: calc(100vh - 135px);
  background-color: #1a1e21;
  padding: ${px2rem(150)} 0 ${px2rem(60)};

  @media screen and (max-width: 997px) {
    min-height: calc(100vh - 483px);
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 24px;
  gap: 24px;
`;

const CardWrapper = styled.div`
  --card-width: 310px;
  --card-height: 473px;

  width: var(--card-width);
  height: auto;
  overflow: hidden;

  @media screen and (max-width: 1400px) {
    width: calc(var(--card-width) * 0.8);
  }

  @media screen and (max-width: 900px) {
    width: calc(var(--card-width) * 0.6);
  }

  @media screen and (max-width: 600px) {
    width: calc(var(--card-width) * 0.5);
  }

  @media screen and (max-width: 480px) {
    width: calc(var(--card-width) * 0.3);
  }
`;

const TitleList = styled.h1`
  font-size: ${px2rem(24)};
  color: #fff;
  margin: 0 auto;
  width: 80vw;
  margin-bottom: ${px2rem(30)};

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(40)};
  }
`;

const WithoutContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: ${px2rem(100)};
  > div {
    background-color: rgba(255, 255, 255, 0.15);
    width: ${px2rem(180)};
    height: ${px2rem(180)};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-size: ${px2rem(18)};
    color: #fff;
    text-align: center;
    width: ${px2rem(450)};
    margin-top: ${px2rem(30)};
  }
`;

export { ListContents, Grid, CardWrapper, TitleList, WithoutContent };
