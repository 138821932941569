import { DisabledChat, HeaderChat, Messages, SendMessage, Wrapper } from './style';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { io, Socket } from 'socket.io-client'

import Send from '../../../assets/send-chat.svg';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';

interface ISocketMessage {
  chat_avatar: string
  live_id: string
  message_id: string;
  text: string;
  user_id: string;
  username: string;
}
export default function Chat() {
  const token = localStorage.getItem('api_token');
  const { user } = useAuth();
  const { id } = useParams<any>();

  const [message, setMessage] = useState('');
  const [viewChat, setViewChat] = useState<boolean>(true);
  const [messageQueue, setMessageQueue] = useState([] as number[]);
  const [intervalId, setIntervalId] = useState<number | undefined>();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [chatMessages, setChatMessages] = useState<ISocketMessage[]>([]);

  const shouldDisabledMessageInput = useMemo(() => {
    return messageQueue?.length >= 5;
  }, [messageQueue]);

  useEffect(() => {
    if (intervalId) {
      window.clearInterval(intervalId);
      setIntervalId(undefined);
    }
    const id = window.setInterval(() => {
      const mq = [...messageQueue];
      if (mq.length > 0) {
        mq.splice(0, 1);
      }
      setMessageQueue(mq);
    }, 5000);
    setIntervalId(id);
  }, [messageQueue]);

  const handleSendMessage = () => {
    if (shouldDisabledMessageInput && !message) return;

    if(socket) {
      socket.emit('message', {
        live_id: id,
        user_id: user?.user_id,
        message: message,
      });
      
      setMessageQueue((mq) => [...mq, Date.now()]);
      setMessage('');
    }
  };

  const keyDownEnter = (e: any) => {
    if (e.which === 13) {
      handleSendMessage();
    }
  };

  const createConnectionWithSocketIO = useCallback(() => {
    const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

    if(SOCKET_URL) {
      const socketIoConnection = io(SOCKET_URL, {
        query: {
          token,
        },
        transports: ['websocket'],
      });
      setSocket(socketIoConnection);

      socketIoConnection.emit(
        'select_room',
        {
          live_id: id,
          user_id: user?.user_id,
          username: user?.name,
        },
        (data: { messages: ISocketMessage[] }) => {
          setChatMessages(data.messages);
        },
      )
    }
  }, [token, id, user])

  useEffect(() => {
    if(token) {
      createConnectionWithSocketIO();
    }
  }, [token, createConnectionWithSocketIO]);

  useEffect(() => {
    if (socket) {
      socket.on('message', (newMessage: ISocketMessage) => {
        setChatMessages(messages => [...messages, newMessage]);
      });

      socket.on('delete_message', data => {
        setChatMessages((messages) =>
          messages.filter(msg => msg.message_id !== data.message_id),
        );
      });
    }
  }, [socket]);

  return (
    <Wrapper>
      <HeaderChat>
        <p>Chat</p>
        <label className="switch">
          <input type="checkbox" checked={viewChat} onChange={() => setViewChat(!viewChat)} />
          <span className="slider" />
        </label>
      </HeaderChat>
      {!viewChat && (
        <DisabledChat>
          <strong>Chat desativado.</strong>
          <p>Você pode habilitar e desabilitar o chat quando quiser.</p>
        </DisabledChat>
      )}
      {viewChat && (
        <>
          <Messages>
            {chatMessages.map((message) => {
              return (
                <div key={`chat-${message.message_id}`}>
                  <strong>{message.username}</strong>
                  <p>{message.text}</p>
                </div>
              );
            })}
          </Messages>
          <SendMessage>
            <input
              type="text"
              placeholder="Mande uma mensagem"
              name="message"
              autoComplete="off"
              value={message}
              onKeyDown={keyDownEnter}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              disabled={shouldDisabledMessageInput}
            />
            <button type="submit" title="Enviar" onClick={handleSendMessage} disabled={shouldDisabledMessageInput}>
              <img src={Send} alt="Enviar mensagem" />
            </button>
          </SendMessage>
        </>
      )}
    </Wrapper>
  );
}
