import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import MovieCard from '../../components/Movies/MovieCard';
import { ListContents, Grid, TitleList, WithoutContent, CardWrapper } from './style';
import EmptyList from '../../assets/icon-empty.svg';
import { useEffect, useState } from 'react';
import { Content } from '../../services/contents/models/content-interface';
import GridSkeleton from '../../components/Commons/GridSkeleton';
import { getContentTag } from '../../services/tags';
import { Mobile } from 'utils/responsiveRules';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';

const NoContent = () => (
  <WithoutContent>
    <div>
      <img src={EmptyList} alt="" />
    </div>
    <p>Nenhum conteúdo a esta categoria no momento. Navegue pelo catálogo para encontrar mais conteúdos.</p>
  </WithoutContent>
);

export default function Movies() {
  const [contents, setContents] = useState<any>(null);
  const getMovieContent = async () => {
    const resultContent = await getContentTag('filmes');
    setContents(resultContent);
  };
  useEffect(() => {
    getMovieContent();
  }, []);

  return (
    <>
      <NavBar />
      <ListContents>
        <TitleList>Filmes</TitleList>
        {!contents && <GridSkeleton />}
        {contents?.length <= 0 ? (
          <NoContent />
        ) : (
          <Grid>
            {contents &&
              contents.map((movie: Content, key: string) => {
                return (
                  <CardWrapper>
                    <MovieCard to={`/movie/${movie.content_id}`} data={movie} key={`movie-${movie.content_id}`} />
                  </CardWrapper>
                );
              })}
          </Grid>
        )}
      </ListContents>
      <Footer />
      <Mobile>
        <MobileNavigatorSpace />
      </Mobile>
    </>
  );
}
