import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import { ListContents, Grid, TitleList, WithoutContent, Wrapper, CardWrapper } from './style';
import EmptyList from '../../assets/icon-empty.svg';
import { useEffect, useState } from 'react';
import { getAllAuthors } from '../../services/authors';
import CardAuthor from '../../components/Authors/CardAuthor';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';

export default function Authors() {
  const [contents, setContents] = useState<any>();
  const getContent = async () => {
    const resultContent = await getAllAuthors();
    setContents(resultContent);
  };
  useEffect(() => {
    getContent();
  }, []);
  return (
    <Wrapper>
      <NavBar />
      <ListContents>
        <TitleList>Teólogos</TitleList>
        <Grid>
          {contents &&
            contents.map((author: any, key: any) => {
              return (
                <CardWrapper>
                  <CardAuthor key={key} data={author} animation={false} />
                </CardWrapper>
              );
            })}
        </Grid>
      </ListContents>
      <Footer />
      <MobileNavigatorSpace />
    </Wrapper>
  );
}
