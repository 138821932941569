import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

export const ErrorWrapper = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: ${px2rem(14)};
  margin-top: ${px2rem(4)};
  text-align: left;
`;

export const Error = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: ${px2rem(16)};
  margin-top: 4px;
  margin-bottom: ${px2rem(32)};
  text-align: left;
  display: flex;
  align-items: center;
  padding: ${px2rem(8)};
  border-radius: 8px;
  background: #222;
  line-height: ${px2rem(24)};

  svg {
    margin-right: ${px2rem(16)};
    font-size: ${px2rem(32)};
  }
`;

export const Form = styled.form`
  width: 100%;

  div.one-row {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
  }

  div.default-input {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${px2rem(48)};

    label {
      font-size: ${px2rem(16)};
      font-family: 'Lato', sans-serif;
      color: #fff;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s linear;
      font-weight: lighter;
    }

    input {
      font-size: ${px2rem(16)};
      font-family: 'Lato', sans-serif;
      margin: 0;
      border: none;
      background-color: var(--default-background) !important;
      color: #fff;
      outline: none;
      border-bottom: 1px solid #ffffff;
      padding-bottom: ${px2rem(10)};
      &::placeholder {
        font-family: 'Lato', sans-serif;
        color: #fff;
        opacity: 0;
      }
      &:not(:placeholder-shown),
      &:focus {
        ~ label {
          transform: translate(0, -1.75rem);
          font-size: 16px;
        }
      }

      &.error {
        color: #ff3030;
        ~ label {
          color: #ff3030;
        }
        border-color: #ff3030;
      }
    }
    span.error {
      color: #ff3030;
      font-size: ${px2rem(14)};
      margin-top: ${px2rem(3)};
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  @media screen and (min-width: 998px) {
    div.default-input {
      input {
        font-size: ${px2rem(18)};
      }
      label {
        font-size: ${px2rem(18)};
      }
    }
  }
`;

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: ${px2rem(24)};
    line-height: ${px2rem(32)};
    margin-bottom: ${px2rem(32)};
    color: #fff;
  }

  > div {
    max-width: ${px2rem(500)};
    width: 100%;
    background: var(--default-background);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: ${px2rem(80)} ${px2rem(24)} ${px2rem(24)};
    position: relative;

    > button {
      height: ${px2rem(40)};
      width: ${px2rem(40)};
      border-radius: 50%;
      border: 1px solid #fff;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: ${px2rem(24)};
      position: absolute;
      right: ${px2rem(16)};
      top: ${px2rem(16)};
      cursor: pointer;
    }

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: ${px2rem(24)};
      line-height: ${px2rem(32)};
      color: #fff;
      text-align: center;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      background-color: transparent;
      text-transform: uppercase;
      border: 1px solid #fff;
      height: ${px2rem(40)};
      padding: 0 ${px2rem(24)};
      border-radius: ${px2rem(8)};
      color: #fff;
      font-size: ${px2rem(18)};
      font-family: 'Lato';
      font-weight: 700;
      padding: 0 ${px2rem(20)};
      cursor: pointer;
    }

    button + button {
      background-color: var(--default-orange);
      border: none;
      margin-left: ${px2rem(16)};
    }

    @media screen and (max-width: 997px) {
      justify-content: center;
    }
  }
`;
