import styled, { css } from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 998px) {
    width: 50%;
    margin-left: auto;
  }
`;

const Plan = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: ${px2rem(10)} 0;

  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: ${px2rem(24)};
    line-height: ${px2rem(32)};
    margin-bottom: ${px2rem(32)};
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;

      strong {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: ${px2rem(20)};
        line-height: ${px2rem(28)};
        color: var(--default-orange);
        margin-bottom: ${px2rem(18)};
      }

      span {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: ${px2rem(20)};
        line-height: ${px2rem(28)};
      }
    }

    button {
      background-color: var(--default-orange);
      border: none;
      margin: 0;
      height: ${px2rem(40)};
      border-radius: ${px2rem(8)};
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-family: 'Lato';
      padding: 0 ${px2rem(20)};
      cursor: pointer;
    }
  }
  @media screen and (min-width: 998px) {
    padding: ${px2rem(40)} 0;
  }
`;

const Billing = styled.div`
  padding: ${px2rem(40)} 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    @media screen and (min-width: 998px) {
      font-size: ${px2rem(24)};
      line-height: ${px2rem(32)};
      margin-bottom: ${px2rem(32)};
    }
  }

  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: ${px2rem(16)};
    line-height: ${px2rem(14)};
    margin-bottom: ${px2rem(8)};

    span {
      color: var(--default-orange);
      font-weight: bold;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: ${px2rem(16)};
      line-height: ${px2rem(32)};
      display: flex;
      align-items: center;

      img {
        margin-right: ${px2rem(16)};
        height: ${px2rem(32)};
        width: auto;
      }
    }

    button {
      background-color: var(--default-orange);
      border: none;
      margin: 0;
      height: ${px2rem(40)};
      border-radius: ${px2rem(8)};
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-family: 'Lato';
      padding: 0 ${px2rem(20)};
      cursor: pointer;
    }
  }
`;

interface HistoryListInterface {
  readonly isOpen?: boolean;
}

const HistoryList = styled.div<HistoryListInterface>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  button {
    background: none;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 80px;
    svg {
      font-size: ${px2rem(32)};
      margin-right: ${px2rem(24)};
      color: #fff;
      transition: ease 0.2s all;

      ${({ isOpen }) =>
    isOpen &&
    css`
          transform: rotate(180deg);
        `}
    }

    h3 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      color: #fff;
    }
  }

  ul {
    width: 100%;
    list-style: none;
    box-sizing: border-box;

    li {
      padding: ${px2rem(20)} 0;
      display: flex;

      a {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      span {
        margin-right: ${px2rem(40)};
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        color: var(--default-orange);
        font-size: ${px2rem(14)};
      }

      .buttonPlay {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background-color: #fb4f14;
        border-radius: 10px;
        color: white;
      }

      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: ${px2rem(14)};
        line-height: ${px2rem(32)};
        color: #fff;
      }
    }
  }

  @media screen and (min-width: 998px) {
    button {
      height: ${px2rem(120)};
      h3 {
        font-size: ${px2rem(24)};
      }
    }
    ul li {
      span {
        font-size: ${px2rem(18)};
      }
      p {
        font-size: ${px2rem(18)};
      }
    }
  }
`;

const ChangePassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: ${px2rem(40)};
  position: relative;

  section {
    > span {
      color: var(--default-orange);
      margin-bottom: ${px2rem(40)};
      display: flex;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-bottom: ${px2rem(8)};

      label {
        color: #fff;
        font-size: ${px2rem(18)};
        margin-bottom: ${px2rem(24)};
      }

      input {
        border: none;
        margin: 0;
        background-color: transparent;
        font-size: ${px2rem(20)};
        color: #fff;
        width: 100%;
        outline: none;
        padding: 0 ${px2rem(10)} ${px2rem(40)};
        height: ${px2rem(80)};
        flex: 100%;
      }
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    input {
      border: none;
      margin: 0;
      background-color: transparent;
      font-size: ${px2rem(20)};
      color: #fff;
      width: 100%;
      outline: none;
      flex: 100%;
      padding: 0 ${px2rem(10)} ${px2rem(10)};
    }

    > button {
      background-color: var(--default-orange);
      border: none;
      margin: 0;
      height: ${px2rem(40)};
      border-radius: ${px2rem(8)};
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-family: 'Lato';
      padding: 0 ${px2rem(20)};
      cursor: pointer;
      white-space: nowrap;

      &.close {
        margin-right: 8px;
        background: none;
        border: 1px solid #fff;
      }
    }
  }

  > label {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 15px;
  }
  @media screen and (min-width: 998px) {
    input {
      padding: 0 ${px2rem(10)} ${px2rem(40)};
      height: ${px2rem(80)};
    }
    > label {
      font-size: ${px2rem(24)};
      margin-bottom: ${px2rem(32)};
    }
  }
`;

interface NameInputInterface {
  readonly isFocus?: boolean;
}

const NameInput = styled.div<NameInputInterface>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: ${px2rem(40)};
  position: relative;

  ${({ isFocus }) =>
    isFocus &&
    css`
      border-bottom: 1px solid var(--default-orange);
    `}

  > div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    input {
      border: none;
      margin: 0;
      background-color: transparent;
      font-size: 16px;
      color: #fff;
      width: 100%;
      outline: none;
      flex: 100%;
      padding: 0 ${px2rem(10)} ${px2rem(10)};
    }

    > button {
      background-color: var(--default-orange);
      border: none;
      margin: 0;
      height: ${px2rem(40)};
      border-radius: ${px2rem(8)};
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-family: 'Lato';
      padding: 0 ${px2rem(20)};
      cursor: pointer;
      white-space: nowrap;

      &.close {
        margin-right: 8px;
        background: none;
        border: 1px solid #fff;
      }
    }
  }

  > label {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 15px;
  }
  @media screen and (min-width: 998px) {
    input {
      padding: 0 ${px2rem(10)} ${px2rem(40)};
      height: ${px2rem(80)};
      font-size: ${px2rem(20)};
    }
    > label {
      font-size: ${px2rem(24)};
      margin-bottom: ${px2rem(32)};
    }
  }
`;

export { Wrapper, Plan, NameInput, HistoryList, Billing, ChangePassword };
