import Home from "pages/Home";
import Author from "pages/Author"
import MyList from "pages/MyList";
import Search from "pages/Search";
import Live from "pages/Live";
import Profile from "pages/Profile";
import Movies from "pages/Movies";
import Detail from "pages/Detail";
import Authors from "pages/Authors";
import Music from "pages/Music";
import Courses from "pages/Courses";
import Lectures from "pages/Lectures";
import Series from "pages/Series";
import Documentary from "pages/Documentary";
import Sermon from "pages/Sermon";
import Albums from "pages/Albums";
import Shows from "pages/Shows";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { path: '/home', component: Home, exact: true },
  { path: '/albums', component: Albums },
  { path: '/shows', component: Shows },
  { path: '/author/:id', component: Author },
  { path: '/my-list', component: MyList },
  { path: '/search/:term', component: Search },
  { path: '/live', component: Live },
  { path: '/profile', component: Profile },
  { path: '/movies', component: Movies },
  { path: '/trail/:id', component: Detail },
  { path: '/course/:id', component: Detail },
  { path: '/movie/:id/:trail?', component: Detail },
  { path: '/authors', component: Authors },
  { path: '/music', component: Music },
  { path: '/courses', component: Courses },
  { path: '/lectures', component: Lectures },
  { path: '/series', component: Series },
  { path: '/documentary', component: Documentary },
  { path: '/sermon', component: Sermon }

]