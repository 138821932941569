import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import LiveCard from '../../components/Live/LiveCard';
import { WrapperLive, Grid, NextAndPreviousLives, NoContent } from './style';
import Calendar from '../../assets/icon-calendar.svg';
import { useState, useCallback, useMemo } from 'react';
import { getAllLives } from '../../services/lives';
import { useEffect } from 'react';
import CurrentLive from './CurrentLive';
import { LivesProps } from 'services/lives/models/liveModels';
import GridSkeleton from 'components/Commons/GridSkeleton';
import { getContentTag } from 'services/tags';
import { Content } from 'services/contents/models/content-interface';
import { LiveCardWrapper } from './style';
import { Link } from 'react-router-dom';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';

const WithoutContent = ({ text }: any) => (
  <NoContent>
    <div>
      <img src={Calendar} alt="" />
    </div>
    <p>{text}</p>
  </NoContent>
);

export default function Live() {
  const [lives, setLives] = useState<LivesProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pastLives, setPastLives] = useState<Content[]>([]);

  const getPastLive = async () => {
    const resultContent = await getContentTag('lives_anteriores');
    setPastLives(resultContent);
  };

  const getLiveToShow = useCallback(async () => {
    const allLives = await getAllLives();
    if (allLives) {
      setLives(allLives);
    } else {
      setLives([]);
    }
  }, []);

  const nextLives = useMemo(() => {
    return lives?.filter(
      (live) => !(live.status === 'ERROR' || live.status === 'ACTIVE') &&
        live.start && new Date(live.start)?.getTime() > Date.now() &&
        live.stop && new Date(live.stop)?.getTime() > Date.now()
    );
  }, [lives]);

  useEffect(() => {
    getLiveToShow();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [getLiveToShow]);

  useEffect(() => {
    getPastLive();
  }, []);

  return (
    <WrapperLive>
      <NavBar />
      <CurrentLive />
      <NextAndPreviousLives>
        <Grid>
          <h1>Próximas lives</h1>
          {nextLives ? nextLives.map((live, key) => <LiveCard key={key} data={live} />) : null}
        </Grid>
        {isLoading ? (
          <GridSkeleton />
        ) : (
          <>{nextLives.length < 1 ? <WithoutContent text={'Não há nenhuma transmissão agendada ainda.'} /> : null}</>
        )}
        <Grid>
          {pastLives.length > 0 && <h1>Lives anteriores</h1>}
          {pastLives?.length > 0 &&
            pastLives.map((live) => {
              return (
                <LiveCardWrapper key={live.content_id}>
                  <Link to={`/player/${live.content_id}`}>
                    <img src={live?.thumbnail} alt="" />
                    <p>{live?.title}</p>
                  </Link>
                </LiveCardWrapper>
              );
            })}
        </Grid>
      </NextAndPreviousLives>
      <Footer />
      <MobileNavigatorSpace />
    </WrapperLive>
  );
}
