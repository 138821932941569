import React, { useState } from 'react';
import { Wrapper, FlowTitle, Form, Grid, RightContent, LeftContent, ErrorWrapper } from '../style';
import IconArrowBack from '../../../assets/icon-arrow-back.svg';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { checkoutPayment } from '../../../services/payment';
import PaymentCheckout from '../../../services/payment/models/payment-interface';
import { AES } from 'crypto-js';
import { checkoutSchema } from './checkoutSchema';
import { toast } from 'react-toastify';
import ButtonLoading from 'components/ButtonLoading';

export default function CheckoutBlock() {
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      card_number: '',
      cvv: '',
      vality: '',
    },
    validationSchema: checkoutSchema,
    onSubmit: async ({ card_number, cpf, cvv, name, vality }) => {
      const token = localStorage.getItem('payment-token-teo') as string;

      const payment: PaymentCheckout = {
        card_cvv: cvv,
        card_expiration: vality.replace(' ', '/'),
        card_number: card_number.replaceAll(' ', ''),
        holder_name: name,
        registry_code: cpf.replaceAll('.', '').replaceAll('-', ''),
      };

      const data = AES.encrypt(JSON.stringify(payment), `Bearer ${token}`).toString();
      setIsLoadingButton(true);
      await checkoutPayment()
        .then(() => {
          toast.success('Pagamento efetuado com sucesso');
          localStorage.removeItem('payment-token-teo');
          history.push('/login');
        })
        .catch(() => {
          toast.error('Erro ao realizar pagamento');
        });
      setIsLoadingButton(false);
    },
  });

  const handleChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maskValue = event.target.value
      .replace('\b([3-6]d{3})(?: *-* *d{4}){2} *-* *(d{4})\b', '$1********$2')
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    formik.setFieldValue('cpf', maskValue);
  };

  const handleChangeCardNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maskValue = event.target.value
      .replace(/\D/g, '')
      .replace(/^(\d{4})(\d)/g, '$1 $2')
      .replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3')
      .replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
    formik.setFieldValue('card_number', maskValue);
  };

  const handleChangeValityDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maskValue = event.target.value.replace(/^(\d{2})(\d)/g, '$1/$2');
    formik.setFieldValue('vality', maskValue);
  };

  return (
    <Wrapper>
      <Grid>
        <RightContent>
          <Link to="/register/choose-plan" className="back">
            <img src={IconArrowBack} alt="" />
          </Link>
          <FlowTitle>
            Informe
            <br /> seu cartão.
          </FlowTitle>
          <p>Passo 4 de 4</p>
        </RightContent>
        <LeftContent>
          <hr />
          <Form onSubmit={formik.handleSubmit}>
            <div className="default-input">
              <input
                name="name"
                className={formik.errors.name && 'error'}
                type="text"
                placeholder="Nome"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <label>Nome como está no cartão</label>
              {formik.errors.name && formik.touched.name ? <ErrorWrapper>{formik.errors.name}</ErrorWrapper> : null}
            </div>
            <div className="default-input">
              <input
                name="cpf"
                className={formik.errors.cpf && 'error'}
                type="text"
                placeholder="CPF"
                maxLength={14}
                value={formik.values.cpf}
                onChange={handleChangeCpf}
              />
              <label>CPF</label>
              {formik.errors.cpf && formik.touched.cpf ? <ErrorWrapper>{formik.errors.cpf}</ErrorWrapper> : null}
            </div>
            <div className="default-input">
              <input
                name="card_number"
                className={formik.errors.card_number && 'error'}
                type="text"
                maxLength={19}
                placeholder="Número do cartão"
                value={formik.values.card_number}
                onChange={handleChangeCardNumber}
              />
              <label>Número do cartão</label>
              {formik.errors.card_number && formik.touched.card_number ? (
                <ErrorWrapper>{formik.errors.card_number}</ErrorWrapper>
              ) : null}
            </div>
            <div className="one-row">
              <div className="default-input ">
                <input
                  name="vality"
                  className={formik.errors.vality && 'error'}
                  type="text"
                  maxLength={7}
                  placeholder="Validade"
                  value={formik.values.vality}
                  onChange={handleChangeValityDate}
                />
                <label htmlFor="">Validade</label>
                {formik.errors.vality && formik.touched.vality ? (
                  <ErrorWrapper>{formik.errors.vality}</ErrorWrapper>
                ) : null}
              </div>
              <div className="default-input">
                <input
                  name="cvv"
                  className={formik.errors.cvv && 'error'}
                  type="text"
                  placeholder="CVV"
                  maxLength={3}
                  value={formik.values.cvv}
                  onChange={formik.handleChange}
                />
                <label htmlFor="">CVV</label>
                {formik.errors.cvv && formik.touched.cvv ? <ErrorWrapper>{formik.errors.cvv}</ErrorWrapper> : null}
              </div>
            </div>
            <div className="buttons">
              <Link to="/">Cancelar</Link>
              <ButtonLoading isLoading={isLoadingButton} disabled={isLoadingButton} className="submit">
                Criar conta
              </ButtonLoading>
            </div>
          </Form>
        </LeftContent>
      </Grid>
    </Wrapper>
  );
}
