import React, { useState } from 'react';
import { ContextBePremiumModal } from 'utils/context';

export const PremiumModalProvider = ({ children }: any) => {
  const [openModalPremium, setOpenModalPremium] = useState(false);

  return (
    <ContextBePremiumModal.Provider value={{ openModalPremium, setOpenModalPremium }}>
      {children}
    </ContextBePremiumModal.Provider>
  );
};
