import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSimilar } from '../../../services/search';
import RelatedCarousel from '../../Commons/RelatedCarousel';
import MovieCard from '../MovieCard';
import { RelatedWrapper, WarningNotification } from './style';

export default function RelatedMovie({ data }: any) {
  const [similarContent, setSimilarContent] = useState<any>();
  const {
    location: { pathname },
  } = useHistory();

  const getSimilarData = useCallback(async () => {
    const categories = data?.categories?.map((category: any) => category.id);
    const similar = await getSimilar(categories);
    setSimilarContent(similar);
  }, [data?.categories]);

  useEffect(() => {
    if (data) getSimilarData();
  }, [data, getSimilarData]);

  if (!data) return null;

  return (
    <RelatedWrapper>
      {similarContent && (
        <RelatedCarousel title="Títulos semelhantes">
          {similarContent && similarContent.length ? (
            similarContent?.map((content: any) => {
              if (content?.is_library)
                return (
                  <MovieCard
                    width={305}
                    info={true}
                    tail
                    data={content}
                    to={!data?.user?.premium && data?.info?.premium ? pathname : `/movie/${content.content_id}`}
                    key={`movie-${content.content_id}`}
                  />
                );
              else
                return (
                  <MovieCard
                    width={305}
                    info={true}
                    tail
                    data={content}
                    to={!data?.user?.premium && data?.info?.premium ? pathname : `/trail/${content.trail_id}`}
                    key={`trail-${content.trail_id}`}
                  />
                );
            })
          ) : (
            <WarningNotification>Não foram encontrados títulos semelhantes</WarningNotification>
          )}
        </RelatedCarousel>
      )}
    </RelatedWrapper>
  );
}
