import { AES } from 'crypto-js';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { checkoutPayment } from '../../../services/payment';
import PaymentCheckout from '../../../services/payment/models/payment-interface';
import { schema } from './schema';
import { Container, ErrorWrapper, Form, Error } from './style';
import PlanCheck from '../../../assets/check.svg';
import { BsExclamationOctagon } from 'react-icons/bs';
import ButtonLoading from 'components/ButtonLoading';
import { getDefaultToken } from 'services/userToken';

interface IProps {
  onFinish: () => void;
  onCancel: () => void;
  onClose: () => void;
}

const BePremium: React.FC<IProps> = ({ onFinish, onCancel, onClose }: IProps) => {
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const user = localStorage.getItem('user') || '{}';
  const { user_id } = JSON.parse(user);

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      card_number: '',
      cvv: '',
      vality: '',
    },
    validationSchema: schema,
    onSubmit: async ({ card_number, cpf, cvv, name, vality }) => {
      const token = localStorage.getItem('api_token') || '';
      setError(false);

      const payment: PaymentCheckout = {
        card_cvv: cvv,
        card_expiration: vality.replace(' ', '/'),
        card_number: card_number.replaceAll(' ', ''),
        holder_name: name,
        registry_code: cpf.replaceAll('.', '').replaceAll('-', ''),
      };

      const data = AES.encrypt(JSON.stringify(payment), `Bearer ${token}`).toString();

      try {
        setLoading(true);
        await checkoutPayment();
        const { api_token } = await getDefaultToken(user_id);
        localStorage.setItem('api_token', api_token);
        onFinish();
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maskValue = event.target.value
      .replace('\b([3-6]d{3})(?: *-* *d{4}){2} *-* *(d{4})\b', '$1********$2')
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    formik.setFieldValue('cpf', maskValue);
  };

  const handleChangeCardNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maskValue = event.target.value
      .replace(/\D/g, '')
      .replace(/^(\d{4})(\d)/g, '$1 $2')
      .replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3')
      .replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
    formik.setFieldValue('card_number', maskValue);
  };

  const handleChangeValityDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maskValue = event.target.value.replace(/^(\d{2})(\d)/g, '$1/$2');
    formik.setFieldValue('vality', maskValue);
  };

  return (
    <Container>
      <div>
        <button onClick={onClose}>
          <FiX />
        </button>

        <div>
          <aside>
            <h3>Seja Premium</h3>
            <h2>R$14,90/mês.</h2>

            <ul>
              <li>
                <img src={PlanCheck} alt="" />
                Cancele quando quiser
              </li>
              <li>
                <img src={PlanCheck} alt="" />
                Conteúdo infantil
              </li>
              <li>
                <img src={PlanCheck} alt="" />
                Acesso a todo o catálogo
              </li>
              <li>
                <img src={PlanCheck} alt="" />
                Assista sem anúncios
              </li>
              <li>
                <img src={PlanCheck} alt="" />
                Assista em HD
              </li>
              <li>
                <img src={PlanCheck} alt="" />4 telas simultâneas
              </li>
              <li>
                <img src={PlanCheck} alt="" />
                Assista em qualquer dispositivo
              </li>
            </ul>
          </aside>

          <Form onSubmit={formik.handleSubmit}>
            {error && (
              <Error>
                <BsExclamationOctagon />{' '}
                <p>
                  Ocorreu um erro ao realizar a assinatura.
                  <br />
                  Verifique os dados e tente novamente.
                </p>
              </Error>
            )}

            <div className="default-input">
              <input
                name="name"
                className={formik.errors.name && 'error'}
                type="text"
                placeholder="Nome"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <label>Nome como está no cartão</label>
              {formik.errors.name && formik.touched.name ? <ErrorWrapper>{formik.errors.name}</ErrorWrapper> : null}
            </div>
            <div className="default-input">
              <input
                name="cpf"
                className={formik.errors.cpf && 'error'}
                type="text"
                placeholder="CPF"
                maxLength={14}
                value={formik.values.cpf}
                onChange={handleChangeCpf}
              />
              <label>CPF</label>
              {formik.errors.cpf && formik.touched.cpf ? <ErrorWrapper>{formik.errors.cpf}</ErrorWrapper> : null}
            </div>
            <div className="default-input">
              <input
                name="card_number"
                className={formik.errors.card_number && 'error'}
                type="text"
                maxLength={19}
                placeholder="Número do cartão"
                value={formik.values.card_number}
                onChange={handleChangeCardNumber}
              />
              <label>Número do cartão</label>
              {formik.errors.card_number && formik.touched.card_number ? (
                <ErrorWrapper>{formik.errors.card_number}</ErrorWrapper>
              ) : null}
            </div>
            <div className="one-row">
              <div className="default-input ">
                <input
                  name="vality"
                  className={formik.errors.vality && 'error'}
                  type="text"
                  maxLength={7}
                  placeholder="Validade"
                  value={formik.values.vality}
                  onChange={handleChangeValityDate}
                />
                <label htmlFor="">Validade</label>
                {formik.errors.vality && formik.touched.vality ? (
                  <ErrorWrapper>{formik.errors.vality}</ErrorWrapper>
                ) : null}
              </div>
              <div className="default-input">
                <input
                  name="cvv"
                  className={formik.errors.cvv && 'error'}
                  type="text"
                  placeholder="CVV"
                  maxLength={3}
                  value={formik.values.cvv}
                  onChange={formik.handleChange}
                />
                <label htmlFor="">CVV</label>
                {formik.errors.cvv && formik.touched.cvv ? <ErrorWrapper>{formik.errors.cvv}</ErrorWrapper> : null}
              </div>
            </div>

            <footer>
              <button type="button" onClick={onCancel}>
                Cancelar
              </button>
              <ButtonLoading isLoading={isLoading} type="submit">
                Salvar
              </ButtonLoading>
            </footer>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default BePremium;
