import * as Yup from 'yup';
import { passwordPattern } from '../../../utils/regex';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").required(''),
  password: Yup
    .string()
    .required('')
    .min(8, "Senha deve conter pelo menos 8 caracteres")
    .matches(
      passwordPattern,
      "Deve conter uma letra maiúscula, um número e um caracter especial"
    ),
});


export {
  LoginSchema
}