import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import MovieCard from '../../components/Movies/MovieCard';
import { ListContents, Grid, TitleList, WithoutContent, CardWrapper } from './style';
import EmptyList from '../../assets/icon-empty.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import GridSkeleton from '../../components/Commons/GridSkeleton';
import { getContentTag, getTrailTag } from '../../services/tags';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';
import { Mobile } from 'utils/responsiveRules';

const NoContent = () => (
  <WithoutContent>
    <div>
      <img src={EmptyList} alt="" />
    </div>
    <p>Nenhum conteúdo a esta categoria no momento. Navegue pelo catálogo para encontrar mais conteúdos.</p>
  </WithoutContent>
);

export default function Documentary() {
  const [content, setContent] = useState<any>();

  const getData = async () => {
    const content = await getContentTag('documentarios');
    const trail = await getTrailTag('documentarios');
    setContent(content.concat(trail));
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <NavBar />
      <ListContents>
        <TitleList>Documentários</TitleList>
        {!content && <GridSkeleton />}
        {content?.length <= 0 && <NoContent />}
        <Grid>
          {content &&
            content.map((documentary: any, key: any) => {
              if (documentary?.is_library) {
                return (
                  <CardWrapper>
                    <MovieCard to={`/movie/${documentary.content_id}`} data={documentary} key={`documentary-${key}`} />
                  </CardWrapper>
                );
              } else {
                return (
                  <CardWrapper>
                    <MovieCard to={`/trail/${documentary.trail_id}`} data={documentary} key={`documentary-${key}`} />
                  </CardWrapper>
                );
              }
            })}
        </Grid>
      </ListContents>
      <Footer />
      <Mobile>
        <MobileNavigatorSpace />
      </Mobile>
    </>
  );
}
