import NavBar from '../../components/Commons/NavBar';
import { ContainerImage, Image, ListContents, Grid, TitleList } from './style';
import authorImage from '../../assets/author.jpg';
import AuthorBiography from '../../components/Authors/AuthorBiography';
import MovieCard from '../../components/Movies/MovieCard';
import Footer from '../../components/Commons/Footer';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { getAuthor } from '../../services/authors';
import { Author } from '../../services/authors/models/author-interface';
import { Content } from '../../services/contents/models/content-interface';

export default function AuthorDetail() {
  const [author, setAuthor] = useState<Author>();
  const { id } = useParams<any>();

  const getResultAuthor = async (id: string) => {
    const resultAuthor = await getAuthor(id);
    setAuthor(resultAuthor);
  };
  useEffect(() => {
    if (id) {
      getResultAuthor(id);
    }
  }, [id]);

  const hasCotent = author ? author?.trails?.length > 0 || author?.contents?.length > 0 : null;
  return (
    <div>
      <NavBar />
      <ContainerImage>
        <Image image={author?.info?.banner_url} />
        {author && <AuthorBiography author={author} />}
      </ContainerImage>
      {hasCotent && (
        <ListContents>
          <Grid>
            <TitleList>Conteúdos por {author && author.name}</TitleList>
            {author &&
              author.trails.map((trail: any, key) => (
                <MovieCard to={`/trail/${trail?.trail_id}`} data={trail} key={`trail-${key}`} />
              ))}
            {author &&
              author.contents.map((content: any, key) => (
                <MovieCard to={`/movie/${content.content_id}`} data={content} key={`content-${key}`} />
              ))}
          </Grid>
        </ListContents>
      )}

      {author && <Footer />}
    </div>
  );
}
