import styled from 'styled-components';

export const KeepWatchingCardContainer = styled.div`
  position: absolute;
  width: 450px;
  height: 240px;
  border-radius: 8px;
  padding: 28px 40px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 90px;
  right: 32px;
  animation: enter 1s ease-in-out;

  :hover {
    opacity: 0.8;
  }

  .liked-content {
    padding-bottom: 18px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;

    span {
      color: white;
    }

    img {
      border: 1px solid white;
      border-radius: 50%;
      padding: 10px;
    }
  }

  @keyframes enter {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  h3 {
    color: white;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 5px;

    span {
      font-weight: 700;
      color: var(--default-orange);
    }
  }

  h1 {
    font-size: 24px;
    color: white;
    font-weight: 700;
  }

  button#play {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: auto;
    color: white;
    background-color: var(--default-orange);
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 8px;
    padding: 15px 25px;
    outline: none;
    border: none;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
    }
  }

  button#close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    padding: 0.5rem;
    border-radius: 1rem;
    line-height: 0;
    border: none;
    height: 1.5rem;
    width: 1.5rem;
    background-color: var(--default-orange);
    color: #fff;
    font-weight: bold;
  }
`;
