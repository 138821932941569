import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAllPlans } from 'services/Plans';
import { formatPrice } from 'utils/formatPrice';

import PlanModelsProps from '../../../services/Plans/models/plan-interface';

import PlanCheck from '../../../assets/check.svg';
import { PlansWrapper, PlansTitle, PlanName, PlanPrice, PlanBenefits, PlanButton, Plan } from './style';

export default function Plans() {
  const history = useHistory();

  const [plans, setPlans] = useState<PlanModelsProps[]>();

  const getPlans = async () => {
    const resultPlans = await getAllPlans();
    setPlans(resultPlans);
  };
  useEffect(() => {
    getPlans();
  }, []);

  return (
    <PlansWrapper id="plans">
      <div className="grid">
        <PlansTitle>Planos que cabem no seu bolso.</PlansTitle>
        <div>
          {plans?.map((plan) => {
            return (
              <Plan>
                <PlanName>{plan.name}</PlanName>
                <PlanPrice>{plan.name === 'Básico' ? 'Gratuito' : formatPrice(plan.price)}</PlanPrice>
                <PlanBenefits>
                  {plan?.benefits?.map((benefit) => {
                    return (
                      <li>
                        <img src={PlanCheck} alt="" />
                        {benefit}
                      </li>
                    );
                  })}
                </PlanBenefits>
                <PlanButton onClick={() => history.push('/register')}>Quero assinar</PlanButton>
              </Plan>
            );
          })}
        </div>
      </div>
    </PlansWrapper>
  );
}
