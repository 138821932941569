import React, { useState } from 'react';
import { TabWrapper, ButtonsList, ButtonListItem, Button } from './style';

export default function Tab({ tabs }: any) {
  const [currentTab, setTab] = useState<any>(0);
  const TabContent = tabs[currentTab].component;
  return (
    <TabWrapper>
      <div className="buttons">
        <ButtonsList>
          {tabs?.map((tab: any, key: any) => (
            <ButtonListItem key={key}>
              <Button
                type="button"
                onClick={() => setTab(key)}
                className={currentTab === key ? 'active' : ''}
                title={tab.title}
              >
                {tab.title}
                <span />
              </Button>
            </ButtonListItem>
          ))}
        </ButtonsList>
      </div>
      <TabContent />
    </TabWrapper>
  )
}