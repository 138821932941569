import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const FooterWrapper = styled.footer`
  background-color: #000;
  padding: ${px2rem(18)} ${px2rem(32)};
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: ${px2rem(160)};
    }
    span {
      color: #fff;
    }
  }
  .links {
    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      align-items: center;
      li {
        color: #fff;
        font-size: ${px2rem(16)};
        margin: ${px2rem(10)} 0;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      cursor: pointer;

      &:not(:last-of-type) {
        margin-right: ${px2rem(5)};
      }
      span {
        display: flex;
        align-items: center;
        border: 1px solid #fff;
        padding: 11.7px;
        border-radius: 50%;
        svg {
          color: #fff;
          font-size: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 997px) {
    > div:first-of-type {
      order: 3;
      span {
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 998px) {
    justify-content: space-between;
    flex-direction: row;
    position: absolute;
    width: 100%;
    .links {
      ul {
        margin-top: -20px;
        flex-direction: row;
        li:not(:last-of-type) {
          margin: 0 ${px2rem(16)} 0 0;
        }
      }
    }
    .social {
      margin: 0;
    }
  }
`;

const SectionLogos = styled.section`
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    a,
    img {
      max-width: 100%;
      .cast {
        width: 70px;
      }
      .radio {
        width: 125px;
      }
      .blog {
        width: 180px;
      }
    }
  }
`;

export { FooterWrapper, SectionLogos };
