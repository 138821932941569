import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const TabWrapper = styled.div`

  @media screen and (min-width: 998px){
    padding-top: ${px2rem(69)};
  }
`;

const ButtonsList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 auto;
  width: 90%;
  padding: 50px 0 20px;
  @media screen and (min-width: 998px){
    width: 85%;
    padding-bottom: 20px;
  }
`;

const ButtonListItem = styled.li`
 &:not(:last-of-type){
    margin-right: ${px2rem(25)};
  }
 @media screen and (min-width: 998px){
  &:not(:last-of-type){
    margin-right: ${px2rem(40)};
  }
 }
`

const Button = styled.button`
  background: transparent;
  border: none;
  color: #FFF;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: color 0.3s ease;
  font-size: 14px;
  span{
    height: 3px;
    width: 0;
    background-color: var(--default-orange);
    margin-top: ${px2rem(10)};
    transition: width 0.3s ease, opacity 0.2s ease;
    opacity: 0;
  }
  &.active{
    color: var(--default-orange);
    span{
      width: 100%;
      opacity: 1;
    }
  }

  @media screen and (min-width: 998px){
    font-size: ${px2rem(18)};
    &:hover{
      color: var(--default-orange);
      span{
        width: 100%;
        opacity: 1;
        transition: width 0.3s ease, opacity 0.2s ease;
      }
    }
  }
`;

export {
  TabWrapper,
  ButtonsList,
  ButtonListItem,
  Button
}