import { useLocation } from 'react-router';
import Skeleton from '../../Skeleton';
import { WrapperSlider } from './style';

export default function HomeSliderSkeleton() {
  const { pathname } = useLocation();
  return (
    <WrapperSlider>
      <Skeleton isKids={pathname === '/kids'} />
      <Skeleton isKids={pathname === '/kids'} />
      <Skeleton isKids={pathname === '/kids'} />
      <Skeleton isKids={pathname === '/kids'} />
    </WrapperSlider>
  );
}
