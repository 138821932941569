import styled, { css } from 'styled-components';
import { px2rem } from '../../utils/fontUtil';
import ArrowDrop from '../../assets/arrow-drop.svg';

interface ImageProps {
  image: string;
}

const Wrapper = styled.div`
  background-color: #1a1e21;
  min-height: calc(100vh - 135px);
`;

const SkeletonContainer = styled.div`
  padding: 100px 0 60px;
`;

const ContainerImage = styled.div`
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.3s ease;
  ${(props: ImageProps) =>
    props.image &&
    css`
      opacity: 1;
      background-image: linear-gradient(359.55deg, #1a1e21 10%, rgba(26, 30, 33, 0.3) 56%),
        url(${(props: ImageProps) => props.image});
    `}
`;

const ListContents = styled.div`
  padding-bottom: ${px2rem(60)};
`;

const WrapperSeason = styled.div`
  padding-bottom: ${px2rem(20)};
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 24px;
  gap: 24px;
`;

const CardWrapper = styled.div`
  --card-width: 310px;

  width: var(--card-width);
  height: auto;
  overflow: hidden;

  @media screen and (max-width: 1400px) {
    width: calc(var(--card-width) * 0.8);
  }

  @media screen and (max-width: 900px) {
    width: calc(var(--card-width) * 0.6);
  }

  @media screen and (max-width: 600px) {
    width: calc(var(--card-width) * 0.5);
  }

  @media screen and (max-width: 480px) {
    width: calc(var(--card-width) * 0.3);
  }
`;

const TitleList = styled.h1`
  color: #fff;
  font-size: ${px2rem(24)};
  padding: 0 ${px2rem(30)} ${px2rem(24)};

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(40)};
    grid-column: 1 / span 3;
  }
`;

const SeasonSelector = styled.select`
  border: 1px solid #fff;
  background-color: transparent;
  margin: 0 ${px2rem(24)} ${px2rem(35)};
  height: ${px2rem(40)};
  padding: 0 ${px2rem(30)} 0 ${px2rem(20)};
  min-width: ${px2rem(228)};
  border-radius: ${px2rem(6)};
  background-image: url(${ArrowDrop});
  background-repeat: no-repeat;
  background-position: 97%;
  width: ${px2rem(250)};
  color: #fff;
  &,
  option {
    font-size: ${px2rem(16)};
    font-family: 'Lato';
    font-weight: 400;
    cursor: pointer;
    background-color: #1a1e21;
  }
  option {
    color: #fff;
  }
`;

export {
  Wrapper,
  SkeletonContainer,
  ContainerImage,
  CardWrapper,
  ListContents,
  Grid,
  TitleList,
  SeasonSelector,
  WrapperSeason,
};
