import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: ${px2rem(48)};
  border-bottom: 1px solid #ffffff;
  background-color: var(--default-background) !important;

button {
  padding: 0 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

  label {
    font-size: 20px;
    font-family: "Lato", sans-serif;
    color: #fff;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s linear;
    font-weight: lighter;
  }
  
  input {
    font-size: 20px;
    flex: 1;
    font-family: "Lato", sans-serif;
    margin: 0;
    border: none;
    color: #fff;
    outline: none;
    background: none;
    padding: ${px2rem(10)}  0 ;
    
    &::placeholder {
      font-family: "Lato", sans-serif;
      color: #fff;
      opacity: 0;
    }
    &:not(:placeholder-shown),
    &:focus {
      ~ label {
        transform: translate(0, -1.75rem);
        font-size: 16px;
      }
    }

    &.error {
      color: #ff3030;
      ~ label {
        color: #ff3030;
      }
      border-color: #ff3030;
    }
  }
  span.error {
    color: #ff3030;
    font-size: ${px2rem(14)};
    margin-top: ${px2rem(3)};
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

export const ErrorWrapper = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: 14px;
  margin-top: 4px;
  text-align: left;
`;