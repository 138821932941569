import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface INav {
  home?: boolean;
}

interface IHeader {
  isScrolled: boolean;
}

const Nav = styled.header<IHeader>`
  display: flex;
  position: fixed;
  background-color: ${({ isScrolled }: IHeader) => (isScrolled ? '#fff' : 'transparent')};
  transition: all;
  transition-duration: 1s;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: ${px2rem(95)};
  width: 100%;
  z-index: 9999;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const MobileHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 0 20px;
  height: ${px2rem(95)};
  position: absolute;
  width: 100%;
  z-index: 9999;
`;

const NavLogo = styled(Link)`
  img {
    width: ${px2rem(150)};
  }

  @media screen and (max-width: 480px) {
    img {
      width: 85px;
    }
  }
`;

const NavSearch = styled.form`
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 ${px2rem(20)};
  height: ${px2rem(40)};
  border-radius: ${px2rem(20)};
  background-color: #ccc;
  input {
    background-color: transparent;
    border: none;
    font-family: 'Lato';
    outline: none;
    color: #000;
    width: 100%;
    height: 100%;
    padding: 0 ${px2rem(10)};
  }
  img {
    width: ${px2rem(19)};
  }
  @media screen and (min-width: 998px) {
    width: ${px2rem(332)};
    margin: 0 ${px2rem(70)} 0 ${px2rem(10)};
  }
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;
const NavButton = styled.button`
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 998px) {
    &:first-of-type {
      margin-right: ${px2rem(40)};
    }
  }

  @media screen and (max-width: 768px) {
    svg {
      font-size: 32px;
      &.active {
        color: var(--default-orange);
      }
    }
  }
  span {
    font-size: ${px2rem(16)};

    &.active {
      color: var(--default-orange);
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SubMenu = styled.div`
  background-color: #fff;
  position: absolute;
  right: ${px2rem(-20)};
  top: ${px2rem(50)};
  width: 188px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${px2rem(20)};
  border-radius: 0px 0px ${px2rem(8)} ${px2rem(8)};
  box-shadow: 1px 1px 0px #00000024;
  a,
  button,
  p {
    color: var(--default-background);
    font-size: ${px2rem(16)};
    font-weight: 400;
    text-align: left;
    margin-top: ${px2rem(15)};
  }
  button {
    background-color: transparent;
    border: none;
    font-family: 'Lato';
    cursor: pointer;
  }

  @media screen and (max-width: 997px) {
    top: ${px2rem(-130)};
    right: ${px2rem(-50)};
    width: ${px2rem(220)};

    border-radius: 8px;

    a,
    button,
    p {
      font-size: ${px2rem(20)};
    }
  }
`;

const Navigator = styled.div`
  background-color: #ebebeb;
  height: 72px;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999;

  ul {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        border-top: 5px solid var(--default-orange);
        border-width: 5px;
      }

      button {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      a,
      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #4a4c5b;
        font-size: 14px;

        svg {
          font-size: 32px;
          margin: 0 auto;

          &.active {
            color: var(--default-orange);
          }
        }
        span {
          font-size: 18px;
          line-height: 24px;
          &.active {
            color: var(--default-orange);
          }
        }
      }
    }
  }
`;

export { Nav, MobileHeader, NavLogo, NavSearch, ButtonWrapper, NavButton, SubMenu, Navigator };
