import { useState } from 'react';
import HomeSlider from 'components/Home/HomeSlider';
import { useEffect } from 'react';
import { getWatchAgain } from 'services/home';

export default function WatchAgain() {
  const [watchAgain, setWatchAgain] = useState<any>();

  useEffect(() => {
    const getContent = async () => {
      const resultWatchAgain = await getWatchAgain(false);
      setWatchAgain(resultWatchAgain);
    };
    getContent();
  }, []);

  return <HomeSlider title="Assista novamente" data={watchAgain} />;
}
