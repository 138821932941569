import Chat from "components/Transmission/Chat";
import Live from "components/Transmission/Live";

export default function Transmission(){
  return(
    <>
    <Live/>
    <Chat/>
    </>
  )
}