import { useState } from 'react';
import { Items } from './style';
import Carousel from '../../Commons/Carousel';
import MovieCard from '../../Movies/MovieCard';
import HomeSliderSkeleton from './HomeSliderSkeleton';
import { memo } from 'react';
import MovieDetail from 'components/Movies/MovieDetail';

function MovieHome({ title, data }: any) {
  const [currentContent, setCurrentContent] = useState<any>();
  const [trailContent, setTrailContent] = useState<any>(false);

  const handleCurrentContent = (content: any) => {
    setCurrentContent(content?.content_id || content?.trail_id);
    if (!content.is_library) {
      setTrailContent(true);
    }
  };
  if (data?.length <= 0) return null;
  return (
    <Items>
      {!data && <HomeSliderSkeleton />}
      {data && (
        <Carousel title={title}>
          {data.map((content: any, key: number) => {
            const link = content?.content_id
              ? `/kids-movie/${content?.content_id}`
              : `/kids-trail/${content?.trail_id}`;
            return <MovieCard data={content} key={key} to={link} />;
          })}
        </Carousel>
      )}
      <MovieDetail id={currentContent} trail={trailContent} />
    </Items>
  );
}
export default memo(MovieHome);
