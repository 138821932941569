import styled from 'styled-components';

interface IsKids {
  isKids?: boolean;
  light?: boolean;
}

export default styled.div<IsKids>`
 
  background-image:  ${({ isKids, light }) =>
    isKids || light
      ? `linear-gradient(
    -90deg,
    #ebebeb 0%,
    #fafafa 50%,
    #ebebeb 100%
  );`
      : `linear-gradient(
    -90deg,
    #1b2124 0%,
    #14181a 50%,
    #1b1f22 100%
  );`}

  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
