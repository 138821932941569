import { AddToList } from './style';
import IconPlus from '../../../assets/icon-plus.svg';
import IconCheck from '../../../assets/check.svg';
import { addToBookmarks, removeFromBookmark } from '../../../services/bookmarks';
import { useState } from 'react';
import { useEffect } from 'react';

export default function AddToBookmarks({ data, bookmark }: any) {
  const [listCheck, setListCheck] = useState(false);
  const [toList, setToList] = useState<boolean>();

  const handleAddToBookmarks = async (data: any) => {
    if (!toList) {
      if (data?.content_id) {
        const a = await addToBookmarks(data.content_id, data.is_library);
        setToList(true);
        setListCheck(true);
      } else {
        const a = await addToBookmarks(data.trail_id);
        setToList(true);
        setListCheck(true);
      }
    } else {
      if (data?.content_id) {
        const a = await removeFromBookmark(data.content_id, data.is_library);
        setToList(false);
        setListCheck(false);
      } else {
        const a = await removeFromBookmark(data.trail_id);
        setToList(false);
        setListCheck(false);
      }
    }
  };

  useEffect(() => {
    const checkBookmark = () => {
      setListCheck(bookmark);
      setToList(bookmark);
    };
    checkBookmark();
  }, [bookmark]);

  return (
    <AddToList bookmark={listCheck || toList || bookmark} onClick={() => handleAddToBookmarks(data)}>
      {toList || bookmark ? <img src={IconCheck} alt="Check" /> : <img src={IconPlus} alt="Plus" />}
      <p>Minha Lista</p>
    </AddToList>
  );
}
