import { useState } from "react";
import { memo } from "react"
import { LazyLoadImage } from './style';

interface LazyImageProps {
  src?: string;
  alt?: string;
}
function LazyImage({ src, alt }: LazyImageProps) {
  const [loaded, setLoaded] = useState<boolean>(false);
  return (
    <LazyLoadImage className={loaded ? 'loaded' : ''} src={src} alt={alt} onLoad={() => setLoaded(true)} />
  )
}

export default memo(LazyImage);