import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const height = px2rem(105) + px2rem(117);

const Wrapper = styled.div`
  background-color: var(--default-background);
  @media screen and (min-width: 998px) {
    width: ${px2rem(390)};
    height: 100%;
    position: fixed;
    top: 0%;
    right: 0;
  }
`;

const HeaderChat = styled.div`
  padding: ${px2rem(32)} ${px2rem(26)};
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  p {
    color: #fff;
    font-size: ${px2rem(18)};
    margin-right: ${px2rem(20)};
  }

  .switch {
    position: relative;
    display: inline-block;
    width: ${px2rem(60)};
    height: ${px2rem(20)};
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: ${px2rem(34)};
    height: ${px2rem(28)};
  }
  .slider:before {
    position: absolute;
    content: '';
    height: ${px2rem(20)};
    width: ${px2rem(20)};
    left: ${px2rem(4)};
    bottom: ${px2rem(4)};
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: var(--default-orange);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--default-orange);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(${px2rem(26)});
    -ms-transform: translateX(${px2rem(26)});
    transform: translateX(${px2rem(26)});
  }
`;

const DisabledChat = styled.div`
  height: ${`calc(100vh - ${px2rem(105)})`};
  background-color: var(--default-background);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  strong,
  p {
    color: #fff;
    font-size: ${px2rem(18)};
    text-align: center;
  }
  p {
    margin-top: ${px2rem(20)};
  }
`;

const Messages = styled.div`
  height: ${`calc(100vh - ${px2rem(222)})`};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2.5px;
  }
  div {
    padding: ${px2rem(15)} ${px2rem(26)};
    border-bottom: 0.2px solid rgba(255, 255, 255, 0.2);
    strong {
      font-size: ${px2rem(16)};
      color: #fff;
    }
    p {
      font-size: ${px2rem(14)};
      color: rgba(255, 255, 255, 0.8);
      margin-top: 2px;
    }
  }
`;

const SendMessage = styled.div`
  padding: ${px2rem(36)} ${px2rem(26)};
  display: flex;
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  input {
    color: #fff;
    background: transparent;
    border-bottom: 1px solid var(--default-orange) !important;
    font-family: 'Lato';
    outline: none;
    margin: 0;
    border: none;
    outline: none;
    width: 85%;
    &::placeholder {
      color: #fff;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--default-orange);
    border: none;
    width: ${px2rem(44)};
    height: ${px2rem(44)};
    border-radius: 50%;
    margin-left: ${px2rem(15)};
  }
`;

export { Wrapper, HeaderChat, Messages, SendMessage, DisabledChat };
