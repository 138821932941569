import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { addUserLike } from 'services/user-like';

import { Content } from 'services/contents/models/content-interface';

import { KeepWatchingCardContainer } from './style';
import PlayIcon from 'assets/icon-play.svg';
import LikeIcon from 'assets/icons/like-icon-white.svg';

interface IKeepWatchingCard {
  onClose: () => void;
  trailId?: string;
  nextContent: Content;
}

const KeepWatchingCard: React.FC<IKeepWatchingCard> = ({ trailId, nextContent, onClose }) => {
  const history = useHistory();
  const [timer, setTimer] = useState<number>(15);
  const [showContentLiked, setShowContentLiked] = useState<boolean>(false);

  const isSeries = nextContent.trail_id !== undefined;
  const isUserPremium = nextContent.user?.premium !== false;
  const isContentLiked = nextContent.user?.like !== undefined ? nextContent.user?.like : true;
  const thumbnail = nextContent?.thumbnail;
  const title = nextContent?.title;
  const lessonId = nextContent?.content_id || nextContent?.trail_id;

  const actualLocation = window.location.pathname.split('/')[1];

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer - 1);

      if (isSeries && timer === 0) {
        onClose();
      }

      if (isSeries && timer === 0) {
        handleNextContent();
      }
    }, 1000);

    setShowContentLiked(isContentLiked);
    return () => clearInterval(interval);
  }, [isContentLiked, timer]);

  const handleNextContent = () => {
    if (isSeries && actualLocation === 'show') {
      history.push(`/show/${trailId}/season/${nextContent.course_id}/episode/${nextContent.content_id}`);
    } else if (isSeries) {
      history.push(`/trail/${nextContent?.trail_id}`);
      window.location.reload();
    } else {
      history.push(`/player/${nextContent.content_id}`);
      window.location.reload();
    }
  };

  const handleBePremium = () => {
    history.push(`${nextContent.trail_id ? '/trail/' : '/movie/'}${nextContent?.trail_id || nextContent?.content_id}`);
  };

  const handleLikeContentUser = async () => {
    if (isSeries) {
      const data = {
        trail_id: trailId,
        content_id: lessonId,
      };

      await addUserLike(data).then(() => {
        setShowContentLiked(false);
      });
    } else {
      const data = {
        trail_id: lessonId,
      };

      await addUserLike(data).then(() => {
        setShowContentLiked(false);
      });
    }
  };

  return (
    <KeepWatchingCardContainer
      style={{
        backgroundImage: thumbnail ? `url(${thumbnail})` : 'none',
        boxShadow: thumbnail ? 'inset 0 0 0 10000px rgba(0, 0, 0, 0.6)' : 'none',
      }}
    >
      {showContentLiked ? (
        <div className="liked-content" onClick={handleLikeContentUser}>
          <img src={LikeIcon} alt="liked" />

          <span>Curtir esse conteúdo</span>
        </div>
      ) : null}

      <h3>
        {isSeries && isUserPremium && actualLocation === 'show' ? (
          <>
            Próximo episódio em <span>{timer}</span>
          </>
        ) : (
          'Assista a um conteúdo similar'
        )}
      </h3>

      <h1>{title}</h1>

      {isUserPremium || !nextContent?.info?.premium ? (
        <button type="button" id="play" onClick={handleNextContent}>
          <img src={PlayIcon} alt="play" />
          Assistir
        </button>
      ) : (
        <button type="button" id="play" onClick={handleBePremium}>
          Assine Agora
        </button>
      )}

      <button type="button" id="close" onClick={onClose}>
        X
      </button>
    </KeepWatchingCardContainer>
  );
};

export default KeepWatchingCard;
