import styled, { css } from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface LikeProps {
  liked?: boolean;
}
interface BookMarkProps {
  bookmark?: boolean;
}

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  width: 85%;
  margin: 0 auto;
  padding: 0;
  a img {
    width: ${px2rem(50)};
  }

  @media screen and (min-width: 998px) {
    a img {
      width: ${px2rem(100)};
    }
  }
`;

const PlaySignBtnWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  padding-top: 100px;
`;

const SignPlanBtn = styled.button`
  background-color: var(--default-orange);
  height: 50px;
  text-transform: uppercase;
  color: white;
  outline: none;
  border: none;
  border-radius: ${px2rem(8)};
  cursor: pointer;
  font-family: 'Lato';
  font-weight: bold;
  font-size: 16px;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }

  p {
    padding: ${px2rem(15)} ${px2rem(15)};
  }
`;

const MovieInfoWrapper = styled.div`
  padding-top: ${px2rem(10)};
  width: 50%;
`;

const MovieTitle = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(48)};
    margin-bottom: ${px2rem(10)};
  }
`;

const MovieResume = styled.span`
  color: #fff;
  font-size: 12px;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }
`;

const MovieDescription = styled.p`
  color: #fff;
  max-width: 490px;
  line-height: ${px2rem(24)};
  margin: ${px2rem(30)} 0;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }
`;

const WrapperUserButtons = styled.div`
  display: flex;
  align-items: center;
`;

const MyListButton = styled.button<BookMarkProps>`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid;
  font-size: ${px2rem(18)};
  text-transform: uppercase;
  font-weight: 700;
  height: ${px2rem(56)};
  padding: 0 ${px2rem(20)};
  border-radius: ${px2rem(8)};
  cursor: pointer;
  img {
    margin-right: ${px2rem(15)};
  }

  ${(props) =>
    props.bookmark
      ? css`
          border-color: var(--default-orange);
          color: var(--default-orange);
          img {
            width: ${px2rem(20)};
          }
        `
      : css`
          border-color: #fff;
          color: #fff;
        `}
`;

export {
  Wrapper,
  MovieInfoWrapper,
  MovieTitle,
  MovieResume,
  MovieDescription,
  WrapperUserButtons,
  MyListButton,
  PlaySignBtnWrapper,
  SignPlanBtn,
};
