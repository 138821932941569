import React, { useState } from 'react';
import { Wrapper, FlowTitle, Form, Grid, RightContent, LeftContent } from '../style';
import IconArrowBack from '../../../assets/icon-arrow-back.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CodeVerificationInput from './CodeVerificationInput';
import { verifyCodeOfUserEmail } from '../../../services/cognito';

interface IConfirmCode {
  email: string;
}

export default function ConfirmCode() {
  const location = useLocation();
  const history = useHistory();
  const [confirmCode, setConfirmCode] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (confirmCode && confirmCode.length === 6) {
      const state = location.state as IConfirmCode;

      await verifyCodeOfUserEmail(state.email, confirmCode).then((response) => {
        localStorage.setItem('payment-token-teo', response.api_token);
        if (response.first_login) {
          history.push('/home');
        } else {
          history.push('/register/choose-plan');
        }
      });
    }

    return;
  };

  return (
    <Wrapper>
      <Grid>
        <RightContent>
          <Link to="/register" className="back">
            <img src={IconArrowBack} alt="" />
          </Link>
          <FlowTitle>
            Valide <br />
            seu e-mail.
          </FlowTitle>
          <p>Passo 2 de 4</p>
        </RightContent>
        <LeftContent>
          <hr />
          <Form onSubmit={(e) => handleSubmit(e)}>
            <CodeVerificationInput onChange={(e) => setConfirmCode(e)} values={confirmCode} />
            <div className="buttons">
              <Link to="/">Cancelar</Link>
              <button type="submit" className="submit">
                Continuar
              </button>
            </div>
          </Form>
        </LeftContent>
      </Grid>
    </Wrapper>
  );
}
