import styled, { keyframes } from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button`
  background-color: var(--default-orange);
  border: none;
  margin: 0;
  height: ${px2rem(40)};
  border-radius: ${px2rem(8)};
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Lato';
  padding: 0 ${px2rem(20)};
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;

  svg {
    margin-left: ${px2rem(8)};
    animation: ${rotate} linear 0.9s infinite;
    font-size: ${px2rem(16)};
  }
`;
