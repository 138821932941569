import { useEffect, useState } from 'react';

import { getHighlightedKids, getHomeCategoriesKids, getMostWatched, getWatchAgain, getWatching } from 'services/home';
import { getContentTag, getTrailTag } from 'services/tags';
import { IHighlightedContent, IContent, Watching, ITrail } from 'services/home/models/home-interfaces';

import HeaderKids from '../../components/Kids/HeaderKids';
import Footer from '../../components/Commons/Footer';
import KidsHighlights from 'components/Kids/KidsHighlights';
import KidsSlider from '../../components/Home/KidsSlider';
import httpClient from 'http';
import HomeCategoriesKids from './HomeCategoriesKids';
import { Spacer } from 'components/Commons/MobileNavigatorSpace/style';
import { Container } from './styles';
import GridSkeleton from 'components/Commons/GridSkeleton';
import { Mobile } from 'utils/responsiveRules';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';
import useTotalViewd from 'hooks/useTotalViewd';


type IMostWatched = (IContent & ITrail)[];

export default function HomeKids() {
  const [highlightedContentKids, setHighlightedContentKids] = useState<IHighlightedContent | null>(null);
  const [watching, setWatching] = useState<Watching | null>(null);
  const [watchAgain, setWatchAgain] = useState<IContent[]>([] as IContent[]);
  const [mostWatched, setMostWatched] = useState<IMostWatched>([]);
  const [contentsSeries, setContentsSeries] = useState<IContent[]>();
  const [contentsMovies, setContentsMovies] = useState<IContent[]>();

  const { clearTimeAtHome } = useTotalViewd();

  const getAllContent = async () => {
    const resultWatchingContents = await getWatching(true);
    const resultWatchAgain = await getWatchAgain(true);
    const resultContentMovies = await getContentTag('filmes');
    const resultContentsSeries = await getTrailTag('series');
    const resultMostWatched = await getMostWatched(true);
    const resultHighlightedContent = await getHighlightedKids();

    setWatchAgain(resultWatchAgain);
    setContentsMovies(resultContentMovies);
    setContentsSeries(resultContentsSeries);
    setWatching(resultWatchingContents);
    setMostWatched([...resultMostWatched.contents, ...resultMostWatched.trails] as IMostWatched);
    setHighlightedContentKids(resultHighlightedContent);
  };

  useEffect(() => {
    getAllContent();
    clearTimeAtHome();
  }, []);

  return (
    <Container>
      <HeaderKids home />
      <section>
        {highlightedContentKids && <KidsHighlights data={highlightedContentKids} />}
        <KidsSlider title="Mais assistidos" data={mostWatched} />
        <KidsSlider title="Continue assistindo" data={watching} />

        {watchAgain?.length > 0 ? <KidsSlider title="Assista de novamente" data={watchAgain} /> : null}

        <KidsSlider title="Filmes" data={contentsMovies} />

        <KidsSlider title="Séries" data={contentsSeries} />

        <HomeCategoriesKids />
      </section>
      <Footer />
      <Mobile>
        <MobileNavigatorSpace />
      </Mobile>
    </Container>
  );
}
