import httpClient from '../../http';

const addUserLike = async (body: object) => {
  const addLike = (await httpClient.post('/user-like', body)).data;

  return addLike;
};
const removeUserLike = async (body: object) => {
  const removeLike = (await httpClient.delete('/user-like', body)).data;

  return removeLike;
}
const createOrRemoveUserLike = async (body: object) => {
  const updateLike = (await httpClient.patch('/user-like', body)).data;

  return updateLike;
}

export { addUserLike, removeUserLike, createOrRemoveUserLike };
