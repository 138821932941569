import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';

const ListContents = styled.div`
  position: relative;
  background-color: #1a1e21;
  padding: ${px2rem(90)} 0 ${px2rem(60)};
  min-height: calc(100vh - 135px);
  padding: 100px 0 60px;

  @media screen and (min-width: 998px) {
    padding: ${px2rem(200)} 0 ${px2rem(300)};
  }

  @media screen and (max-width: 480px) {
    padding: 66px 0 24px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 24px;

  > div,
  a {
    width: 100%;
    min-height: 200px;
    margin-bottom: 40px;
  }

  @media screen and (min-width: 998px) {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${px2rem(25)};
    row-gap: ${px2rem(50)};
    padding: 0;
    > div {
      width: 100%;
    }
  }
`;

const TitleList = styled.h1`
  font-size: ${px2rem(24)};
  color: #fff;
  margin: 0 auto;
  width: 80vw;
  margin-bottom: ${px2rem(30)};

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(40)};
  }

  @media screen and (max-width: 480px) {
    margin: 0 0 0 24px;
    line-height: 72px;
  }
`;

const WithoutContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: ${px2rem(100)};
  padding: 0 20px;
  > div {
    background-color: rgba(255, 255, 255, 0.15);
    width: ${px2rem(180)};
    height: ${px2rem(180)};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-top: ${px2rem(30)};
  }

  @media screen and (min-width: 998px) {
    p {
      width: ${px2rem(450)};
      font-size: ${px2rem(18)};
    }
  }
`;

export { ListContents, Grid, TitleList, WithoutContent };
