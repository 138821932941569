import { useEffect, useState } from 'react';
import { WrapperMovieDetail, ButtonClose } from './style';
import MovieInfo from '../MovieInfo';
import MovieDatasheet from '../MovieDatasheet';
import RelatedMovie from '../RelatedMovies';
import Tab from '../../Commons/Tab';
import Close from '../../../assets/icon-close.svg';
import { useParams } from 'react-router';
import { getContent } from '../../../services/contents';
import { getTrails } from '../../../services/trail';
import ContentSeasons from '../ContentSeasons';
import { Desktop, Mobile } from 'utils/responsiveRules';

interface Props {
  id?: string;
  trail?: boolean;
}

export default function MovieDetail({ id, trail = false }: Props) {
  const [showDetail, setShowDetail] = useState(false);
  const [content, setContent] = useState<any>();
  const slug = useParams();

  const showDetailMovie = () => {
    setShowDetail(true);
  };
  const getContentData = async (id: string, trail: boolean) => {
    if (trail) {
      const content = await getTrails(id);
      setContent(content);
    } else {
      const content = await getContent(id);
      setContent(content);
    }
  };
  const dismissDetail = () => {
    setContent(undefined);
    setShowDetail(false);
  };

  useEffect(() => {
    if (id) {
      getContentData(id, trail);
      showDetailMovie();
    }
  }, [id, trail]);

  if (!showDetail && !content) return null;
  return (
    <>
      <Mobile>
        <WrapperMovieDetail background={content?.thumbnail_mobile ? content?.thumbnail_mobile : content?.thumbnail}>
          <ButtonClose onClick={dismissDetail}>
            <img src={Close} alt="" />
          </ButtonClose>
          {content && content?.courses && (
            <Tab
              tabs={[
                { title: 'Sobre o filme', component: () => <MovieInfo data={content} /> },
                { title: 'Ficha técnica', component: () => <MovieDatasheet data={content} /> },
                { title: 'Mais episódios', component: () => <ContentSeasons data={content} /> },
                { title: 'Títulos semelhantes', component: () => <RelatedMovie data={content} /> },
              ]}
            />
          )}
          {content && content?.is_library && (
            <Tab
              tabs={[
                { title: 'Sobre o filme', component: () => <MovieInfo data={content} /> },
                { title: 'Ficha técnica', component: () => <MovieDatasheet data={content} /> },
                { title: 'Títulos semelhantes', component: () => <RelatedMovie data={content} /> },
              ]}
            />
          )}
        </WrapperMovieDetail>
      </Mobile>
      <Desktop>
        <WrapperMovieDetail background={content?.info?.banner_url ? content?.info.banner_url : content?.thumbnail}>
          <ButtonClose onClick={dismissDetail}>
            <img src={Close} alt="" />
          </ButtonClose>
          {content && content?.courses && (
            <Tab
              tabs={[
                { title: 'Sobre o filme', component: () => <MovieInfo data={content} /> },
                { title: 'Ficha técnica', component: () => <MovieDatasheet data={content} /> },
                { title: 'Mais episódios', component: () => <ContentSeasons data={content} /> },
                { title: 'Títulos semelhantes', component: () => <RelatedMovie data={content} /> },
              ]}
            />
          )}
          {content && content?.is_library && (
            <Tab
              tabs={[
                { title: 'Sobre o filme', component: () => <MovieInfo data={content} /> },
                { title: 'Ficha técnica', component: () => <MovieDatasheet data={content} /> },
                { title: 'Títulos semelhantes', component: () => <RelatedMovie data={content} /> },
              ]}
            />
          )}
        </WrapperMovieDetail>
      </Desktop>
    </>
  );
}
