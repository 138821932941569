import logo from 'assets/logo.svg';
import { useHistory } from 'react-router-dom';
import { Header } from './style';

export default function TermHeader() {
  const history = useHistory();

  return (
    <Header>
      <img src={logo} alt="TEOMídia" onClick={() => history.push('/')} />
    </Header>
  );
}
