import Footer from '../components/Commons/Footer';
import Apps from '../components/Landing/Apps';
import Header from '../components/Commons/Header';
import Plans from '../components/Landing/Plans';
import PresentationBanner from '../components/Landing/PresentationBanner';
import Rising from '../components/Landing/Rising';

function Landing() {
  return (
    <>
      <Header />
      <PresentationBanner />
      <Plans />
      <Apps />
      <Footer />
    </>
  );
}

export default Landing;
