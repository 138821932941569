import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import MovieCard from '../../components/Movies/MovieCard';
import { ListContents, Grid, TitleList, WithoutContent, CardWrapper } from './style';
import EmptyList from '../../assets/icon-empty.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import GridSkeleton from '../../components/Commons/GridSkeleton';
import { getContentTag, getTrailTag } from '../../services/tags';
import { Card } from 'components/Movies/MovieCard/style';
import { Mobile } from 'utils/responsiveRules';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';

const NoContent = () => (
  <WithoutContent>
    <div>
      <img src={EmptyList} alt="" />
    </div>
    <p>Nenhum conteúdo a esta categoria no momento. Navegue pelo catálogo para encontrar mais conteúdos.</p>
  </WithoutContent>
);

export default function Sermon() {
  const [content, setContent] = useState<any>();
  const getData = async () => {
    const content = await getContentTag('sermoes');
    const trail = await getTrailTag('sermoes');
    setContent(content.concat(trail));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <NavBar />
      <ListContents>
        <TitleList>Sermões</TitleList>
        {!content && <GridSkeleton />}
        {content?.length <= 0 && <NoContent />}
        <Grid>
          {content &&
            content.map((sermon: any, key: any) => {
              if (sermon.is_library) {
                return (
                  <CardWrapper>
                    <MovieCard to={`/movie/${sermon.content_id}`} data={sermon} key={`sermon-${key}`} />
                  </CardWrapper>
                );
              } else {
                return (
                  <CardWrapper>
                    <MovieCard to={`/trail/${sermon.trail_id}`} data={sermon} key={`sermon-${key}`} />
                  </CardWrapper>
                );
              }
            })}
        </Grid>
      </ListContents>
      <Footer />
      <Mobile>
        <MobileNavigatorSpace />
      </Mobile>
    </>
  );
}
