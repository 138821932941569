import { BrowserRouter as Router, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import Landing from './pages/Landing';
import Login from './pages/Login';
import RecoverPassword from './pages/RecoverPassword';
import NewPassword from './pages/NewPassword';
import Register from './pages/Register';
import Checkout from './pages/Checkout';
import ChoosePlan from './pages/ChoosePlan';
import PlayerContent from './pages/PlayerSingleContent';
import PlayerSeries from './pages/PlayerSeries';
import Terms from 'pages/Terms';
import Politics from 'pages/Politcs';
import ScrollToTop from 'components/ScrollToTop';
import KidsRoutes from 'routes/KidsRoutes';
import DefaultRoutes from 'routes/DefaultRoutes';
import ConfirmSignUp from 'pages/ConfirmSignUp';
import { useCallback, useState } from 'react';
import { ContextRoute } from 'utils/context';
import { useEffect } from 'react';
import Search from 'pages/Search';
import Transmission from 'pages/Transmission';
import Catalog from 'pages/Catalog';
import Profile from 'pages/Profile';
import KidsSearch from 'pages/KidsSearch';
import Video from 'pages/Video';
import { sendEventRdStation } from 'services/rd';
import { PremiumModalProvider } from 'providers/premiumModalProvider';
import cleanStorage from 'utils/cleanLocalStorage';
import { sendRdStation } from 'utils/rdStationFunctions';
import AuthProvider from 'contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const routerLocation = useLocation();

  const isLogged = () => {
    const api_token = localStorage.getItem('api_token');
    if (api_token) return true;
    return false;
  };

  useEffect(() => {
    sendRdStation(window.location.pathname);
  }, [routerLocation]);
  if (isLogged() && window.location.href == '/') return <Redirect to="/home" />;

  return <Route {...rest} render={(props) => (isLogged() ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

function App() {
  const [perfilType, setPerfilType] = useState('padrao');
  const isKids = localStorage.getItem('kids');
  const isLogged = localStorage.getItem('api_token');
  const location = window.location.pathname;
  const handleIsKidTrue = useCallback(async () => {
    setPerfilType('kids');
  }, []);
  const handleIsKidFalse = useCallback(() => {
    setPerfilType('padrao');
  }, []);

  useEffect(() => {
    if (isKids && location === '/home') {
      window.location.href = '/login';
      cleanStorage();
    }
    if (isKids) handleIsKidTrue();
    else if (!isKids && location === '/kids') {
      handleIsKidFalse();
      window.location.href = '/home';
    } else {
      handleIsKidFalse();
    }
  }, []);

  const AllKidsRoutes = KidsRoutes.map((props) => <PrivateRoute {...props} key={props.path} />);
  const AllDefaultRoutes = DefaultRoutes.map((props) => <PrivateRoute {...props} key={props.path} />);
  return (
    <Router>
      <AuthProvider>
        <ScrollToTop />
        <PremiumModalProvider>
          <ContextRoute.Provider
            value={{
              perfilType,
              handleIsKidTrue,
              handleIsKidFalse,
            }}
          >
            <Switch>
              <PrivateRoute path="/video/:id" component={Video} exact />
              <PrivateRoute path="/catalog" component={Catalog} />
              <PrivateRoute path="/transmission/:id" component={Transmission} exact />
              <PrivateRoute path="/search" component={Search} exact />
              <PrivateRoute path="/kids-search" component={KidsSearch} exact />
              <PrivateRoute path="/player/:id" component={PlayerContent} exact />
              <PrivateRoute path="/show/:trailId/season/:courseId/episode/:lessonId" component={PlayerSeries} exact />
              <Route path="/login" component={Login} />
              <Route path="/politica-de-privacidade" component={Politics} />
              <Route path="/termos-de-uso" component={Terms} />
              <Route path="/register/confirm" component={ConfirmSignUp} />
              <Route path="/register/checkout" component={Checkout} />
              <Route path="/register/choose-plan" component={ChoosePlan} />
              <Route path="/register" component={Register} />
              <Route path="/recover/new-password" component={NewPassword} />
              <Route path="/recover" component={RecoverPassword} />
              <Route path="/" render={({ location }) =>
                !isLogged ? (
                  <Landing/>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/home",
                      state: { from: location }
                    }}
                  />
                )
              } exact />
              {perfilType === 'kids' ? AllKidsRoutes : AllDefaultRoutes}
            </Switch>
          </ContextRoute.Provider>
        </PremiumModalProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
