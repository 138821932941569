import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface IisKids {
  isKids?: boolean;
}

const Card = styled.div`
  width: 100%;
  cursor: pointer;
  overflow: hidden;

  @media screen and (min-width: 998px) {
    position: relative;
    transition: transform 0.4s ease-in-out;

    .hover {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.22);
      opacity: 0;
      border-radius: ${px2rem(4)};
      transform: scale(1.04);
      transition: opacity 0.3s ease-in-out;
      overflow: hidden;
    }
    &:hover {
      transform: scale(1.04);
      .hover {
        opacity: 0.8;
      }
    }
  }
`;

const CardLink = styled(Link)`
  position: relative;
  display: block;
`;

const CardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: ${px2rem(4)};
  transition: transform 0.4s ease-in-out;

  img {
    max-width: 110%;
  }
  @media screen and (min-width: 998px) {
    img {
      width: 100%;
      border-radius: 4px;
    }
    &:hover {
      transform: scale(1.03);
    }
  }
`;

const MovieInfos = styled.div`
  position: relative;
  overflow: hidden;
`;

const MovieTitle = styled.h1<IisKids>`
  height: ${px2rem(50)};
  color: ${({ isKids }) => (isKids ? '#000' : '#fff')};
  font-size: ${px2rem(20)};
  margin: ${px2rem(10)} 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const MovieDuration = styled.strong<IisKids>`
  color: ${({ isKids }) => (isKids ? '#000' : '#fff')};
  font-size: ${px2rem(15)};
  font-weight: 500;
  width: 100px;
`;

const MovieDescription = styled.p<IisKids>`
  color: ${({ isKids }) => (isKids ? '#000' : '#fff')};
  font-size: ${px2rem(15)};
  font-weight: 400;
  margin-top: ${px2rem(15)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const MovieSeeMore = styled(Link)`
  font-size: ${px2rem(15)};
  font-weight: 400;
  text-decoration: underline;
  
  color: var(--default-orange);
  
  display: flex;
  margin: 5px 0;
`;

const RemoveFromList = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  position: absolute;
  top: 15%;
  right: 0;
  z-index: 1;
  cursor: pointer;
  padding: ${px2rem(20)};
  transform: translate(-0%, -50%);

  img {
    transform: rotate(45deg);
    filter: drop-shadow(1px 1px 3px rgb(0 0 0 / 0.8));
  }
`;

const Progress = styled.div`
  width: ${px2rem(210)};
  height: 5px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  background-color: rgba(255, 255, 255, 0.2);
`;
const ProgressIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--default-orange);
  width: 50%;
  border: 5px;
`;
export {
  Card,
  CardLink,
  CardImage,
  MovieInfos,
  MovieTitle,
  MovieDuration,
  MovieDescription,
  MovieSeeMore,
  Progress,
  ProgressIndicator,
  RemoveFromList,
};
