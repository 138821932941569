import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

export const PlayerContentContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;

  #player {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;

interface ContainerButtonProps {
  isHideButton: boolean;
}

export const ContainerButton = styled.div<ContainerButtonProps>`
  max-width: 400px;
  width: 100%;
  height: 200px;
  position: absolute;
  opacity: ${(props) => (props.isHideButton ? 0 : 1)};

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${px2rem(768)}) {
    margin-top: ${px2rem(20)};
    width: 300px;
    opacity: 1;
  }
`;

export const ReturnToDetailPage = styled.div`
  position: absolute;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 23px;
  top: 40px;
  left: 33px;
  border-radius: 8px;
  padding: 2.5px 9px;
  cursor: pointer;

  span {
    font-size: ${px2rem(20)};
  }

  img {
    width: 25px;
    height: 25px;
  }
`;
