import { useContext, useMemo, useState } from 'react';
import RelatedCarousel from '../../Commons/RelatedCarousel';
import MovieCard from '../MovieCard';
import { SeasonWrapper, SeasonSelector } from './style';

import { ContextBePremiumModal } from 'utils/context';

export default function ContentSeasons({ data }: any) {
  const { setOpenModalPremium } = useContext(ContextBePremiumModal);

  const [currentSeason, setCurrentSeason] = useState<any>(0);
  
  const userCanAccessContent = useMemo(() => {
    if(!data?.info?.premium || data?.user?.premium) {
      return true
    }
    
    return false;
  }, [data]);
  
  if (!data) return null;
  return (
    <SeasonWrapper>
      <SeasonSelector onChange={(e) => setCurrentSeason(e.target.value)} value={currentSeason}>
        {data?.courses
          ?.sort((a: any, b: any) => {
            if (a.position < b.position) {
              return -1;
            }
            if (a.position > b.position) {
              return 1;
            }
            return 0;
          })
          ?.map((season: any, key: any) => (
            <option value={key}>{season.title}</option>
          ))}
      </SeasonSelector>
      {data && (
        <RelatedCarousel title="Temporadas e episódios">
          {data &&
            data?.courses[currentSeason]?.contents
              ?.sort((a: { position: number }, b: { position: number }) => {
                if (a.position < b.position) {
                  return -1;
                }
                if (a.position > b.position) {
                  return 1;
                }
                return 0;
              })
              ?.map((content: any) => {
                const cardProps = userCanAccessContent 
                  ? { to: `/show/${data?.trail_id}/season/${data?.courses[currentSeason]?.course_id}/episode/${content.content_id}` }
                  : { onClick: () => setOpenModalPremium(true) };

                return  (
                  <MovieCard
                    width={305}
                    style={{ margin: '0 auto' }}
                    info={true}
                    tail
                    data={content}
                    key={`movie-${content.content_id}`}
                    trailThumbnail={data?.trail_id}
                    {...cardProps}
                  />
                );
              })}
        </RelatedCarousel>
      )}
    </SeasonWrapper>
  );
}
