import { useState } from 'react';
import { Wrapper, FlowTitle, Form, Grid, RightContent, LeftContent } from '../style';
import IconEye from 'assets/eye.svg';
import IconArrowBack from 'assets/icon-arrow-back.svg';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { confirmNewPassword } from 'services/cognito';
import { RecoverSchema } from './newPasswordValidation';
import CodeVerificationInput from '../ConfirmCode/CodeVerificationInput';
import { toast } from 'react-toastify';

interface INewPassword {
  email: string;
}

export default function NewPasswordBlock() {
  const history = useHistory();
  const location = useLocation();
  const [passwordType, setPasswordType] = useState({
    pass: true,
    confirm: true,
  });
  const [confirmCode, setConfirmCode] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validationSchema: RecoverSchema,
    validateOnMount: true,
    onSubmit: async ({ password }) => {
      if (confirmCode && confirmCode.length === 6) {
        const { email } = location.state as INewPassword;

        await confirmNewPassword(email, password, confirmCode)
          .then(() => {
            history.push('/login');
          })
          .catch((error) => {
            switch (error.response.data.message.message) {
              case 'Invalid verification code provided, please try again.':
                toast.error('Código de verificação inválido.');
                break;

              case 'Attempt limit exceeded, please try after some time.':
                toast.error('Limite de tentativas excedido.');
                break;

              default:
                toast.error('Erro ao criar uma nova senha.');
                break;
            }
          });
      }
    },
  });

  const handleShowPassword = (event: React.MouseEvent, index: number) => {
    event.preventDefault();

    if (index === 1) {
      setPasswordType({ ...passwordType, pass: !passwordType.pass });
    } else {
      setPasswordType({ ...passwordType, confirm: !passwordType.confirm });
    }
  };

  return (
    <Wrapper>
      <Grid>
        <RightContent>
          <Link to="/" className="back backArrow">
            <img src={IconArrowBack} alt="" />
          </Link>
          <FlowTitle className='title'>
            Crie uma
            <br /> nova senha.
          </FlowTitle>
        </RightContent>
        <LeftContent>
          <hr />
          <Form onSubmit={formik.handleSubmit}>
            <div className="default-input">
              <input
                name="password"
                type={passwordType.pass ? 'password' : 'text'}
                placeholder="Nova senha"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.errors.password && formik.touched.password ? 'error' : ''}
              />
              <label>Nova senha</label>
              <button onClick={(e) => handleShowPassword(e, 1)}>
                <img src={IconEye} alt="" />
              </button>

              {formik.touched.password && formik.errors.password && (
                <span className="error">{formik.errors.password}</span>
              )}
            </div>

            <div className="default-input" style={{ marginTop: '50px' }}>
              <input
                name="repeatPassword"
                type={passwordType.confirm ? 'password' : 'text'}
                placeholder="Confirmar senha"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.errors.password && formik.touched.password ? 'error' : ''}
              />
              <label>Confirmar senha</label>
              <button onClick={(e) => handleShowPassword(e, 2)}>
                <img src={IconEye} alt="" />
              </button>

              {formik.touched.repeatPassword && formik.errors.repeatPassword && (
                <span className="error">{formik.errors.repeatPassword}</span>
              )}
            </div>

            <div className="default-input" style={{ marginBottom: '50px' }}>
              <label>Código de Verificação</label>
            </div>

            <CodeVerificationInput onChange={(e) => setConfirmCode(e)} values={confirmCode} />

            <div className="buttons">
              <Link to="/">Cancelar</Link>
              <button type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                Salvar
              </button>
            </div>
          </Form>
        </LeftContent>
      </Grid>
    </Wrapper>
  );
}
