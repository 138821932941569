import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IEpisodeImage {
  image: string;
}

interface ILight {
  light?: boolean;
}

export const Container = styled(Link)`
  display: flex;
  max-width: 100%;
  margin-bottom: 20px;
  color: ${({ light }: ILight) => (light ? '#000' : '#fff')};
  padding: 0 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const EpisodeImage = styled.div`
  position: relative;
  background-image: url(${(props: IEpisodeImage) => props.image});
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 56px;
  height: 90px;
`;

export const PlayIconContainer = styled.div`
  &,
  div {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  div {
    background-color: #14141499;
    height: 32px;
    width: 32px;
    border-radius: 16px;
  }

  img {
    height: 14px;
    width: 11px;
  }
`;

export const DescriptionContainer = styled.div`
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 14px 10px;

  h3 {
    font-size: 1rem;
    max-width: 20ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p {
    font-size: 0.86rem;
  }
`;

export const Description = styled.p`
  font-size: 0.86rem;
  padding: 0 14px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const SeeMore = styled(Link)`
  padding: 0 14px;
  font-size: 0.86rem;
  text-decoration: underline;

  color: var(--default-orange);
`
