import httpClient from '../../http';
import PaymentCancel from './models/payment-cancel-interface';

const checkoutPayment = async () => {
  const paymentResponse = (
    await httpClient.post(
      `${process.env.REACT_APP_PUBLIC_API_URL}/payment/checkout`,
    )
  ).data;

  return paymentResponse;
};

const cancelPayment = async () => {
  const data: PaymentCancel = (await httpClient.post('/payment/payment-cancel')).data;
  return data;
};

const updatePayment = async (body: string, token: string) => {
  const data = (
    await httpClient.post(
      '/payment/payment-update',
      {
        payment: body,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )
  ).data;
  return data;
};

export { checkoutPayment, cancelPayment, updatePayment };
