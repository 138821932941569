import Footer from '../components/Commons/Footer';
import Header from '../components/Commons/Header';
import MobileFooter from '../components/LoginFlow/Mobile Footer';
import RegisterBlock from '../components/LoginFlow/Register';
import { Desktop, Mobile } from '../utils/responsiveRules';

export default function Register() {
  return (
    <>
      <Header hasLogin={false} />
      <RegisterBlock />
      <Desktop>
        <Footer />
      </Desktop>
      <Mobile>
        <MobileFooter question='Já é cadastrado?' path='/login' title='Acesse sua conta' />
      </Mobile>
    </>
  )
}