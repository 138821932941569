import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const LikeButton = styled.button`
  background-color: transparent;
  border: 1.5px solid #fff;
  width: ${px2rem(56)};
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  margin-left: ${px2rem(25)};
  img {
    width: ${px2rem(20)};
  }
`;
export { LikeButton };
