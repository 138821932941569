import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getCurrentLives } from 'services/lives';
import { LivesProps } from 'services/lives/models/liveModels';
import { CurrentLives, Grid, NoContent, LiveCardWrapper } from './style';

const WithoutContent = () => (
  <NoContent>
    <p>Não há nenhuma transmissão ao vivo no momento.</p>
  </NoContent>
);

export default function CurrentLive() {
  const [allLives, setAllLives] = useState<LivesProps[]>([]);

  const getInLive = async () => {
    const lives = await getCurrentLives();
    if (lives) {
      setAllLives(lives);
    } else {
      setAllLives([]);
    }
  };

  const liveBroadcasts = useMemo(() => {
    return allLives?.filter(
      (live) => (live.status === 'ACTIVE') &&
        live.start &&
        new Date(live.start)?.getTime() < Date.now() &&
        live.stop &&
        new Date(live.stop)?.getTime() > Date.now()
    );
  }, [allLives]);

  useEffect(() => {
    getInLive();
  }, []);

  return (
    <CurrentLives>
      <div className="header">
        <h1>Transmissões ao vivo</h1>
        <p>Acompanhe conteúdos ao vivo.</p>
      </div>
      {liveBroadcasts?.length > 0 && (
        <Grid>
          <h1>Ao vivo agora </h1>
          {liveBroadcasts?.map((live, key) => (
            <LiveCardWrapper key={key}>
              <Link to={`/transmission/${live.live_id}`}>
                <img src={live?.images?.thumbnail_Url} alt={live.name} />
                <p>{live.name}</p>
                <p>{moment(live?.start).format('ddd, DD MMMM')}</p>
                <p>{moment(live?.start).format('LT')}</p>
              </Link>
            </LiveCardWrapper>
          ))}
        </Grid>
      )}
      {liveBroadcasts?.length <= 0 && <WithoutContent />}
    </CurrentLives>
  );
}
