import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { Container, ErrorWrapper } from './style';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  errormessage?: string;
  label: string;
}

const Input: React.FC<IProps> = ({ error = false, errormessage = "", type = "teste", label, ...rest }: IProps) => {

  const [showPassword, setShowPassord] = useState(false);

  return <Container >
    <input
      name="name"
      className={error ? 'error' : ''}
      placeholder="Nome"
      type={showPassword ? 'text' : type}
      {...rest}
    />
    <button onClick={() => setShowPassord(!showPassword)} type="button" >
      {!showPassword ? <FiEye /> : <FiEyeOff />}
    </button>
    <label>{label}</label>
    {error ? <ErrorWrapper>{errormessage}</ErrorWrapper> : null}
  </Container>;
}

export default Input;