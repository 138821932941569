import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const BiographyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${px2rem(248)};
  height: 100%;
  padding-top: 50px;
  button {
    background: transparent;
    cursor: pointer;
    padding: 20px;
    border: none;
    display: flex;
    align-items: flex-start;
    img {
      width: 25px;
    }
  }
  @media screen and (min-width: 998px) {
    margin: ${px2rem(235)} auto ${px2rem(160)};
    width: 80vw;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0;
    button {
      img {
        width: ${px2rem(40)};
      }
    }
  }
`;

const Biography = styled.div`
  padding: 0 24px;
  @media screen and (min-width: 998px) {
    width: 50%;
    min-height: ${px2rem(380)};
    padding: 0;
  }
`;

const BiographyTitle = styled.h1`
  color: #fff;
  margin-bottom: ${px2rem(20)};
  font-size: ${px2rem(24)};
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(64)};
  }
`;

const BiographyParagraph = styled.p`
  font-size: ${px2rem(14)};
  color: #fff;
  margin-bottom: ${px2rem(15)};
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
    line-height: ${px2rem(24)};
  }
`;

export { BiographyWrapper, Biography, BiographyTitle, BiographyParagraph };
