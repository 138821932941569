import { useState } from 'react';
import { Wrapper, FlowTitle, Form, Grid, RightContent, LeftContent, ErrorWrapper, WithFooterWrapper } from '../style';
import IconEye from 'assets/eye.svg';
import IconArrowBack from 'assets/icon-arrow-back.svg';
import { Link, useHistory } from 'react-router-dom';
import { RegisterSchema } from './registerSchema';
import { useFormik } from 'formik';
import { registerUser } from 'services/cognito';
import UserRegister from 'services/cognito/models/user-register-interface';
import { toast } from 'react-toastify';

export default function RegisterBlock() {
  const history = useHistory();
  const [passwordType, setPasswordType] = useState({
    pass: true,
    confirm: true,
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async ({ name, email, password }: UserRegister) => {
      const userData = {
        name,
        email,
        password,
      };

      await registerUser(userData)
        .then(() => {
          history.push('/register/confirm', {
            email,
          });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });

  const handleShowPassword = (event: React.MouseEvent, index: number) => {
    event.preventDefault();

    if (index === 1) {
      setPasswordType({ ...passwordType, pass: !passwordType.pass });
    } else {
      setPasswordType({ ...passwordType, confirm: !passwordType.confirm });
    }
  };

  return (
    <WithFooterWrapper>
      <Wrapper>
        <Grid>
          <RightContent>
            <Link to="/" className="back">
              <img src={IconArrowBack} alt="voltar" />
            </Link>
            <FlowTitle>
              Junte-se
              <br /> a nós.
            </FlowTitle>
            <p>Passo 1 de 4</p>

            <h4 onClick={() => history.push('/login')}>Já possuo conta</h4>
          </RightContent>
          <LeftContent>
            <hr />
            <Form onSubmit={formik.handleSubmit}>
              <div className="default-input">
                <input
                  name="name"
                  className={formik.errors.name && 'error'}
                  type="text"
                  placeholder="Nome"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <label>Nome</label>
                {formik.errors.name && formik.touched.name ? <ErrorWrapper>{formik.errors.name}</ErrorWrapper> : null}
              </div>
              <div className="default-input">
                <input
                  name="email"
                  className={formik.errors.email && 'error'}
                  type="email"
                  placeholder="E-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <label>E-mail</label>
                {formik.errors.email && formik.touched.email ? <ErrorWrapper>{formik.errors.email}</ErrorWrapper> : null}
              </div>
              <div className="default-input">
                <input
                  name="password"
                  className={formik.errors.password && 'error'}
                  type={passwordType.pass ? 'password' : 'text'}
                  placeholder="Senha"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <label>Senha</label>
                <button type="button" onClick={(e) => handleShowPassword(e, 1)}>
                  <img src={IconEye} alt="" />
                </button>
                {formik.errors.password && formik.touched.password ? (
                  <ErrorWrapper>{formik.errors.password}</ErrorWrapper>
                ) : null}
              </div>
              <div className="default-input">
                <input
                  name="confirmPassword"
                  className={formik.errors.confirmPassword && 'error'}
                  type={passwordType.confirm ? 'password' : 'text'}
                  placeholder="Confirmar senha"
                  onChange={formik.handleChange}
                />
                <label>Confirmar senha</label>
                <button type="button" onClick={(e) => handleShowPassword(e, 2)}>
                  <img src={IconEye} alt="" />
                </button>
                {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                  <ErrorWrapper>{formik.errors.confirmPassword}</ErrorWrapper>
                ) : null}
              </div>
              <div className="buttons">
                <Link to="/">Cancelar</Link>
                <button className="submit" disabled={!formik.isValid}>
                  Continuar
                </button>
              </div>
            </Form>
          </LeftContent>
        </Grid>
      </Wrapper>
    </WithFooterWrapper>
  );
}
