import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface Props {
  notifications?: boolean;
}

interface IHeader {
  isScrolled: boolean;
}

const Nav = styled.header<IHeader>`
  display: flex;
  align-items: center;
  background-color: ${({ isScrolled }: IHeader) => (isScrolled ? 'var(--default-background)' : 'transparent')};
  transition: all;
  transition-duration: 1s;
  @media screen and (min-width: 998px) {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 ${px2rem(32)};
    height: ${px2rem(95)};
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
    justify-content: space-between;
  }
`;

const NavLogo = styled(Link)`
  img {
    width: ${px2rem(150)};
  }

  @media screen and (max-width: 768px) {
    img {
      width: ${px2rem(200)};
    }
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  @media screen and (min-width: 998px) {
    height: 100%;
    margin: 0 ${px2rem(60)};
  }
`;
const NavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  a,
  p,
  button {
    background-color: transparent;
    border: none;
    color: #fff;
    text-decoration: none;
    font-size: ${px2rem(16)};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    &.active {
      color: var(--default-orange);
      border-bottom: 2px solid var(--default-orange);
    }
  }
  @media screen and (min-width: 998px) {
    &:not(:last-of-type) {
      margin-right: ${px2rem(30)};
    }
    height: 100%;
    a,
    p {
      padding: 0 10px;
    }
  }

  button.btn-infantil {
    & img {
      width: 45px;
    }
  }
`;

const NavSearch = styled.form`
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 ${px2rem(20)};
  height: ${px2rem(40)};
  border-radius: ${px2rem(20)};
  input {
    background-color: transparent;
    border: none;
    font-family: 'Lato';
    outline: none;
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 0 ${px2rem(10)};
  }
  img {
    width: ${px2rem(19)};
  }
  @media screen and (min-width: 998px) {
    width: ${px2rem(332)};
    margin: 0 ${px2rem(70)} 0 ${px2rem(10)};
  }
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;
const NavButton = styled.button`
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-end;
  div.notification-dot {
    background-color: red;
    position: absolute;
    width: ${px2rem(10)};
    height: ${px2rem(10)};
    border-radius: 100%;
  }
  @media screen and (min-width: 998px) {
    &:first-of-type {
      margin-right: ${px2rem(40)};
    }
  }
`;

const SubMenu = styled.div<Props>`
  background-color: #1a1e21;
  position: absolute;
  right: ${px2rem(-20)};
  top: ${px2rem(80)};
  min-height: ${px2rem(140)};
  display: flex;
  flex-direction: column;
  padding: ${px2rem(15)} ${px2rem(26)};
  border-radius: 0px 0px ${px2rem(8)} ${px2rem(8)};
  box-shadow: 1px 1px 0px #00000024;
  a,
  button,
  p {
    color: #fff;
    font-size: ${px2rem(16)};
    font-weight: 400;
    text-align: left;
    margin-top: ${px2rem(15)};
    cursor: pointer;
  }
  button {
    background-color: transparent;
    border: none;
    font-family: 'Lato';
  }

  ${(props) => (props.notifications ? ` width: ${px2rem(400)};` : ` width: ${px2rem(180)};`)}
`;

const NotificationsContainer = styled.div<Props>`
  background-color: #1a1e21;
  position: absolute;
  right: ${px2rem(-20)};
  top: ${px2rem(80)};
  min-height: ${px2rem(140)};
  display: flex;
  flex-direction: column;
  padding: ${px2rem(15)} ${px2rem(26)};
  border-radius: 0px 0px ${px2rem(8)} ${px2rem(8)};
  box-shadow: 1px 1px 0px #00000024;

  ${(props) => (props.notifications ? ` width: ${px2rem(400)};` : ` width: ${px2rem(180)};`)}
`;

const WrapperSubMenu = styled.div`
  position: relative;
  cursor: pointer;
`;

const SubMenuHover = styled.div`
  background-color: #1a1e21;
  position: absolute;
  right: ${px2rem(-50)};
  top: ${px2rem(40)};
  display: flex;
  flex-direction: column;
  padding: ${px2rem(20)} ${px2rem(26)};
  border-radius: 0px 0px ${px2rem(8)} ${px2rem(8)};
  box-shadow: 1px 1px 0px #00000024;
  opacity: 0;
  z-index: 2;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
  a {
    font-size: ${px2rem(16)};
    margin-bottom: ${px2rem(20)};
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      border: none;
    }
  }
`;

const MobileHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: ${px2rem(24)};
  width: 100%;
  display: flex;
  justify-content: space-between;
  img {
    width: ${px2rem(85)};
  }
  a {
    img {
      width: ${px2rem(20)};
    }
  }
`;

const MobileNavigator = styled.div`
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 75px;
  z-index: 5;
  background: #000;
  ul {
    display: grid;
    padding: 0 20px;
    height: 100%;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-content: center;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75px;

      &.active {
        border-top: 5px solid var(--default-orange);
        border-width: 5px;
      }

      svg {
        display: block;
        margin: 0 auto 1px;
        font-size: 30px;

        &.active {
          color: var(--default-orange);
        }
      }
      a,
      div {
        font-size: 14px;
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;

        &.active {
          color: var(--default-orange);
        }
      }
    }
  }
`;

const ProfileMenu = styled.div`
  background-color: #fff;
  position: absolute;
  right: ${px2rem(-20)};
  top: ${px2rem(50)};
  width: 188px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${px2rem(20)};
  border-radius: 0px 0px ${px2rem(8)} ${px2rem(8)};
  box-shadow: 1px 1px 0px #00000024;
  a,
  button,
  p {
    color: var(--default-background);
    font-size: ${px2rem(16)};
    font-weight: 400;
    text-align: left;
    margin-top: ${px2rem(15)};
  }
  button {
    background-color: transparent;
    border: none;
    font-family: 'Lato';
    cursor: pointer;
  }

  @media screen and (max-width: 997px) {
    position: absolute;
    border-radius: 8px;
    top: -150px;
    right: 5px;
    padding-top: 0;
    font-size: 1.25rem;
    background-color: #000;

    a,
    button,
    p {
      color: white;
    }
  }
`;

export {
  Nav,
  NavLogo,
  NavList,
  NavItem,
  NavSearch,
  ButtonWrapper,
  NavButton,
  NotificationsContainer,
  SubMenu,
  SubMenuHover,
  WrapperSubMenu,
  MobileHeader,
  MobileNavigator,
  ProfileMenu,
};
