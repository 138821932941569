import React from 'react';
import ReactDOM from 'react-dom';
import 'react-multi-carousel/lib/styles.css';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/pt-br';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
      <App />
    <ToastContainer position="top-center" autoClose={4000} hideProgressBar />
  </React.StrictMode>,
  document.getElementById('root')
);
