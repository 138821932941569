import styled, { css } from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface Props {
  animation?: boolean;
}

const AuthorWrapper = styled.div<Props>`
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  text-align: center;

  a {
    position: relative;
    display: block;
    > img {
      display: block;
      border-radius: 8px;
      transition: all 0.5s;
    }
  }
  img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  ${(props) =>
    props.animation
      ? css`
          &:hover {
            transition: all 1s;
            transform: scale(1.1);
            text-align: center;
            > a div {
              opacity: 1;
              border-radius: 8px;
            }
          }
        `
      : css`
          &:hover {
            transition: all 0.5s;
            > a div {
              border-radius: 8px;
              opacity: 1;
            }
          }
        `}
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  img {
    width: ${px2rem(40)};
    border-radius: 8px;
  }
  p {
    color: #fff;
    font-size: ${px2rem(24)};
    margin-top: ${px2rem(25)};
  }

  @media screen and (max-width: 768px) {
    img {
      width: ${px2rem(20)};
    }
    p {
      font-size: ${px2rem(12)};
    }
  }
`;

export { AuthorWrapper, Overlay };
