import React from 'react';
import Footer from '../components/Commons/Footer';
import Header from '../components/Commons/Header';
import NewPasswordBlock from '../components/LoginFlow/CreateNewPassword';
import { Desktop } from '../utils/responsiveRules';

export default function NewPassword() {
  return (
    <>
      <Header hasLogin={false} />
      <NewPasswordBlock />
      <Desktop>
        <Footer />
      </Desktop>
    </>
  )
}