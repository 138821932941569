import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

interface Props {
  bookmark?: boolean;
}

const MovieWrapper = styled.div`
  padding: 100px 30vw 10vh 10vw;

  position: relative;
  display: flex;
  flex-direction: column;
`;

const Details = styled.div`
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    margin-bottom: ${px2rem(20)};
    width: 40px;
    height: 40px;
    background-color: var(--default-orange);

    img {
      width: 25px;
    }
  }
`;

const MovieTitle = styled.h1`
  color: #fff;
  margin-bottom: ${px2rem(20)};
  font-size: ${px2rem(24)};
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(64)};
  }
`;

const MovieInfos = styled.p`
  font-size: ${px2rem(14)};
  color: #fff;
  margin-bottom: ${px2rem(10)};
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(16)};
    line-height: ${px2rem(24)};
  }
`;

const MovieDescription = styled.p`
  font-size: ${px2rem(14)};
  color: #fff;
  margin-bottom: ${px2rem(15)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
    line-height: ${px2rem(24)};
  }
`;

const WrapperButtons = styled.div`
  display: flex;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const PlayMovie = styled(Link)`
  display: flex;
  background-color: var(--default-orange);
  color: #fff;
  font-weight: 700;
  border-radius: ${px2rem(8)};
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${px2rem(20)};
  padding: ${px2rem(15)} ${px2rem(25)};

  img {
    margin-right: 5px;
  }

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }

  @media screen and (max-width: 480px) {
    margin: 0 0 15px;
  }
`;

const Sign = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--default-orange);
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  border-radius: ${px2rem(8)};
  cursor: pointer;
  font-size: ${px2rem(18)};
  padding: ${px2rem(15)} ${px2rem(25)};
  margin-right: ${px2rem(25)};

  img {
    margin-right: 5px;
  }

  p {
    white-space: nowrap;
  }

  @media screen and (max-width: 480px) {
    margin: 0 0 15px;
  }
`;

export { MovieWrapper, Details, MovieTitle, MovieInfos, MovieDescription, WrapperButtons, PlayMovie, Sign };
