import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import MovieCard from '../../components/Movies/MovieCard';
import { ListContents, Grid, TitleList, WithoutContent, CardWrapper } from './style';
import EmptyList from '../../assets/icon-empty.svg';
import { useEffect, useState } from 'react';
import { getBookmarks } from '../../services/bookmarks';
import GridSkeleton from '../../components/Commons/GridSkeleton';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';
import { Desktop, Mobile } from 'utils/responsiveRules';

const NoContent = () => (
  <WithoutContent>
    <div>
      <img src={EmptyList} alt="" />
    </div>
    <p>
      Você ainda não adicionou nenhum conteúdo a sua lista. Navegue pelo catálogo salve os conteúdos que que você não
      quer deixar de ver.
    </p>
  </WithoutContent>
);

export default function MyList() {
  const [bookmarks, setBookmarks] = useState<any>(null);

  const getAllBookmarks = async () => {
    const resultBookmarks = await getBookmarks();
    setBookmarks(resultBookmarks.contents.concat(resultBookmarks.trails));
  };

  useEffect(() => {
    getAllBookmarks();
  }, []);

  return (
    <div>
      <NavBar />
      <ListContents>
        <TitleList>Minha Lista</TitleList>
        <Desktop>
          {!bookmarks && <GridSkeleton />}
          {bookmarks && bookmarks?.length <= 0 && <NoContent />}
          <Grid>
            {bookmarks &&
              bookmarks?.map((item: any) => {
                if (item?.is_library) {
                  return (
                    <CardWrapper>
                      <MovieCard
                        to={`/movie/${item.content_id}`}
                        data={item}
                        key={`movie-${item.content_id}`}
                        myList={true}
                        id={item.content_id}
                        setBookmarks={setBookmarks}
                        bookmarks={bookmarks}
                      />
                    </CardWrapper>
                  );
                } else {
                  return (
                    <CardWrapper>
                      <MovieCard
                        to={`/trail/${item.trail_id}`}
                        data={item}
                        key={`trail-${item.trail_id}`}
                        myList={true}
                        id={item.trail_id}
                        setBookmarks={setBookmarks}
                        bookmarks={bookmarks}
                      />
                    </CardWrapper>
                  );
                }
              })}
          </Grid>
        </Desktop>
        <Mobile>
          {!bookmarks && <GridSkeleton />}
          {bookmarks && bookmarks?.length <= 0 && <NoContent />}
          <Grid>
            {bookmarks &&
              bookmarks?.map((item: any) => {
                if (item?.is_library) {
                  return (
                    <CardWrapper>
                      <MovieCard
                        isOnMyListPage
                        to={`/movie/${item.content_id}`}
                        data={item}
                        key={`movie-${item.content_id}`}
                        myList={true}
                        id={item.content_id}
                        setBookmarks={setBookmarks}
                        bookmarks={bookmarks}
                      />
                    </CardWrapper>
                  );
                } else {
                  return (
                    <CardWrapper>
                      <MovieCard
                        isOnMyListPage
                        to={`/trail/${item.trail_id}`}
                        data={item}
                        key={`trail-${item.trail_id}`}
                        myList={true}
                        id={item.trail_id}
                        setBookmarks={setBookmarks}
                        bookmarks={bookmarks}
                      />
                    </CardWrapper>
                  );
                }
              })}
          </Grid>
        </Mobile>
      </ListContents>
      <Footer />
      <MobileNavigatorSpace />
    </div>
  );
}
