import { useState } from 'react';
import { Items } from '../../../pages/Home/styles';
import Carousel from '../../Commons/Carousel';
import MovieDetail from '../../Movies/MovieDetail';
import MovieCard from '../../Movies/MovieCard';
import { memo } from 'react';
import GridSkeleton from 'components/Commons/GridSkeleton';

function MovieHome({ title, data }: any) {
  const [currentContent, setCurrentContent] = useState<any>();
  const [trailContent, setTrailContent] = useState<any>(false);

  const handleCurrentContent = (content: any) => {
    setCurrentContent(content?.content_id || content?.trail_id);
    if (!content.is_library) {
      setTrailContent(true);
    }
  };
  if (data?.length <= 0) return null;

  return (
    <Items>
      {!data && <GridSkeleton />}
      {data && (
        <Carousel title={title}>
          {data.map((content: any, key: number) => (
            <MovieCard hover={true} data={content} key={key} onClick={() => handleCurrentContent(content)} />
          ))}
        </Carousel>
      )}
      <MovieDetail id={currentContent} trail={trailContent} />
    </Items>
  );
}
export default memo(MovieHome);
