import httpClient from '../../http';

const getBookmarks = async () => {
  const bookmarks = (await httpClient.get('/user-bookmarks')).data;
  return bookmarks;
};

const addToBookmarks = async (id: string, is_library?: boolean) => {
  if (is_library) {
    const bookmarks = (await httpClient.post(`/user-bookmarks`, {
      content_id: id
    })).data;
    return bookmarks;
  }
  else {
    const bookmarks = (await httpClient.post(`/user-bookmarks`, {
      trail_id: id
    })).data;
    return bookmarks;
  }
}


const removeFromBookmark = async (id: string, is_library?: boolean) => {
  if (is_library) {
    const bookmarks = (await httpClient.delete(`/user-bookmarks`, {
      data: {
        content_id: id
      }
    })).data;
    return bookmarks;
  }
  else {
    const bookmarks = (await httpClient.delete(`/user-bookmarks`, {
      data: {
        trail_id: id
      }
    })).data;
    return bookmarks;
  }
}


export {
  getBookmarks,
  addToBookmarks,
  removeFromBookmark
}