import { Link } from 'react-router-dom';
import { CardContainer, DescriptionText, DetailFilmContainer, ImageFilm, Label, SmallText } from './styles';
import { INotification } from './types';

interface Props {
  notification: INotification;
}

export function NotificationCard({ notification }: Props) {
  const link = notification?.type === 'content' ? `/movie/${notification.id}` : `/trail/${notification.id}`;

  const categories = {
    filmes: 'Filmes',
    series: 'Séries',
    documentarios: 'Documentários',
    sermoes: 'Sermões',
    cursos: 'Cursos',
    palestras: 'Palestras',
    musicas: 'Músicas',
    lives_anteriores: 'Lives Anteriores',
  };

  return (
    <Link to={link}>
      <CardContainer>
        <ImageFilm src={notification.thumbnail} />

        <DetailFilmContainer>
          <Label>{notification.name}</Label>
          <DescriptionText>
            {notification.type === 'content' || notification.action === 'is_new'
              ? 'Novo conteúdo disponível'
              : `${notification.count} ${notification.count > 1 ? 'episódios adicionados' : 'episódio adicionado'} `}
          </DescriptionText>
          <SmallText>categoria: {categories[notification.tag]}</SmallText>
        </DetailFilmContainer>
      </CardContainer>
    </Link>
  );
}
