import { Wrapper, FlowTitle, Grid, RightContent, LeftContent } from '../style';
import { PlansWrapper, Plan, PlanName, PlanBenefits, PlanPrice } from './style';
import IconArrowBack from '../../../assets/icon-arrow-back.svg';
import PlanCheck from '../../../assets/check.svg';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllPlans } from 'services/Plans';
import { useEffect, useState } from 'react';
import PlanModelProps from 'services/Plans/models/plan-interface';
import { formatPrice } from 'utils/formatPrice';
import { checkoutPayment } from 'services/payment';
import Loading from 'components/Loading';

export default function ChoosePlanBlock() {
  const [plans, setPlans] = useState([] as PlanModelProps[]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getPlans = async () => {
    const resultPlans: PlanModelProps[] = await getAllPlans();
    setPlans(resultPlans);
  };

  const handleSelectPlan = async (plan: string) => {
    if (plan === 'Básico') {
      toast.success('Cadastro realizado com sucesso!');
      history.push('/login');
    } else {
      setIsLoading(true);
      await checkoutPayment()
        .then((data) => {
          if (data.success === true) {
            toast.success('Cadastro realizado com sucesso!, você receberá sua fatura por email em breve...');
            localStorage.removeItem('api_token');
            localStorage.removeItem('payment-token-teo');
            localStorage.removeItem('user');
            history.push('/login');
            window.open(data.url, '_blank');
          } else if (data.success === false) {
            toast.error(data.message);
          }
        })
        .catch((err) => {
          toast.error('Sistema de pagamentos indisponível no momento, tente novamente mais tarde.');
        });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid>
          <RightContent>
            <Link to="/register" className="back">
              <img src={IconArrowBack} alt="" />
            </Link>
            <FlowTitle>
              Escolha
              <br /> seu plano.
            </FlowTitle>
            <p>Passo 2 de 4</p>
          </RightContent>
          <LeftContent>
            <hr />
            <PlansWrapper>
              {plans?.map((plan) => {
                return (
                  <Plan>
                    <label className="container">
                      <input
                        type="radio"
                        className="checked"
                        name="radio"
                        onChange={() => handleSelectPlan(plan.name)}
                      />
                      <span className="checkmark"></span>
                    </label>

                    <PlanName>{plan.name}</PlanName>
                    <PlanPrice>{plan.name === 'Básico' ? 'Gratuito' : formatPrice(plan.price)}</PlanPrice>
                    <PlanBenefits>
                      {plan.benefits?.map((benefit) => {
                        return (
                          <li>
                            <img src={PlanCheck} alt="" />
                            {benefit}
                          </li>
                        );
                      })}
                    </PlanBenefits>
                  </Plan>
                );
              })}
            </PlansWrapper>
          </LeftContent>
        </Grid>
      )}
    </Wrapper>
  );
}
