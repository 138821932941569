import { Wrapper, Title, Highlights } from './styles';
import { Link } from 'react-router-dom';
import HighlightsKidsCarousel from 'components/Commons/RelatedCarousel';
import HomeSliderSkeleton from 'components/Home/HomeSlider/HomeSliderSkeleton';
import LazyImage from 'components/Commons/LazyImage';

export default function KidsHighlights({ data }: any) {
  return (
    <Wrapper>
      <Title>Destaques</Title>
      {!data && <HomeSliderSkeleton />}
      <HighlightsKidsCarousel title="Destaques">
        {data?.map((data: any) => {
          const link = data?.trail_id ? `/kids-trail/${data?.trail_id}` : `/kids-movie/${data?.content_id}`;
          return (
            <Highlights key={data.trail_id ? `${data.trail_id}` : `${data.content_id}`}>
              <Link to={link} title={data?.title || data?.name}>
                {data?.thumbnail && (
                  <LazyImage
                    src={data?.thumbnail ? data?.thumbnail_mobile : data?.info.thumbnail_Url}
                    alt={data?.thumbnail || data?.name}
                  />
                )}
              </Link>
            </Highlights>
          );
        })}
      </HighlightsKidsCarousel>
    </Wrapper>
  );
}
