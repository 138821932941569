import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 32px;
  height: ${px2rem(90)};
  display: flex;
  align-items: center;
  img {
    width: ${px2rem(200)};
    cursor: pointer;
  }
`;

export { Header };
