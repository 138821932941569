import React from 'react';

import { Container } from './style';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  text?: string;
}

const ButtonLoading: React.FC<IProps> = ({ isLoading = false, text, children, ...rest }: IProps) => {
  return <Container disabled={isLoading} {...rest} >{text || children} {isLoading && (<AiOutlineLoading3Quarters />)} </Container>;
}

export default ButtonLoading;