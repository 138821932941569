import Footer from '../components/Commons/Footer';
import Header from '../components/Commons/Header';
import CheckoutBlock from '../components/LoginFlow/Checkout';
import { Desktop } from '../utils/responsiveRules';

export default function Register() {
  return (
    <>
      <Header hasLogin={false} />
      <CheckoutBlock />
      <Desktop>
        <Footer />
      </Desktop>
    </>
  )
}