import styled from "styled-components"

const Spacer = styled.div`
  display: none;

  @media screen and (max-width: 997px) {
    display: block;
    position: relative;
    background-color: #000;
    height: 72px;
    width: 100%;
  }
`

export { Spacer }