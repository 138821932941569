import { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { getHighlighted } from 'services/home';
import { ContainerContent, ContainerImage, Description, Image, Info, PlayMovie, Title, WrapperButtons } from './styles';
import IconPlay from '../../assets/icon-play.svg';
import AddToBookmarks from 'components/Commons/AddToBookmarks';
import { useHistory } from 'react-router-dom';
import { renewToken } from 'services/userToken';
import BePremiumModal from 'components/Modals/BePremiumSmallSize';
import { ContextBePremiumModal } from 'utils/context';

export default function HighlightedContent() {
  const [highlightedContent, setHighlightedContent] = useState<any>();
  const [user, setUser] = useState<any>();

  const { openModalPremium, setOpenModalPremium } = useContext(ContextBePremiumModal);
  const history = useHistory();

  const getHiglight = async () => {
    const { api_token, user } = await renewToken();
    localStorage.setItem('api_token', api_token);
    localStorage.setItem('user', JSON.stringify(user));
    const resulthighlightedContent = await getHighlighted();
    setHighlightedContent(resulthighlightedContent);
  };

  useEffect(() => {
    getHiglight();
    const localUser = localStorage.getItem('user') as string;
    setUser(JSON.parse(localUser));
  }, []);

  const handleEnterPlay = () => {
    if (!!highlightedContent?.trail_id) {
      history.push(`/trail/${highlightedContent?.trail_id}`);
    } else {
      history.push(`/player/${highlightedContent?.content_id}`);
    }
  };
  const signPlan = () => {
    history.push('/profile', {
      renewPlan: true,
    });
  };
  return (
    <>
      {openModalPremium && <BePremiumModal />}
      <ContainerImage>
        <Image
          image={
            highlightedContent?.info?.banner_url ? highlightedContent?.info?.banner_url : highlightedContent?.thumbnail
          }
        />

        <ContainerContent>
          <Info>
            {highlightedContent?.title && <Title>{highlightedContent?.title}</Title>}
            {highlightedContent?.name && <Title>{highlightedContent?.name}</Title>}
            {/*<MovieInfos>Filme   2016   2h 8min</MovieInfos>*/}
            {highlightedContent?.description && <Description>{highlightedContent?.description}</Description>}
            {highlightedContent && (
              <WrapperButtons>
                {highlightedContent?.user?.premium && highlightedContent?.info?.premium && (
                  <PlayMovie onClick={handleEnterPlay}>
                    <img src={IconPlay} alt="Play" />
                    <p>Assistir</p>
                  </PlayMovie>
                )}
                {!highlightedContent?.user?.premium && !highlightedContent?.info?.premium && (
                  <PlayMovie onClick={handleEnterPlay}>
                    <img src={IconPlay} alt="Play" />
                    <p>Assistir</p>
                  </PlayMovie>
                )}
                {highlightedContent?.user?.premium && !highlightedContent?.info?.premium && (
                  <PlayMovie onClick={handleEnterPlay} title="Assistir">
                    <img src={IconPlay} alt="Play" />
                    <p>Assistir</p>
                  </PlayMovie>
                )}
                {!highlightedContent?.user?.premium && highlightedContent?.info?.premium && (
                  <PlayMovie
                    onClick={async () => {
                      setOpenModalPremium(true);
                    }}
                  >
                    Assine agora
                  </PlayMovie>
                )}
                <AddToBookmarks data={highlightedContent} bookmark={highlightedContent?.user?.bookmark} />
              </WrapperButtons>
            )}
          </Info>
        </ContainerContent>
      </ContainerImage>
    </>
  );
}
