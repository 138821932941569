import DetailKids from "pages/DetailKids";
import HomeKids from "pages/HomeKids";
import KidsSearch from "pages/KidsSearch";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { path: '/kids-movie/:id/:trail?', component: DetailKids },
  { path: '/kids-course/:id', component: DetailKids },
  { path: '/kids-trail/:id', component: DetailKids },
  { path: '/kids-search/:term', component: KidsSearch },
  { path: '/kids', component: HomeKids }
]