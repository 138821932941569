import httpClient from '../../http';

const getSearch = async (term: string) => {
  const searchResult = (await httpClient.get('/search', {
    params: {
      search: term
    }
  })).data;
  return searchResult;
};

const getSimilar = async (categoryId: any, is_unique = false) => {
  const similarResult = (await httpClient.get(
    `/search/similar-titles`
    , {
      params: {
        categories: categoryId,
        is_unique
      }
    })).data;
  return similarResult;
}
export {
  getSearch,
  getSimilar
}