import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const WrapperBanner = styled.div`
  position: relative;
  img {
    width: 100%;
    display: block;
  }

  @media screen and (max-width: 768px) {
    img {
      height: 375px;
      object-fit: cover;
      opacity: 60%;
    }
  }
`;

const WrapperBannerContent = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  max-width: var(--home-grid);
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 120px 20px 0;

  p {
    color: #fff;
    font-size: 14px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    height: 49px;
    width: 232px;
    text-transform: uppercase;
    background-color: var(--default-orange);
    border-radius: ${px2rem(8)};
    font-weight: 700;
    margin-top: 20px;
    border: 0;
  }

  @media screen and (min-width: 998px) {
    padding: ${px2rem(202)} 20px 0;
    a {
      margin-top: ${px2rem(90)};
      width: ${px2rem(380)};
      height: ${px2rem(77)};
      font-size: ${px2rem(24)};
    }
    p {
      font-size: ${px2rem(24)};
    }
  }
`;

const BannerTitle = styled.h1`
  font-weight: 700;
  color: #fff;
  font-size: 18px;
  span {
    color: var(--default-orange);
  }

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(56)};
    width: ${px2rem(675)};
    margin-bottom: ${px2rem(20)};
  }

  @media screen and (max-width: 480px) {
    font-weight: bold;
    font-size: 24px;
    width: 249px;
    margin-bottom: 31px;
  }
`;

const PropositoWrapper = styled.div`
  background-color: var(--default-background);
  padding: 40px 0;
  div {
    max-width: var(--home-grid);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  p {
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  @media screen and (min-width: 998px) {
    padding: ${px2rem(170)} 0;
    div {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    p {
      width: ${px2rem(565)};
      font-size: ${px2rem(20)};
      line-height: ${px2rem(32)};
      text-align: inherit;
    }
  }
`;

const PropositoTitle = styled.h2`
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(32)};
    text-align: inherit;
    width: ${px2rem(430)};
    margin-right: ${px2rem(30)};
    margin-bottom: 0;
  }
`;

const WrapperOverlayItems = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 998px) {
    flex-direction: row;
  }
`;
const OverlayItem = styled.div`
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #fff;
      font-size: ${px2rem(22)};
      width: ${px2rem(250)};
      text-align: center;
    }
  }

  @media screen and (min-width: 998px) {
    width: 34%;
    height: ${px2rem(600)};
    div {
      p {
        font-size: ${px2rem(24)};
      }
    }
  }

  @media screen and (max-width: 480px) {
    img {
      opacity: 60%;
      height: 212px;
      object-fit: cover;
    }

    div p {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

export {
  WrapperBanner,
  WrapperBannerContent,
  BannerTitle,
  PropositoWrapper,
  PropositoTitle,
  WrapperOverlayItems,
  OverlayItem,
};
