import { useEffect, useState } from 'react';
import { getContentTerms } from 'services/home';
import NavBar from 'components/Commons/NavBar';
import Footer from 'components/Commons/Footer';
import { Wrapper, Body } from './style';
import TermsHeader from 'components/TermsHeader';
import { Spacer } from 'components/Commons/MobileNavigatorSpace/style';
import IconArrowBack from 'assets/icon-arrow-back.svg';
import { Link } from 'react-router-dom';

export default function Terms() {
  const [term, setTerm] = useState<any>('');
  const [user, setUser] = useState<boolean>(false);

  const getTerm = async () => {
    const term = await getContentTerms();
    setTerm(term);
  };

  useEffect(() => {
    const localUser = localStorage.getItem('user') as string;

    if (!!localUser) {
      setUser(true);
    }

    getTerm();
  }, []);

  return (
    <>
      <Wrapper>
        {user ? <NavBar /> : <TermsHeader />}
        <Link to="/" className="back">
          <img src={IconArrowBack} alt="voltar" />
        </Link>
        <Body>
          <div dangerouslySetInnerHTML={{ __html: term?.term_of_use }}></div>
        </Body>
      </Wrapper>
      <Footer />
      <Spacer />
    </>
  );
}
