import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';

const WrapperLive = styled.div`
  header {
    background-color: var(--default-background);
  }
`;

const CurrentLives = styled.div`
  background-color: #000;
  padding: ${px2rem(32)} 0;
  div.header {
    width: 80%;
    margin: ${px2rem(20)} auto;
    h1 {
      color: #fff;
      font-size: ${px2rem(24)};
    }
    p {
      margin-top: ${px2rem(10)};
      color: #fff;
      font-size: ${px2rem(18)};
    }
  }
  @media screen and (min-width: 998px) {
    padding: ${px2rem(200)} 0 ${px2rem(80)};
    div.header {
      margin: 0 auto;
      h1 {
        font-size: ${px2rem(48)};
      }
    }
  }
  @media screen and (max-width: 480px) {
    div.header {
      width: 100%;
      padding: 10px 24px;
    }
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1 {
    margin-left: 25px;
    grid-column: 1 / span 3;
    color: #fff;
    font-size: ${px2rem(18)};
    align-self: flex-start;
    margin-bottom: ${px2rem(20)};
  }
  @media screen and (min-width: 998px) {
    h1 {
      font-size: ${px2rem(32)};
    }
    width: 80%;
    margin: 0 auto;
    padding-top: ${px2rem(60)};
    display: grid;
    grid-column-gap: ${px2rem(4)};
    grid-row-gap: ${px2rem(24)};
    grid-template-columns: repeat(3, 1fr);
    padding: ${px2rem(20)} 0;

    img {
      max-width: 250px;
      border-radius: 8px;
      transition: transform 0.2s ease-in-out;
      width: 100%;
      &:hover {
        transform: scale(1.01);
      }
    }
  }

  @media screen and (max-width: 768px) {
    a {
      img {
        width: ${px2rem(500)};
        border-radius: 8px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    a {
      img {
        width: ${px2rem(300)};
        border-radius: 8px;
      }
    }
  }
`;

const NextAndPreviousLives = styled.div`
  background-color: var(--default-background);
  padding-bottom: ${px2rem(250)};
  @media screen and (max-width: 997px) {
    padding-top: ${px2rem(30)};
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 30px;
  }
`;

const NoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${px2rem(40)};
  div {
    background-color: rgba(255, 255, 255, 0.15);
    width: ${px2rem(180)};
    height: ${px2rem(180)};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    color: #fff;
    font-size: ${px2rem(18)};
    margin-top: ${px2rem(50)};
  }

  @media screen and (max-width: 480px) {
    margin: 0 24px;
    p {
      text-align: center;
    }
  }
`;

const LiveCardWrapper = styled.div`
  margin-bottom: 24px;
  img {
    width: 100%;
  }
  p {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: ${px2rem(18)};
    line-height: ${px2rem(24)};
    color: #fff;
  }
  @media screen and (min-width: 998px) {
    width: ${px2rem(375)};
    margin-bottom: 0;
  }
`;

export { WrapperLive, CurrentLives, Grid, NextAndPreviousLives, NoContent, LiveCardWrapper };
