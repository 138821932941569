import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import MovieCard from '../../components/Movies/MovieCard';
import { ListContents, Grid, TitleList, WithoutContent, CardWrapper } from './style';
import EmptyList from '../../assets/icon-empty.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import GridSkeleton from '../../components/Commons/GridSkeleton';
import { getTrailTag } from '../../services/tags';
import { Spacer } from 'components/Commons/MobileNavigatorSpace/style';

const NoContent = () => (
  <WithoutContent>
    <div>
      <img src={EmptyList} alt="" />
    </div>
    <p>Nenhum conteúdo a esta categoria no momento. Navegue pelo catálogo para encontrar mais conteúdos.</p>
  </WithoutContent>
);

export default function Series() {
  const [content, setContent] = useState<any>();
  const getData = async () => {
    const series = await getTrailTag('series');
    setContent(series);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <NavBar />
      <ListContents>
        <TitleList>Séries</TitleList>
        {!content && <GridSkeleton />}
        {content?.length <= 0 && <NoContent />}
        <Grid>
          {content &&
            content.map((serie: any, key: any) => {
              return (
                <CardWrapper>
                  <MovieCard to={`/trail/${serie.trail_id}`} data={serie} key={`serie-${key}`} />
                </CardWrapper>
              );
            })}
        </Grid>
      </ListContents>
      <Footer />
      <Spacer />
    </>
  );
}
