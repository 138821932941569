import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { WrapperLive, HeaderLive } from './style';
import ArrowBack from '../../../assets/arrow-back-white.svg';
import { getCurrentLive } from 'services/lives';
import { useParams } from 'react-router-dom';
import { LivesProps } from 'services/lives/models/liveModels';
import Loading from 'components/Loading';

export default function Live() {
  const { id } = useParams<any>();
  const history = useHistory();
  const [activeLive, setActiveLive] = useState<LivesProps>();
  const [isLoading, setIsLoading] = useState(false);

  const getActiveLive = useCallback(async () => {
    setIsLoading(true);
    const localLive = await getCurrentLive(id);
    
    if(localLive.status === 'STOPPED') {
      return toast.error('Live finalizada! Voltando a tela de transmissões!', {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => history.push('/live')
      });
    }

    setActiveLive(localLive);
    setIsLoading(false);
  }, [id]);

  const liveUrl = useMemo(() => {
    const playerHash = process.env.REACT_APP_PLAYER_HASH;
    const playerBaseUrl = process.env.REACT_APP_BASE_REFERENCE_URL;
    if (activeLive?.channel_sv_id) {
      return `${playerBaseUrl}/${playerHash}/live/${activeLive.channel_sv_id}`;
    }
    return `${playerBaseUrl}/${playerHash}`;
  }, [activeLive?.channel_sv_id]);

  useEffect(() => {
    getActiveLive();
  }, [getActiveLive]);

  return (
    <WrapperLive>
      <div className="live-video-container">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <HeaderLive>
              <Link to="/live">
                <img src={ArrowBack} alt="" />
              </Link>
            </HeaderLive>
            {activeLive && (
              activeLive.external_live ? (
                <iframe src={activeLive.external_live_url} title={activeLive.name}></iframe>
              ) : (
                <iframe src={liveUrl} title={activeLive.name}></iframe>
              )
            )}
          </>
        )}
      </div>
    </WrapperLive>
  );
}
