import React, { useCallback, useEffect, useState } from 'react';
import { totalViewed as totalViewedService } from '../services/contents';


const useTotalViewd = (id?: string) => {
  const [contentId, setContentId] = useState<string | undefined>(id);
  const [trailId, setTrailId] = useState<string>();
  const [courseId, setCourseId] = useState<string>();
  
  const [timeOn, setTimeOn] = useState<boolean>(false);
  const [viewingTime, setViewingTime] = useState({
    contentId: contentId,
    time: 0
  });

  const setTotalViewed = useCallback(async () => {
    const storage = JSON.parse(localStorage.getItem(`time_${contentId}`) || '');
    
    if (contentId && !trailId && !courseId) {
      await totalViewedService(contentId, storage.time);
    }

    if (contentId && trailId && courseId) {
      await totalViewedService(contentId, storage.time, trailId, courseId);
    }

    storage.time = 0;
    setViewingTime(storage);
    localStorage.setItem(`time_${contentId}`, JSON.stringify(storage));
  }, [courseId, contentId, trailId]);

  const checkView = useCallback(() => {
    const timeStorage = localStorage.getItem(`time_${contentId}`);
    let timeStorageParse;
    
    if (timeStorage === null) {
      localStorage.setItem(`time_${contentId}`, JSON.stringify(viewingTime));
    } else {
      timeStorageParse = JSON.parse(timeStorage);
      setViewingTime(timeStorageParse);
    }
    
    if (timeStorage !== null && timeStorageParse.time >= 60) {
      setTotalViewed();
    }
  }, [contentId]);

  const tick = useCallback((event: string) => {
    if (event === 'onProgress') {
      setTimeOn(true);
    }

    if (event === 'onPause') {
      setTimeOn(false);
      setTotalViewed();
    }

    if (event === 'onFinish') {
      setTimeOn(false);
      setTotalViewed();
    }
  }, [setTotalViewed]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    const stateViewingTime = viewingTime;
    
    if (timeOn) {
      interval = setInterval(() => {
        stateViewingTime.time = stateViewingTime.time + 1;
        localStorage.setItem(`time_${contentId}`, JSON.stringify(stateViewingTime));
        
        if (stateViewingTime.time === 60) {
          setTotalViewed();
        }
      }, 1000);
    }

    
    return () => clearInterval(interval);
  }, [contentId, timeOn, viewingTime]);

  const removeLocalStorageTime = async () => {
    const currentStorage = JSON.parse(localStorage.getItem(`time_${contentId}`) || '');

    if (contentId && !trailId && !courseId) {
      await totalViewedService(contentId, currentStorage.time);
    }

    if (contentId && trailId && courseId) {
      await totalViewedService(contentId, currentStorage.time, trailId, courseId);
    }

    localStorage.removeItem(`time_${contentId}`);
  }

  const clearTimeAtHome = async () => {
    const myStorage = localStorage;
    const timeKeys = Object.keys(myStorage).filter(key => key.substring(0, 5) === 'time_');
    
    if (timeKeys.length) {
      timeKeys.forEach(async (key) => {
        let storageParsed = JSON.parse(myStorage[key]);
  
        await totalViewedService(storageParsed.contentId, storageParsed.time);
        localStorage.removeItem(key);
      });
    }
  }

  return {
    addContentId: (id: string) => setContentId(id),
    addCourseId: (id: string) => setCourseId(id),
    addTrailId: (id: string) => setTrailId(id),
    checkView,
    tick,
    setTotalViewed,
    removeLocalStorageTime,
    clearTimeAtHome,
  }
}

export default useTotalViewd;
