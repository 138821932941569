import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getHistory, updateName } from '../../../services/user';
import { History, User } from '../../../services/user/models/user-interface';
import { useLocation } from 'react-router-dom';
import { Wrapper, Plan, NameInput, HistoryList, Billing, ChangePassword as ChangePasswordSection } from './style';
import { FiChevronDown, FiPlay } from 'react-icons/fi';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import Visa from '../../../assets/brands/Visa.png';
import MasterCard from '../../../assets/brands/MasterCard.png';
import Alelo from '../../../assets/brands/Alelo.png';
import DinersClub from '../../../assets/brands/DinersClub.png';
import EditPaymentMethod from '../../Modals/EditPaymentMethod';
import ChangePassword from '../../Modals/ChangePassword';
import BePremium from 'components/Modals/BePremium';
import { toast } from 'react-toastify';
import ButtonLoading from 'components/ButtonLoading';
import { useHistory } from 'react-router';
import { ContextBePremiumModal } from 'utils/context';

export default function ProfileForm({ user, daysLeft }: { user: User; daysLeft: number }) {
  const inputNameRef = useRef<HTMLInputElement | null>(null);
  const inputEmailRef = useRef<HTMLInputElement | null>(null);

  const [history, setHistory] = useState([] as History[]);
  const [editUser, setEditUser] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [focusInputName, setFocusInputName] = useState(false);
  const [name, setName] = useState(user.name);
  const [editPaymentMethod, setEditPaymentMethod] = useState(false);
  const [bePremium, setBePremium] = useState(false);
  const location: any = useLocation();
  const ht = useHistory();

  const [loadingSaveName, setLoadingSaveName] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const { setOpenModalPremium } = useContext(ContextBePremiumModal);

  useEffect(() => {
    if (location.state && location.state.renewPlan) {
      setBePremium(true);
    }

    const getHistoryUser = async () => {
      const data = await getHistory();
      setHistory(data);
    };

    getHistoryUser();
  }, []);

  const handleEditName = useCallback(async () => {
    if (editUser && name) {
      setLoadingSaveName(true);
      await updateName({ name });
      setLoadingSaveName(false);
      toast.success('Nome alterado com sucesso.');
    }
    setEditUser(!editUser);
  }, [editUser, name]);

  useEffect(() => {
    if (editUser) {
      inputNameRef.current?.focus();
    }
  }, [editUser]);

  const getCardBrand = useCallback((cardCompany) => {
    if (cardCompany === 'visa') {
      return Visa;
    }

    if (cardCompany === 'alelo') {
      return Alelo;
    }

    if (cardCompany === 'dinersclub') {
      return DinersClub;
    }
    return MasterCard;
  }, []);

  const handleFinishBePremium = useCallback(() => {
    setBePremium(false);
    toast.success('Alteração de plano realizada com sucesso.');
    ht.go(0);
  }, [ht]);

  const handleFinishUpdateCard = useCallback(() => {
    setEditPaymentMethod(false);
    toast.success('Metódo de pagamento atualizado com sucesso.');
  }, []);

  const handleFinishUpdatePassword = useCallback(() => {
    setEditPassword(false);
    toast.success('Senha alterada com sucesso.');
  }, []);

  return (
    <Wrapper>
      {editPaymentMethod && (
        <EditPaymentMethod
          onFinish={handleFinishUpdateCard}
          onCancel={() => setEditPaymentMethod(false)}
          onClose={() => setEditPaymentMethod(false)}
        />
      )}
      {bePremium && (
        <BePremium
          onFinish={handleFinishBePremium}
          onCancel={() => setBePremium(false)}
          onClose={() => setBePremium(false)}
        />
      )}
      {editPassword && (
        <ChangePassword
          onFinish={handleFinishUpdatePassword}
          onCancel={() => setEditPassword(false)}
          onClose={() => setEditPassword(false)}
        />
      )}

      <NameInput isFocus={focusInputName}>
        <label>Nome</label>
        <div>
          <input
            onBlur={() => setFocusInputName(false)}
            onFocus={() => setFocusInputName(true)}
            ref={inputNameRef}
            type="text"
            onChange={(e) => setName(e.target.value)}
            disabled={!editUser}
            defaultValue={user.name}
          />
          <ButtonLoading isLoading={loadingSaveName} type="button" onClick={handleEditName}>
            {' '}
            {editUser ? 'Salvar' : 'Editar'}
          </ButtonLoading>
        </div>
      </NameInput>

      <NameInput isFocus={focusInputName}>
        <label>Email</label>
        <div>
          <input
            disabled
            onBlur={() => setFocusInputName(false)}
            onFocus={() => setFocusInputName(true)}
            ref={inputEmailRef}
            type="email"
            defaultValue={user.email}
          />
        </div>
      </NameInput>

      <ChangePasswordSection>
        <label>Senha</label>
        <div>
          <input type="password" value="example" disabled />
          <button type="button" onClick={() => setEditPassword(true)}>
            Alterar Senha
          </button>
        </div>
      </ChangePasswordSection>

      <Plan>
        <h3>Plano atual</h3>
        <div>
          <div>
            <strong>{user.plan === 'premium' ? 'Plano Premium' : 'Plano Básico'}</strong>
            <span> {user.plan === 'premium' ? 'R$ 14,90/ mês' : 'Gratuito'}</span>
          </div>
          {user.plan !== 'premium' && (
            // <button type="button" onClick={() => setBePremium(true)}>
            //   Alterar plano
            // </button>
            <button
              type="button"
              onClick={() => {
                setOpenModalPremium(true);
                //window.open(`${process.env.REACT_APP_VINDI_PAYMENT_URL}`, '_self')
              }}
            >
              Alterar plano
            </button>
          )}
        </div>
      </Plan>

      {user.plan !== 'free' && (
        <Billing>
          {daysLeft <= 1 ? (
            <>
              <h3>Assinatura e cobrança</h3>
              {user.next_billing && user.plan === 'premium' && (
                <p>
                  Sua próxima cobrança será no dia{' '}
                  <span> {format(new Date(user.next_billing), "dd 'de' MMMM 'de' yyyy", { locale: pt })}.</span>
                </p>
              )}
              {/* <div>
                {user.card_numbers && user.card_type && (
                  <div>
                    <img src={getCardBrand(user.card_type)} alt="Bandeira do Cartão" /> **** **** ****{' '}
                    {user.card_numbers}
                  </div>
                )}
                <button type="button" onClick={() => setEditPaymentMethod(true)}>
                  {' '}
                  {user.card_numbers ? 'Editar' : 'Adicionar forma de pagamento'}
                </button>
              </div> */}
            </>
          ) : (
            <div>
              <p>
                Restam{' '}
                <span>
                  {daysLeft} dia{daysLeft !== 1 ? 's' : ''}
                </span>{' '}
                de assinatura premium
              </p>
            </div>
          )}
        </Billing>
      )}

      <HistoryList isOpen={showHistory}>
        <button type="button" onClick={() => setShowHistory(!showHistory)}>
          <h3>Histórico de conteúdos assistidos</h3> <FiChevronDown />
        </button>
        {showHistory && (
          <ul>
            {history.length &&
              history.map((item, index) => (
                <li key={index}>
                  <a
                    href={
                      item.tag === 'series' || item.tag === 'albuns'
                        ? `/trail/${item.trail_id}`
                        : `/movie/${item.content_id}`
                    }
                  >
                    <span>{item.updated_at && format(new Date(item.updated_at), 'dd/MM/yyyy')}</span>{' '}
                    <span>{item.title}</span>{' '}
                    <span className="buttonPlay">
                      <FiPlay />
                    </span>{' '}
                  </a>
                </li>
              ))}
          </ul>
        )}
      </HistoryList>
    </Wrapper>
  );
}
