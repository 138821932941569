import React from 'react';
import { FiX } from 'react-icons/fi';
import { BsExclamationOctagon } from 'react-icons/bs';

import { Container } from './style';
import ButtonLoading from 'components/ButtonLoading';

interface IProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  isLoadingButton?: boolean;
}

const ConfirmModal: React.FC<IProps> = ({ title, message, onConfirm, onCancel, onClose, isLoadingButton }: IProps) => {
  return (
    <Container>
      <div>
        <button onClick={onClose}>
          <FiX />
        </button>
        <h2>{title}</h2>
        <div>
          <span>
            <BsExclamationOctagon />
          </span>
          <p>{message}</p>
        </div>
        <footer>
          <button onClick={onCancel}>Cancelar</button>
          <ButtonLoading isLoading={isLoadingButton} disabled={isLoadingButton} onClick={onConfirm}>
            Continuar
          </ButtonLoading>
        </footer>
      </div>
    </Container>
  );
};

export default ConfirmModal;
