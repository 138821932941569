import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
  cpf: Yup.string().required("Obrigatório"),
  card_number: Yup.string().required("Obrigatório"),
  cvv: Yup.string().required("Obrigatório").min(3).max(4),
  vality: Yup.string().required("Obrigatório"),
});

export { schema };
