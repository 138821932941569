import {
  Wrapper,
  MovieInfoWrapper,
  MovieTitle,
  MovieResume,
  MovieDescription,
  WrapperUserButtons,
  SignPlanBtn,
  PlaySignBtnWrapper,
} from './style';
import { addToBookmarks } from '../../../services/bookmarks';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Play from '../../../assets/detail-play.svg';
import AddToBookmarks from 'components/Commons/AddToBookmarks';
import UserLike from 'components/Commons/UserLike';
import { ContextBePremiumModal } from 'utils/context';

export default function MovieInfo({ data }: any) {
  const hour = `${Math.floor(data.duration / (60 * 60)).toFixed(0)}`;
  const min = `${Math.floor((data.duration / 60) % 60).toFixed(0)}`;
  let time: string;

  if ((hour === '1' && min === '0') || (hour === '0' && min === '60')) {
    time = '1hr';
  } else if (hour === '0') {
    const splitMinutes = min.split('.');
    splitMinutes[1] === '00' ? (time = min) : (time = `${splitMinutes[0]} min`);
  } else {
    time = `${hour}h ${min} min`;
  }

  const [listCheck, setListCheck] = useState(false);
  const [user, setUser] = useState<any>();
  const history = useHistory();
  const { setOpenModalPremium } = useContext(ContextBePremiumModal);

  const handleAddToBookmarks = async (data: any) => {
    if (data.content_id) {
      await addToBookmarks(data.content_id, data.is_library).then(() => {
        setListCheck(true);
      });
    } else {
      await addToBookmarks(data.trail_id).then(() => {
        setListCheck(true);
      });
    }
  };

  const getContentTag = (tag: string) => {
    switch (tag) {
      case 'filmes':
        return 'Filme';
      case 'sermoes':
        return 'Sermão';
      case 'palestras':
        return 'Palestra';
      case 'documentarios':
        return 'Documentário';
      case 'cursos':
        return 'Curso';
      case 'series':
        return 'Série';
    }
  };

  useEffect(() => {
    if (data) setListCheck(data.user.bookmark);
    const localUser = localStorage.getItem('user') as string;

    setUser(JSON.parse(localUser));
  }, [data]);

  const handleEnterPlay = () => {
    if (!!data.trail_id) {
      history.push(
        `/show/${data.trail_id}/season/${data.courses[0].course_id}/episode/${data.courses[0].contents[0].content_id}`
      );
    } else {
      history.push(`/player/${data.content_id}`);
    }
  };
  const signPlan = () => {
    history.push('/profile', {
      renewPlan: true,
    });
  };
  return (
    <Wrapper>
      <MovieInfoWrapper>
        <MovieTitle>{data?.title}</MovieTitle>
        <MovieResume>
          {getContentTag(data?.tag)} • {data?.info?.year} • {time}
        </MovieResume>
        <MovieDescription>{data?.description}</MovieDescription>
        <WrapperUserButtons>
          <AddToBookmarks data={data} bookmark={data?.user?.bookmark} />
          <UserLike liked={data?.user?.like} data={data} />
        </WrapperUserButtons>
      </MovieInfoWrapper>
      <PlaySignBtnWrapper>
        {data?.user?.premium && data?.info?.premium && (
          <a className="play" onClick={handleEnterPlay} href="" title={data?.title}>
            <img src={Play} alt="play" />
          </a>
        )}
        {!data?.user?.premium && !data?.info?.premium && (
          <a className="play" onClick={handleEnterPlay} href="" title={data?.title}>
            <img src={Play} alt="play" />
          </a>
        )}
        {data?.user?.premium && !data?.info?.premium && (
          <a className="play" onClick={handleEnterPlay} href="" title={data?.title}>
            <img src={Play} alt="play" />
          </a>
        )}
        {!data?.user?.premium && data?.info?.premium && (
          <SignPlanBtn
            onClick={async () => {
              setOpenModalPremium(true);
            }}
          >
            <p>Assine agora</p>
          </SignPlanBtn>
        )}
      </PlaySignBtnWrapper>
    </Wrapper>
  );
}
