import { memo, useContext } from 'react';
import {
  Wrapper,
  MovieDatasheetWrapper,
  MovieTitle,
  MovieResume,
  DetailList,
  DetailItem,
  DetailTitle,
  PlaySignBtnWrapper,
} from './style';
import Play from '../../../assets/detail-play.svg';
import { SignPlanBtn } from '../MovieInfo/style';
import { Link, useHistory } from 'react-router-dom';
import { ContextBePremiumModal } from 'utils/context';

function MovieDatasheet({ data }: any) {
  const history = useHistory();
  const { setOpenModalPremium } = useContext(ContextBePremiumModal);

  const hour = `${Math.floor(data.duration / (60 * 60)).toFixed(0)}`;
  const min = `${Math.floor((data.duration / 60) % 60).toFixed(0)}`;
  let time: string;

  if ((hour === '1' && min === '0') || (hour === '0' && min === '60')) {
    time = '1hr';
  } else if (hour === '0') {
    const splitMinutes = min.split('.');
    splitMinutes[1] === '00' ? (time = min) : (time = `${splitMinutes[0]} min`);
  } else {
    time = `${hour}h ${min} min`;
  }

  const getContentTag = (tag: string) => {
    switch (tag) {
      case 'filmes':
        return 'Filme';
      case 'sermoes':
        return 'Sermão';
      case 'palestras':
        return 'Palestra';
      case 'documentarios':
        return 'Documentário';
      case 'cursos':
        return 'Curso';
      case 'series':
        return 'Série';
    }
  };
  const handleEnterPlay = () => {
    if (!!data.trail_id) {
      history.push(
        `/show/${data.trail_id}/season/${data.courses[0].course_id}/episode/${data.courses[0].contents[0].content_id}`
      );
    } else {
      history.push(`/player/${data.content_id}`);
    }
  };

  const signPlan = () => {
    history.push('/profile', {
      renewPlan: true,
    });
  };

  return (
    <Wrapper>
      <MovieDatasheetWrapper>
        <MovieTitle>{data.title}</MovieTitle>
        <MovieResume>
          {getContentTag(data?.tag)} • {data?.info?.year} • {time}
        </MovieResume>
        <DetailList>
          {data?.info?.original_title && (
            <DetailItem>
              <DetailTitle>Título original - </DetailTitle>
              {data?.info?.original_title}
            </DetailItem>
          )}
          {/* {data?.authors && (
            <DetailItem>
              <DetailTitle>Autor - </DetailTitle>
              {data?.authors?.map((author: any, key: number) => {
                return author.name;
              })}
            </DetailItem>
          )} */}
          {data?.categories && (
            <DetailItem>
              <DetailTitle>Categoria - </DetailTitle>
              {data?.categories?.map((category: any, key: number) => {
                return key + 1 == data.categories.length ? `${category.title}` : `${category.title}, `;
              })}
            </DetailItem>
          )}
          {data?.info?.cast && (
            <DetailItem>
              <DetailTitle>Elenco - </DetailTitle>
              {data?.info?.cast}
            </DetailItem>
          )}
          <Link
            to={
              data.trail_id
                ? `/trail/${data.trail_id}`
                : data.movie_id
                ? `/movie/${data.movie_id}`
                : `/movie/${data.content_id}`
            }
          >
            Ver mais...
          </Link>
        </DetailList>
      </MovieDatasheetWrapper>
      <PlaySignBtnWrapper>
        {data?.user?.premium && data?.info?.premium && (
          <a className="play" onClick={handleEnterPlay} href="" title={data?.title}>
            <img src={Play} alt="play" />
          </a>
        )}
        {!data?.user?.premium && !data?.info?.premium && (
          <a className="play" onClick={handleEnterPlay} href="" title={data?.title}>
            <img src={Play} alt="play" />
          </a>
        )}
        {data?.user?.premium && !data?.info?.premium && (
          <a className="play" onClick={handleEnterPlay} href="" title={data?.title}>
            <img src={Play} alt="play" />
          </a>
        )}
        {!data?.user?.premium && data?.info?.premium && (
          <SignPlanBtn
            onClick={async () => {
              setOpenModalPremium(true);
            }}
          >
            <p>Assine agora</p>
          </SignPlanBtn>
        )}
      </PlaySignBtnWrapper>
    </Wrapper>
  );
}

export default memo(MovieDatasheet);
