import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const WrapperLive = styled.div`
  background-color: #000;
  width: 100%;
  >div{
    width: 100%;
    height: 100%;
    iframe{ 
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  @media screen and (min-width: 998px){
    height: 100%;
  }
  @media screen and (max-width: 997px){
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    >div{
      position: absolute;
      top: 0;
      left: 0%;;
    }
  }
`; 

const Back = styled(Link)`
  padding: ${px2rem(20)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  img { width: 25px; } 

  @media screen and (min-width: 998px){
    img{ width: 100%; }
  }
`

export {
  WrapperLive,
  Back
}