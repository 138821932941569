import httpClient from '../../http';
import { Content, ContentUpdateProgress } from './models/content-interface';

const convertContents = (content: Content) => {
  return {
    ...content,
    alreadyFinished: !!content.user?.content_user?.finish_at,
    alreadyStarted: !!content.user?.content_user?.start_at,
  } as Content;
};

const getAllContents = async () => {
  const contents: Content = (await httpClient.get('/contents', {
    params: {
      is_library: true,
      is_active: true
    }
  })).data;
  return contents;
};

const getMusics = async () => {
  const contents = (
    await httpClient.get<[]>('contents/show-clips-presentations?is_active=true')
  ).data;

  return contents;
};

const getContent = async (id: string, trail?: string) => {
  let content: Content = (await httpClient.get(`/contents/${id}/${trail ? trail : ''}`)).data;

  if (content) {
    content = convertContents(content);
  }

  return content;
};

const startSingleContent = async (content_id: string, duration: number) => {
  if (!content_id) {
    return {};
  }

  const updatedContent = httpClient.post('/content-users', {
    content_id,
    duration,
  });

  return updatedContent;
};

const finishSingleContent = async (content_id: string) => {
  if (!content_id) {
    return {};
  }

  const updatedContent = httpClient.put('/content-users/finish', {
    content_id,
  });

  return updatedContent;
};

const updateProgressSingleContent = async ({ content_id, content_view }: ContentUpdateProgress) => {
  if (!content_id) {
    return {};
  }

  const updatedContent = httpClient.put('/content-users/content-view', {
    content_id,
    content_view,
  });

  return updatedContent;
};

const startTrailCourseContent = async (content_id: string, trail_id: string, course_id: string, duration: number) => {
  const startTrailCourseContent = httpClient.post('/trail-course-content-users', {
    course_id,
    content_id,
    trail_id,
    duration,
  });

  return startTrailCourseContent;
};

const finishTrailCourseContent = async (content_id: string, trail_id: string, course_id: string) => {

  const finishTrailCourseContent = httpClient.put('/trail-course-content-users/finish', {
    trail_id,
    course_id,
    content_id,
  });

  return finishTrailCourseContent;
};

const updateProgressTrailCourseContent = async (
  content_id: string,
  trail_id: string,
  course_id: string,
  content_view: number | null
) => {
  const updateTrailCourseContent = httpClient.put('/trail-course-content-users/content-view', {
    course_id,
    content_id,
    trail_id,
    content_view,
  });

  return updateTrailCourseContent;
};

const totalViewed = async (content_id: string, total_viewed: number, trail_id?: string, course_id?: string) => {
  const startTrailCourseContent = httpClient.post('/total-viewed', {
    course_id,
    content_id,
    trail_id,
    total_viewed,
  });

  return startTrailCourseContent;
};

export {
  getAllContents,
  getContent,
  updateProgressSingleContent,
  finishSingleContent,
  startSingleContent,
  startTrailCourseContent,
  finishTrailCourseContent,
  updateProgressTrailCourseContent,
  totalViewed,
  getMusics,
};
