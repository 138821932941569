import { LikeButton } from './style';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { addUserLike, createOrRemoveUserLike, removeUserLike } from 'services/user-like';
import Like from '../../../assets/icon-like.svg';
import ActiveLike from '../../../assets/icon-like-active.svg';
import { getContent } from 'services/contents';
import { getTrails } from 'services/trail';

export default function UserLike({ data, liked }: any) {
  const [listCheck, setListCheck] = useState(false);
  const [newStateLike, setNewStateLike] = useState<boolean>(liked);
  const [toList, setToList] = useState<boolean>();

  const checkValueLike = useCallback(() => {
    setNewStateLike(data?.user.like);
  }, [data?.user.like]);

  const handleLike = async (data: any) => {
    if (data?.content_id) {
      const body = {
        content_id: data?.content_id,
      };

      const content = await getContent(body.content_id);
      setNewStateLike(!content.user?.like);

      await createOrRemoveUserLike(body);
    } else {
      const body = {
        trail_id: data.trail_id,
      };

      const trail = await getTrails(body.trail_id);

      setNewStateLike(!trail.user?.like);
      await createOrRemoveUserLike(body);
    }
  };

  useEffect(() => {
    checkValueLike();
    const checkBookmark = () => {
      setListCheck(liked);
      setToList(liked);
    };
    checkBookmark();
  }, [checkValueLike, liked]);

  return (
    <LikeButton onClick={() => handleLike(data)}>
      <img src={newStateLike ? ActiveLike : Like} alt="Like" />
    </LikeButton>
  );
}
