import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';
interface ImageProps {
  image: string;
}

const ContainerImage = styled.div`
  height: 85vh;

  @media screen and (max-width: 480px) {
    height: auto;
    padding-bottom: 30px;
  }
`;

const Image = styled.div`
  position: absolute;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 85vh;

  background-image: linear-gradient(359.55deg, #1a1e21 5%, rgba(26, 30, 33, 0.3) 56%),
    url(${(props: ImageProps) => props.image});

  @media screen and (max-width: 480px) {
    background-color: #1a1e21;
    background-size: contain;
    background-position: top;
    background-image: linear-gradient(transparent 0%, #1a1e21 30%), url(${(props: ImageProps) => props.image});
  }
`;

const ContainerContent = styled.div`
  position: relative;
  width: 80vw;
  display: flex;
  align-items: center;
  padding: 25vh 30vw 10vh 10vw;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media screen and (min-width: 998px) {
    max-width: 60%;
  }
`;

const Title = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: ${px2rem(24)};
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(56)};
  }
`;

const MovieInfos = styled.span`
  color: #fff;
  font-weight: 500;
  margin: 10px 0;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }
`;

const Description = styled.p`
  color: #fff;
  font-size: ${px2rem(14)};
  line-height: ${px2rem(20)};
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
    line-height: 1.6;
  }
`;

const WrapperButtons = styled.div`
  display: flex;
  margin-top: ${px2rem(56)};

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const PlayMovie = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--default-orange);
  color: #fff;
  font-weight: 700;
  font-size: ${px2rem(16)};
  border-radius: ${px2rem(8)};
  text-transform: uppercase;
  cursor: pointer;
  margin-right: ${px2rem(20)};
  padding: ${px2rem(15)} ${px2rem(20)};

  border: none;
  font-family: 'Lato';

  img {
    margin-right: 5px;
  }

  p {
    white-space: nowrap;
  }

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }

  @media screen and (max-width: 480px) {
    margin: 0 0 15px;
  }
`;

const AddToList = styled.button`
  border: none;
  background-color: transparent;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  border: 1px solid #fff;
  border-radius: ${px2rem(8)};
  cursor: pointer;
  padding: 0 ${px2rem(20)};

  img {
    margin-right: 5px;
  }
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
    height: ${px2rem(56)};
    padding: 0 ${px2rem(25)};
  }
`;

const Items = styled.div`
  position: relative;
  background-color: #1a1e21;
  padding-bottom: ${px2rem(50)};
  @media screen and (max-width: 480px) {
    padding-bottom: 23px;
  }
`;

export {
  ContainerImage,
  Image,
  ContainerContent,
  Info,
  Title,
  MovieInfos,
  Description,
  WrapperButtons,
  PlayMovie,
  AddToList,
  Items,
};
