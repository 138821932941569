import { useEffect, useMemo, useState } from 'react';
import { WrapperLive, Back } from './style';
import ArrowBack from '../../assets/arrow-back.svg';
import { getCurrentLive } from 'services/lives';
import { useParams } from 'react-router-dom';

export default function Video() {
  const { id } = useParams<any>();
  const [activeLive, setActiveLive] = useState<any>({});

  const getActiveLive = async () => {
    const localLive = await getCurrentLive(id);
    setActiveLive(localLive);
  };

  const liveUrl = useMemo(() => {
    const playerHash = process.env.REACT_APP_PLAYER_HASH;
    const playerBaseUrl = process.env.REACT_APP_BASE_REFERENCE_URL;
    if (activeLive?.transmission_id) {
      return `${playerBaseUrl}/${playerHash}/live/${activeLive.transmission_id}`;
    }
    return `${playerBaseUrl}/${playerHash}`;
  }, []);

  useEffect(() => {
    getActiveLive();
  }, []);

  return (
    <WrapperLive>
      <Back to="/live">
        <img src={ArrowBack} alt="Voltar" />
      </Back>
      <div className="live-video-container">
        <iframe allowFullScreen={true} src={liveUrl}></iframe>
      </div>
    </WrapperLive>
  );
}
