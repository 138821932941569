import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const Items = styled.div`
  position: relative;
  background-color: #fff;
  padding-top: ${px2rem(20)};
  :first-child {
    padding-top: ${px2rem(40)};
  }
  > div h1 {
    color: var(--default-background);
  }
`;

const WrapperSlider = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 20vh;
  column-gap: 2vw;
  row-gap: 2vw;
  width: 95vw;
  margin: 0 auto;
  padding: 0 24px;
  > div {
    width: 100%;
    height: 100%;
    border-radius: ${px2rem(8)};
  }
`;

export { WrapperSlider, Items };
