import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { login as loginService } from '../services/cognito';
import { toast } from 'react-toastify';
import type { ReactNode } from 'react';
import { getMe } from '../services/user';

interface UserToken extends User {
	user: string
}
interface User {
	user_id: string;
	card_numbers: string
	card_type: string
	created_at: string
	deleted_at: string
	email: string
	is_active: boolean
	is_admin: boolean
	is_exported: boolean
	name: string
	plan: string
	roles: Array<string>
	status: string
	subscriptions_type: string
	type: string
	updated_at: string
	vindi_id: number
	vindi_payment_id: number
}

type AuthContext = {
	user: UserToken | undefined;
	login: (email: string, password: string) => Promise<void>;
	logout: () => void;
	checkUserPlanAndUpdateInfo: () => void;
}

type ProviderProps = {
	children: ReactNode;
};

export const Context = createContext({} as AuthContext)

export default function AuthProvider({ children }: ProviderProps) {
	const [user, setUser] = useState<UserToken | undefined>(() => {
		const token = localStorage.getItem('user');
		return token ? JSON.parse(token) : undefined;
	});

	const history = useHistory();

	const login = useCallback(async (email: string, password: string) => {
		try {
			const user = await loginService(email, password);
			if (user.api_token && !user.first_login) {
				localStorage.setItem('api_token', user.api_token);
				localStorage.setItem('user', JSON.stringify(user.user));
				setUser(user.user);
				history.push('/home');
			}
			if (user.first_login) {
				history.push('/register/confirm', {
					email,
				});
			}
		} catch (error: any) {
			switch (error.response.data.message.message) {
				case 'Incorrect username or password.':
					toast.error('Usuário ou senha incorretos.');
					break;
				case 'User is not confirmed.':
					history.push('/register/confirm', {
						email,
					});
					break;
				default:
					toast.error(error.response.data.message);
					break;
			}
		};
	}, [history]);

	const logout = useCallback(() => {
		setUser(undefined);
		localStorage.removeItem('api_token');
		localStorage.removeItem('user');
		history.push('/login');
	}, [])

	const checkUserPlanAndUpdateInfo = useCallback(async () => {
		const userReturned = await getMe();
		const storageUser: User = JSON.parse(localStorage.getItem('user') as string);
		 
		if(storageUser) {
			if(userReturned.plan !== storageUser.plan) {
				localStorage.setItem('user', JSON.stringify({ 
					...storageUser, 
					plan: userReturned.plan
				}));
			}
		}
	}, [])

	const value = useMemo(() => ({ user, login, logout, checkUserPlanAndUpdateInfo }), [user, login, logout, checkUserPlanAndUpdateInfo]);

	return (
		<Context.Provider value={value}>
			{children}
		</Context.Provider>
	)
}

export function useAuth() {
	const { user, login, logout, checkUserPlanAndUpdateInfo } = useContext(Context);
	return { user, login, logout, checkUserPlanAndUpdateInfo };
}