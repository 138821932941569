import { Link } from 'react-router-dom';
import { LiveCardWrapper } from './style';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Loading from 'components/Loading';
moment.locale('pt-br');

export default function LiveCard({ to = '', data }: any) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (to) {
    return (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <LiveCardWrapper>
            <Link to={to}>
              <img src={data?.thumbnail} alt="" />
              <p>{data?.name}</p>
              <p>{moment(data?.start).format('ddd, DD MMMM')}</p>
              <p>{moment(data?.start).format('LT')}</p>
            </Link>
          </LiveCardWrapper>
        )}
      </>
    );
  } else {
    return (
      <LiveCardWrapper>
        <img src={data?.images.thumbnail_Url} alt="" />
        <p>{data?.name}</p>
        <p>{moment(data?.start).format('ddd, DD MMMM')}</p>
        <p>{moment(data?.start).format('LT')}</p>
      </LiveCardWrapper>
    );
  }
}
