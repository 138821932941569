import MobileFooter from 'components/LoginFlow/Mobile Footer';
import Footer from '../components/Commons/Footer';
import Header from '../components/Commons/Header';
import LoginBlock from '../components/LoginFlow/Login';
import { Desktop, Mobile } from '../utils/responsiveRules';

export default function Login() {
  return (
    <>
      <Header hasLogin={false} />
      <LoginBlock />
      <Desktop>
        <Footer />
      </Desktop>
      <Mobile>
        <MobileFooter 
        question='Ainda não possui conta?' 
        path='/register' 
        title='Cadastre-se'
        />
      </Mobile>
    </>
  );
}
