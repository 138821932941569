import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';

interface ImageProps {
  image?: string;
}

const ContainerImage = styled.div``;

const Image = styled.div`
  position: absolute;
  top: 0;
  background-image: linear-gradient(359.55deg, #1a1e21 21.7%, rgba(26, 30, 33, 0.3) 45.05%),
    url(${(props: ImageProps) => props.image});

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: ${px2rem(250)};
  height: 100%;
  @media screen and (min-width: 998px) {
    height: ${px2rem(917)};
  }
`;

const ListContents = styled.div`
  position: relative;
  background-color: #1a1e21;
  padding-bottom: ${px2rem(300)};
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
  > div,
  a {
    width: 100%;
    height: 150px;
    max-width: 375px;
    margin: 0 auto 40px;
    img {
      width: 100%;
    }
  }

  @media screen and (min-width: 998px) {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${px2rem(25)};
    row-gap: ${px2rem(50)};
    padding: 0;
    > div,
    a {
      width: 100%;
      max-height: ${px2rem(250)};
    }
  }
`;

const TitleList = styled.h1`
  color: #fff;
  font-size: ${px2rem(24)};
  margin-bottom: ${px2rem(30)};

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(40)};
    grid-column: 1 / span 3;
    margin: 0;
  }
`;

export { ContainerImage, Image, ListContents, Grid, TitleList };
