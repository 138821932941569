import styled from "styled-components";
import ReactCodeInput from "react-verification-code-input";
import { px2rem } from "../../../utils/fontUtil";

export const InputWrapper = styled(ReactCodeInput)`
  width: auto !important;
  div {
    display: flex;
    margin-bottom: ${px2rem(50)};
    align-items: center;
    justify-content: center;
    input {
      margin-right: ${px2rem(8)};
      background-color: var(--default-background);
      border-radius: 4px;
      height: ${px2rem(40)} !important;
      width: ${px2rem(40)} !important;
      color: white;
      border: 2px solid white !important;
      font-family: "Montserrat";
      font-size: 3rem;
      caret-color: white;
      font-family: 'Lato';
      &:focus {
        border: ${px2rem(3)} solid white;
        caret-color: white;
      }
      &:not([value=""]) {
        border: ${px2rem(3)} solid var(--default-orange) !important;
        color: var(--default-orange);
      }
    }
  }

  @media screen and (min-width: 998px){
    div{
      input{
        height: ${px2rem(80)} !important;
        width: ${px2rem(64)} !important;
      }
    }
  }
`;