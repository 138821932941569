import React from 'react';
import Carousel from 'react-multi-carousel';
import { useLocation } from 'react-router';
import ArrowRight from '../../../assets/arrow-right.svg';
import ArrowLeft from '../../../assets/arrow-left.svg';

import { WrapperCarousel, CarouselTitle, RightArrow, LeftArrow } from './style';

interface Props {
  children?: React.ReactNode;
  title?: string;
}

export default function PrincipalCarousel({ children, title }: Props) {
  const { pathname } = useLocation();

  const responsive = {
    extraSuperLargeDesktop: {
      breakpoint: { max: 6000, min: 2101 },
      items: 6,
      paritialVisibilityGutter: 30,
      slidesToSlide: 6,
    },
    superLargeDesktop: {
      breakpoint: { max: 2100, min: 1801 },
      items: 5,
      paritialVisibilityGutter: 40,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1201 },
      items: 5,
      paritialVisibilityGutter: 40,
      slidesToSlide: 5,
    },
    smallDesktop: {
      breakpoint: { max: 1200, min: 901 },
      items: 4,
      paritialVisibilityGutter: 30,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 4,
      paritialVisibilityGutter: 40,
      slidesToSlide: 4,
    },
    mobile: {
      breakpoint: { max: 600, min: 325 },
      items: 3,
      paritialVisibilityGutter: 25,
    },
    smallMobile: {
      breakpoint: { max: 325, min: 0 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
  };
  const CustomLeftArrow = ({ onClick }: any) => (
    <LeftArrow isKids={pathname === '/kids'} onClick={() => onClick()}>
      <img src={ArrowLeft} width={40} height={40} alt="itens para esquerda" />
    </LeftArrow>
  );
  const CustomRightArrow = ({ onClick }: any) => (
    <RightArrow isKids={pathname === '/kids'} onClick={() => onClick()}>
      <img src={ArrowRight} width={40} height={40} alt="itens para direita" />
    </RightArrow>
  );
  return (
    <>
      <WrapperCarousel>
        <CarouselTitle>{title}</CarouselTitle>
        <Carousel
          responsive={responsive}
          itemClass="image-item"
          infinite={true}
          partialVisible
          draggable={false}
          removeArrowOnDeviceType={['tablet', 'mobile', 'smallMobile', 'smallDesktop']}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          autoPlay={false}
        >
          {children}
        </Carousel>
      </WrapperCarousel>
    </>
  );
}
