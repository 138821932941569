import httpClient from '../../http';
import { History, User, UserUpdate } from './models/user-interface';

const getMe = async () => {
  const user: User = (await httpClient.get('/users/me')).data;
  return user;
};

const getHistory = async () => {
  const data: History[] = (await httpClient.get('/users/history')).data;
  return data;
};

const updateName = async (body: UserUpdate) => {
  const data = (await httpClient.patch('/users/update-name', body)).data;
  return data;
};

const deactivateUser = async () => {
  const data = (await httpClient.patch('/users/deactivate')).data;
  return data;
};

const getDeactivateUser = async (userId: string) => {
  const data = (await httpClient.get(`/users/deactivate/${userId}`)).data;
  return data;
};

export { getMe, getHistory, updateName, deactivateUser, getDeactivateUser };
