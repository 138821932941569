import React from 'react';
import Footer from '../components/Commons/Footer';
import Header from '../components/Commons/Header';
import ChoosePlanBlock from '../components/LoginFlow/ChoosePlan';
import { Desktop } from '../utils/responsiveRules';

export default function ChoosePlan() {
  return (
    <>
      <Header hasLogin={false} />
      <ChoosePlanBlock />
      <Desktop>
        <Footer />
      </Desktop>
    </>
  );
}
