import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';

const Wrapper = styled.section`
  background-color: var(--default-background);
  padding: 120px 0 60px;
  height: 100vh;

  @media screen and (min-width: 998px) {
    padding-top: ${px2rem(155)};
  }
`;

const WithFooterWrapper = styled.div`
height: 100vh;

  @media screen and (max-width: 480px) {
    height: calc(100vh - 123px);
  }

  @media screen and (max-height: 700px) {
    height: 100vh;
  }
`

const Grid = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media screen and (min-width: 998px) {
    max-width: var(--home-grid);
    flex-direction: row;
    padding: 0 32px;
  }
`;

const ErrorWrapper = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: 14px;
  margin-top: 4px;
`;

const RightContent = styled.div`
  h4 {
    color: var(--default-orange);
    margin-top: 40px;
    font-size: ${px2rem(16)};
    text-decoration: underline;
    cursor: pointer;
  }

  a:not(.back) {
    color: var(--default-orange);
    text-decoration: underline;
    font-size: ${px2rem(16)};
    font-weight: bold;
    display: block;
    margin-bottom: ${px2rem(32)};
  }
  .back {
    display: none;
  }
  p {
    color: #fff;
  }

  @media screen and (min-width: 998px) {
    width: 350px;
    p {
      font-size: ${px2rem(16)};
      margin-top: ${px2rem(20)};
    }

    .back img {
      height: 12px;
      width: 13px;
    }

    p {
      margin-bottom: 48px;
    }
  }

  @media screen and (max-width: 480px) {
    .back {
      display: block;
      position: absolute;
      top: 60px;
      z-index: 5;
    }

    .back img {
      height: 14px;
      width: 13px;
    }

    h4 {
      display: none;
    }

    p {
      margin-bottom: 48px;
    }

    .registerLink {
      display: none;
    }

    .title {
      text-align: center;
      margin-bottom: 114px;
    }
  }
`;

const LeftContent = styled.div`

  hr {
    display: none;
  }

  @media screen and (min-width: 998px) {
    padding: ${px2rem(120)} 0 ${px2rem(170)};
    position: relative;
    hr {
      height: 100%;
      width: 2px;
      position: absolute;
      top: 0;
      border: none;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  @media screen and (max-width: 480px) {
    .forgotPasswordLink {
      margin: 12px 0 48px;
    }

    button[type="submit"] {
      width: 100%;
    }

    .loginButton:first-child {      
      font-size: 18px;
    }

    .buttons {
      margin-top: 68px;
    }
  }
`;

const FlowTitle = styled.h1`
  color: #fff;
  text-align: center;
  margin-bottom: 70px;
  br {
    display: none;
  }
  img {
    display: block;
  }
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(64)};
    margin: ${px2rem(70)} 0;
    text-align: inherit;
    br {
      display: block;
    }
  }
  @media screen and (max-width: 480px) {
    text-align: left;
    margin-bottom: 0;
  }  
`;

const Form = styled.form`
  div.one-row {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
  }

  div.default-input {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${px2rem(40)};
    label {
      font-size: ${px2rem(16)};
      color: #fff;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s linear;
    }
    input {
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      margin: 0;
      border: none;
      background-color: var(--default-background) !important;
      color: #fff;
      outline: none;
      border-bottom: 1px solid #ffffff;
      padding-bottom: ${px2rem(10)};
      &::placeholder {
        font-family: 'Lato', sans-serif;
        color: #fff;
        opacity: 0;
      }
      &:not(:placeholder-shown),
      &:focus {
        ~ label {
          transform: translate(-0.375rem, -1.25rem) scale(0.8);
        }
      }

      &.error {
        color: #ff3030;
        ~ label {
          color: #ff3030;
        }
        border-color: #ff3030;
      }
    }
    span.error {
      color: #ff3030;
      font-size: ${px2rem(14)};
      margin-top: ${px2rem(3)};
      position: absolute;
      top: 100%;
      left: 0;
    }
    button {
      background: transparent;
      margin: 0;
      border: 0;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: ${px2rem(24)};
      }
    }
    a {
      color: var(--default-orange);
      text-decoration: underline;
      font-size: ${px2rem(16)};
      font-weight: bold;
      margin-top: ${px2rem(5)};
    }
  }
  div.buttons {
    display: flex;
    justify-content: center;
    > a {
      height: 49px;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      border-radius: ${px2rem(8)};
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${px2rem(25)};
      text-decoration: none;
      padding: 0 ${px2rem(24)};
    }
    button,
    a.submit {
      height: 49px;
      font-size: 16px;
      background-color: var(--default-orange);
      border: none;
      padding: 0 ${px2rem(24)};
      border-radius: ${px2rem(8)};
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-family: 'Lato', sans-serif;
      cursor: pointer;
      margin: 0;
      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  @media screen and (min-width: 998px) {
    width: ${px2rem(486)};
    margin-left: ${px2rem(232)};
    div.default-input {
      input {
        font-size: ${px2rem(18)};
      }
      label {
        font-size: ${px2rem(18)};
      }
    }

    div.buttons {
      justify-content: flex-end;
      a,
      button,
      a.submit {
        font-size: ${px2rem(18)};
        height: ${px2rem(56)};
      }
    }
  }

  @media screen and (max-width: 480px) {
    button.submit {
      width: 100%;
    }
  }
`;

export { Wrapper, WithFooterWrapper, Grid, RightContent, LeftContent, FlowTitle, Form, ErrorWrapper };
