import httpClient from '../../http';
import { Trail } from './models/trail-interface';
import { Course } from 'services/courses/models/course-interface';
import { Content } from 'services/contents/models/content-interface';

const convertTrail = (trail: Trail) => {
  return {
    ...trail,
    alreadyStarted: !!trail.user?.trail_course_user.start_at,
    alreadyFinish: !!trail.user?.trail_course_user.finish_at,
    courses:
      trail.courses && trail.courses.length ? convertCourses(trail.courses, trail, trail.user?.trail_course_user) : [],
  } as Trail;
};

const convertCourses = (courses: Array<Course>, trail: Trail, user?: any) => {
  return courses.map((course) => {
    let courseUser;

    if (user) {
      courseUser = user?.courses?.find((course: any) => course.content_id === course.course_id);
    }

    return {
      ...course,
      alreadyFinish: !!courseUser?.start_at,
      alreadyStarted: !!courseUser?.finish_at,
      contents: convertContents(course?.contents, user, course, trail),
    } as Course;
  });
};

const convertContents = (contents?: Content[], moduleUser?: any, course?: Course, trail?: Trail) => {
  return (
    contents &&
    contents.map((content, index, self) => {
      let contentUser: any | undefined;
      const actualContent = self[index];

      if (moduleUser && moduleUser.contents) {
        contentUser = moduleUser.contents.find(
          (contentUser: Content) => contentUser.content_id === actualContent?.content_id
        );
      }

      return {
        ...content,
        trail_id: trail && trail.trail_id ? trail.trail_id : '',
        course_id: course && course.course_id ? course.course_id : '',
        isFirstOfCourse:
          course && course.contents && course.contents.length
            ? course.contents[0].content_id === content.content_id
            : false,
        isLastOfCourse:
          course && course.contents && course.contents.length
            ? course.contents[course.contents.length - 1].content_id === content.content_id
            : false,
        alreadyStarted: !!moduleUser?.start_at,
        alreadyFinished: !!contentUser?.finish_at,
        content_view: contentUser?.content_view || null,
      } as Content;
    })
  );
};

const getAllTrails = async () => {
  const trails: Trail = (await httpClient.get('/trails', {
    params: {
      is_active: true
    }
  })).data;
  return trails;
};

const getAlbums = async () => {
  const courses = (
    await httpClient.get<any>('trails/albums?is_active=true')
  ).data;

  return courses;
};

const getTrails = async (id: string) => {
  let trail: Trail = (await httpClient.get(`/trails/${id}`)).data;

  if (trail) {
    trail = convertTrail(trail);
  }

  return trail;
};

const startTrail = async (trailId: string) => {
  const trailResponse = (
    await httpClient.post('/trail-users', {
      trail_id: trailId,
    })
  ).data;

  return trailResponse;
};

const finishTrail = async (trailId: string) => {
  const trailResponse = (
    await httpClient.put('/trail-users/finish', {
      trail_id: trailId,
    })
  ).data;

  return trailResponse;
};

export { getAllTrails, getTrails, startTrail, finishTrail, getAlbums };
