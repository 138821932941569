import { AuthorWrapper, Overlay } from './style';
import AuthorPlaceholder from '../../../assets/author-1.jpg';
import IconInfo from '../../../assets/icon-info.svg';
import { Link } from 'react-router-dom';
import LazyImage from '../../Commons/LazyImage';

export default function CardAuthor({ data, animation = true }: any) {
  return (
    <AuthorWrapper animation={animation}>
      <Link to={`/author/${data.author_id}`}>
        {data?.avatar_url ? (
          <LazyImage src={data?.avatar_url} alt="" />
        ) : (
          <LazyImage src={AuthorPlaceholder} alt="" />
        )}
        <Overlay>
          <img src={IconInfo} alt="" />
          <p>{data.name}</p>
        </Overlay>
      </Link>
    </AuthorWrapper>
  )
}