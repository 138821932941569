import Footer from '../../components/Commons/Footer';
import HeaderKids from '../../components/Kids/HeaderKids';
import { Results, NoResultsWrapper, SearchedTerms, Grid, WrapperMobile, SearchInput } from './style';
import NotFound from '../../assets/icon-notfound.svg';
import MovieCard from '../../components/Movies/MovieCard';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { getSearch } from '../../services/search';
import { Mobile } from 'utils/responsiveRules';
import SearchIcon from '../../assets/icon-search.svg';
import { Link, useHistory } from 'react-router-dom';
import { Spacer } from 'components/Commons/MobileNavigatorSpace/style';

const NoResults = ({ term }: any) => (
  <NoResultsWrapper>
    <div>
      <img src={NotFound} alt="" />
    </div>
    <p>
      Não encontramos nenhum resultado para <strong>{term}</strong>
    </p>
    <small>Tente usar outras palavras-chave ou navegue pelo catálogo.</small>
    <Link to="/kids">
      <span>voltar para catálogo infantil</span>
    </Link>
  </NoResultsWrapper>
);

export default function KidsSearch() {
  const { term } = useParams<any>();
  const [searchTerm, setSearchedTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any>();
  const history = useHistory();

  const getData = async (term: string) => {
    const a = await getSearch(term);
    setSearchResults(a);
  };

  useEffect(() => {
    getData(term);
  }, [term]);
  const handleSearch = (e: any) => {
    e.preventDefault();
    if (searchTerm) history.replace(`/kids-search/${searchTerm}`);
  };

  const noContent = searchResults?.trailsFull?.length <= 0 && searchResults?.contentFull?.length <= 0;
  return (
    <div>
      <HeaderKids />
      <Mobile>
        <WrapperMobile>
          <h1>Busca</h1>
          <SearchInput onSubmit={(e) => handleSearch(e)}>
            <img src={SearchIcon} alt="" />
            <input
              type="text"
              placeholder="Busca"
              value={searchTerm}
              onChange={(e) => setSearchedTerm(e.target.value)}
            />
          </SearchInput>
        </WrapperMobile>
      </Mobile>
      {!noContent && (
        <Results>
          <SearchedTerms>
            <p>
              Encontramos resultados para<strong> {term} </strong>
            </p>
            {/*<small>Veja também: <a href=""> PE. Fábio de Melo</a> | <a>Missa de Páscoa</a></small>*/}
          </SearchedTerms>
          <Grid>
            {searchResults?.trailsFull?.map((content: any, key: any) => (
              <MovieCard to={`/kids-trail/${content?.trail_id}`} key={`trail-${key}`} data={content} />
            ))}
            {searchResults?.contentsFull?.map((content: any, key: any) => (
              <MovieCard to={`/kids-movie/${content?.content_id}`} key={`content-${key}`} data={content} />
            ))}
          </Grid>
        </Results>
      )}
      {noContent && <NoResults term={term} />}

      <Footer />
      <Spacer />
    </div>
  );
}
