import { Link } from 'react-router-dom';
import cleanStorage from 'utils/cleanLocalStorage';
import logo from '../../../assets/logo.svg';
import { HeaderWrapper } from './style';

interface Props {
  hasLogin?: boolean;
}
export default function Header({ hasLogin = true }: Props) {
  const handleClearTokens = () => {
    cleanStorage();
  }

  return (

    <HeaderWrapper className={hasLogin ? 'hasLogin' : ''}>
      <img src={logo} alt="TEOMídia" />
      {hasLogin && <Link to="/login" onClick={handleClearTokens} >ENTRAR </Link>}
    </HeaderWrapper>
  )
}