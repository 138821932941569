import Footer from '../components/Commons/Footer';
import Header from '../components/Commons/Header';
import ConfirmCode from '../components/LoginFlow/ConfirmCode';
import { Desktop } from '../utils/responsiveRules';

export default function ConfirmSignUp() {
  return (
    <>
      <Header hasLogin={false} />
      <ConfirmCode />
      <Desktop>
        <Footer />
      </Desktop>
    </>
  )
}