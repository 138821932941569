import styled from 'styled-components';
import { px2rem } from 'utils/fontUtil';

const Wrapper = styled.div`
  background-color: var(--default-background);
  min-height: 100%;

  .back {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .back {
      display: block;
      position: absolute;
      top: 100px;
      left: 26px;
      z-index: 5;
    }

    .back img {
      height: 12px;
      width: 13px;
    }
  }
`;
const Body = styled.div`
  padding: ${px2rem(190)} 24px;
  color: #fff;
  @media screen and (min-width: 998px) {
    width: 80vw;
    margin: 0 auto;
  }
  p,
  span {
    color: #fff !important;
    margin-top: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-weight: 700;
  color: #fff;
  font-size: ${px2rem(18)};
  line-height: ${px2rem(22)};
  margin: ${px2rem(15)} 0;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(24)};
    line-height: ${px2rem(24)};
  }
`;

const Paragraph = styled.p`
  font-weight: 400;
  color: #fff;
  font-size: ${px2rem(14)};
  margin-top: 10px;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }
`;

export { Wrapper, Body, SectionTitle, Paragraph };
