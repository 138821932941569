import httpClient from '../../http';
import { Author } from './models/author-interface';


const getReputedAuthors = async () => {
  const authors: Array<Author> = (await httpClient.get('/authors', { params: { is_reputed: true, is_active: true } })).data;
  return authors;
};

const getAllAuthors = async () => {
  const authors: Array<Author> = (await httpClient.get('/authors', {
    params: { is_active: true }
  })).data;
  return authors;
};


const getAuthor = async (id: string) => {
  const author: Author = (await httpClient.get(`/authors/${id}`)).data;
  return author;
}

export {
  getReputedAuthors,
  getAllAuthors,
  getAuthor
}