import axios from 'axios';
import { history } from '../App'

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
});

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('api_token');
      localStorage.removeItem('user');
      window.location.href = '/login'
    }
    return Promise.reject(error);
  }
);

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('api_token') || localStorage.getItem('payment-token-teo');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default httpClient;
