import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  width: 85%;
  margin: 0 auto;
  padding: 0;
  a img {
    width: ${px2rem(50)};
  }

  @media screen and (min-width: 998px) {
    a img {
      width: ${px2rem(100)};
    }
  }
`;

const PlaySignBtnWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  padding-top: 100px;
`;

const MovieDatasheetWrapper = styled.div`
  padding-top: ${px2rem(10)};
  width: 50%;
`;

const MovieTitle = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(48)};
    margin-bottom: ${px2rem(10)};
  }
`;

const MovieResume = styled.span`
  color: #fff;
  font-size: 12px;
  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }
`;

const DetailList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: ${px2rem(40)};

  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const DetailItem = styled.li`
  color: #fff;
  margin-bottom: ${px2rem(10)};
  font-size: 16px;

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(18)};
  }
`;

const DetailTitle = styled.strong``;

export {
  Wrapper,
  PlaySignBtnWrapper,
  MovieDatasheetWrapper,
  MovieTitle,
  MovieResume,
  DetailList,
  DetailItem,
  DetailTitle,
};
