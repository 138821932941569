import httpClient from '../../http';

const getAllLives = async () => {
  const lives = (await httpClient.get('/lives')).data;

  if (!lives) return null;
  return lives;
};

const getCurrentLives = async () => {
  const lives = (await httpClient.get('/lives/in-live')).data;
  if (!lives) return null;
  return lives;
};

const getNextLives = async () => {
  const lives = (await httpClient.get('/lives/next')).data;
  if (!lives) return null;
  return lives;
};

const getCurrentLive = async (id: string) => {
  const lives = (await httpClient.get(`/lives/${id}`)).data;
  const response = lives;
  if (!lives) return null;
  return response;
};

const getExternalLive = async (id: string) => {
  const externalLive = (await httpClient.get(`/external-lives/${id}`)).data;
  const response = externalLive;
  if (!externalLive) return null;
  return response;
};

export { getAllLives, getCurrentLives, getNextLives, getCurrentLive, getExternalLive };
