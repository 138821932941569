import KidsSlider from 'components/Home/KidsSlider';
import { useEffect } from 'react';
import { useState } from 'react';
import { ContentsProps } from 'services/contents/models/content-interface';
import { getHomeCategoriesKids } from 'services/home';

export default function HomeCategoriesKids() {
  const [contents, setContents] = useState<ContentsProps[]>([]);
  useEffect(() => {
    const getData = async () => {
      const content = await getHomeCategoriesKids();
      setContents(content);
    };
    getData();
  }, []);

  return (
    <>
      {contents &&
        contents?.map((category, key) => (
          <KidsSlider title={category?.category} data={category?.contents} key={`category-hom-${key}`} />
        ))}
    </>
  );
}
