import Devices from '../../../assets/devices.png';
import PlayStore from '../../../assets/play-store.png';
import AppStore from '../../../assets/app-store.png';

import { AppWrapper } from './style';

export default function Apps() {
  return (
    <AppWrapper>
      <div>
        <img src={Devices} alt="" />
        <div>
          <h3>Assista no notebook, smartphone ou na TV com o Chromecast.</h3>
          <div className="buttons">
            <a
              href="https://play.google.com/store/apps/details?id=br.com.comev.teomidia"
              target="_blank"
              rel="noreferrer"
              title="Logo Google Play"
            >
              <img src={PlayStore} alt="PlayStore" />
            </a>
            <a
              href="https://apps.apple.com/us/app/teom%C3%ADdia/id1261676036"
              target="_blank"
              rel="noreferrer"
              title="Logo Apple Store"
            >
              <img src={AppStore} alt="AppStore" />
            </a>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
}
