import { useState } from 'react';
import Image from '../../../assets/card.jpg';
import MobileImage from '../../../assets/rising-1.jpg';
import IconPlus from '../../../assets/icon-plus.svg';
import { removeFromBookmark } from '../../../services/bookmarks';
import {
  Card,
  CardLink,
  CardImage,
  MovieDuration,
  MovieInfos,
  MovieTitle,
  MovieDescription,
  MovieSeeMore,
  Progress,
  ProgressIndicator,
  RemoveFromList,
} from './style';
import LazyImage from '../../Commons/LazyImage';
import IconInfo from '../../../assets/icon-info.svg';
import { Desktop, Mobile } from 'utils/responsiveRules';

export default function MovieCard({
  info = false,
  progress = false,
  to,
  data,
  onClick,
  myList = false,
  id,
  hover = false,
  isOnMyListPage = false,
  bookmarks,
  setBookmarks,
  isKids = false,
  trailThumbnail = undefined,
}: any) {
  const [removedFromList, setRemoved] = useState<boolean>();

  const handleRemoveFromUserList = async (e: any) => {
    e.preventDefault();
    setRemoved(true);
    const updatedList = bookmarks.filter((item: any) =>
      item.content_id ? item.content_id !== id : item.trail_id !== id
    );
    setBookmarks(updatedList);
    if (data.is_library) await removeFromBookmark(data.content_id, data.is_library);
    else await removeFromBookmark(data.trail_id);
  };

  const CardContent = ({ data }: any) => {
    const hour = `${Math.floor(data.duration / (60 * 60)).toFixed(0)}`;
    const min = `${Math.floor((data.duration / 60) % 60).toFixed(0)}`;
    let time: string;

    if ((hour === '1' && min === '0') || (hour === '0' && min === '60')) {
      time = '1hr';
    } else if (hour === '0') {
      const splitMinutes = min.split('.');
      splitMinutes[1] === '00' ? (time = min) : (time = `${splitMinutes[0]} min`);
    } else {
      time = `${hour}h ${min} min`;
    }
    return (
      <>
        {hover && (
          <Desktop>
            <div className="hover">
              <img src={IconInfo} alt={IconInfo} />
            </div>
          </Desktop>
        )}
        <Desktop>
          <CardImage>
            {data?.thumbnail ? (
              <LazyImage src={data?.thumbnail} alt={data?.name} />
            ) : (
              <LazyImage src={Image} alt={data?.name} />
            )}
          </CardImage>
          {progress && (
            <Progress>
              <ProgressIndicator />
            </Progress>
          )}
          {info && (
            <MovieInfos>
              <MovieTitle isKids={isKids}> {(data && data.title) || data.name}</MovieTitle>
              <MovieDuration isKids={isKids}>{time}</MovieDuration>
              <MovieDescription title={data?.description} isKids={isKids}>
                {data?.description}
              </MovieDescription>
              <MovieSeeMore 
                to={isKids 
                    ? data?.content_id 
                      ? `/kids-movie/${data?.content_id}/${trailThumbnail ? trailThumbnail : ''}` 
                      : `/kids-trail/${data?.trail_id}`
                    : data?.content_id 
                      ? `/movie/${data?.content_id}/${trailThumbnail ? trailThumbnail : ''}` 
                      : `/trail/${data?.trail_id}` 
                  }
              >
                Ver mais
              </MovieSeeMore>
            </MovieInfos>
          )}
        </Desktop>

        <Mobile>
          <CardImage>
            {isOnMyListPage &&
              (data?.thumbnail_mobile ? (
                <LazyImage src={data?.thumbnail_mobile} alt={data?.name} />
              ) : (
                <LazyImage src={MobileImage} alt={data?.name} />
              ))}

            {!isOnMyListPage &&
              (data?.thumbnail_mobile ? (
                <LazyImage src={data?.thumbnail_mobile} alt={data?.name} />
              ) : (
                <LazyImage src={MobileImage} alt={data?.name} />
              ))}
          </CardImage>
          {progress && (
            <Progress>
              <ProgressIndicator />
            </Progress>
          )}
          {info && (
            <MovieInfos>
              <MovieTitle>{(data && data.title) || data.name}</MovieTitle>
              <MovieDuration>{time}</MovieDuration>
              <MovieDescription> {data?.description}</MovieDescription>
              <MovieSeeMore 
                to={isKids 
                    ? data?.content_id 
                      ? `/kids-movie/${data.content_id}/${trailThumbnail ? trailThumbnail : ''}` 
                      : `/kids-trail/${data.trail_id}`
                    : data?.content_id 
                      ? `/movie/${data.content_id}/${trailThumbnail ? trailThumbnail : ''}` 
                      : `/trail/${data.trail_id}`
                  }
              >
                Ver mais
              </MovieSeeMore>
            </MovieInfos>
          )}
        </Mobile>
      </>
    );
  };

  if (removedFromList) {
    return null;
  }

  return (
    <>
      {onClick && (
        <Card
          onClick={() => {
            onClick();
          }}
        >
          <CardContent data={data} />
        </Card>
      )}
      {to && (
        <CardLink title={(data && data.title) || data.name} to={to}>
          {myList && (
            <RemoveFromList onClick={(e) => handleRemoveFromUserList(e)}>
              <img src={IconPlus} alt="" />
            </RemoveFromList>
          )}
          <CardContent data={data} />
        </CardLink>
      )}
    </>
  );
}
