import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 998px) {
    margin-left: ${px2rem(115)};
    flex-direction: row;
  }
`;

const Plan = styled.div`
  background-color: #000;
  padding: ${px2rem(36)};
  border-radius: ${px2rem(8)};
  align-self: flex-start;
  margin: 20px auto 20px;

  &:nth-of-type(1) {
    background-color: #ebebeb;
    color: #000;
    .container .checkmark {
      border-color: #000;
      &::after {
        background-color: #000;
      }
    }
  }
    color: #fff;
    .container .checkmark {
      border-color: #fff;
      &::after {
        background-color: #fff;
      }
    }

  @media screen and (min-width: 998px) {
    width: ${px2rem(335)};
    margin: 0 0 25px 0;
    &:first-of-type {
      margin: 0 ${px2rem(16)} 0 0;
    }
  }

  .container {
    width: 40px;
    height: 40px;
    position: relative;
    display: block;
    margin: ${px2rem(15)} 0 ${px2rem(35)};
    cursor: pointer;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${px2rem(40)};
    width: ${px2rem(40)};
    border-radius: 50%;
    border: 1px solid var(--default-orange);
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${px2rem(28)};
    height: ${px2rem(28)};
    border-radius: 50%;
    background: var(--default-orange);
  }
`;

const PlanName = styled.p`
  font-size: ${px2rem(20)};
`;

const PlanPrice = styled.strong`
  font-size: ${px2rem(36)};
  margin: ${px2rem(18)} 0 ${px2rem(50)};
  display: block;
`;

const PlanBenefits = styled.ul`
  list-style: none;
  li {
    font-size: ${px2rem(16)};
    margin-bottom: ${px2rem(15)};
    img {
      margin-right: ${px2rem(15)};
    }
  }
`;

export { PlansWrapper, Plan, PlanName, PlanPrice, PlanBenefits };
