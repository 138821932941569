import { useHistory } from 'react-router-dom';
import {
  MovieWrapper,
  Details,
  MovieTitle,
  MovieInfos,
  MovieDescription,
  WrapperButtons,
  PlayMovie,
  Sign,
} from './style';
import ArrowBack from '../../../assets/icon-arrow-back.svg';
import IconPlay from '../../../assets/icon-play.svg';
import AddToBookmarks from 'components/Commons/AddToBookmarks';
import marked from 'marked';
import { useContext, useEffect, useMemo } from 'react';
import { ContextBePremiumModal } from 'utils/context';
import { Context as AuthContext } from '../../../contexts/AuthContext';

export default function MovieHeader({ data }: any) {
  const hour = `${Math.floor(data?.duration / (60 * 60)).toFixed(0)}`;
  const min = `${Math.floor((data?.duration / 60) % 60).toFixed(0)}`;
  let time: string;

  if ((hour === '1' && min === '0') || (hour === '0' && min === '60')) {
    time = '1hr';
  } else if (hour === '0') {
    const splitMinutes = min.split('.');
    splitMinutes[1] === '00' ? (time = min) : (time = `${splitMinutes[0]} min`);
  } else {
    time = `${hour} h ${min} min`;
  }

  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user') as string);
  const isKids = localStorage.getItem('kids');
  const { setOpenModalPremium } = useContext(ContextBePremiumModal);
  const { checkUserPlanAndUpdateInfo } = useContext(AuthContext);

  const backLocation = () => {
    const tag = data.tag;

    const actualLocation = window.location.pathname.split('/')[1];
    if (actualLocation === 'kids-movie') history.push('/kids');

    if (actualLocation !== 'kids-movie') {
      if (tag === 'filmes') history.push('/movies');
      if (tag === 'series') history.push('/series');
      if (tag === 'documentarios') history.push('/documentary');
      if (tag === 'cursos') history.push('/courses');
      if (tag === 'palestras') history.push('/lectures');
      if (tag === 'sermoes') history.push('/sermon');
      if (tag === 'musicas') history.push('/music');
    }
  };

  const linkPlayer = data?.content_id
    ? `/player/${data?.content_id}`
    : `/show/${data?.trail_id}/season/${data?.courses[0]?.course_id}/episode/${data?.courses[0]?.contents[0]?.content_id}`;

  const signPlan = () => {
    history.push('/profile', {
      renewPlan: true,
    });
  };
  marked.setOptions({
    gfm: true,
    breaks: false,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
  });

  const userHasPremiumPlan = useMemo(() => {
    const storageUser = JSON.parse(localStorage.getItem('user') as string);

    if(storageUser?.plan === 'premium') {
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    checkUserPlanAndUpdateInfo();
  }, [checkUserPlanAndUpdateInfo]);

  return (
    <MovieWrapper>
      <Details>
        <button onClick={() => history.goBack()} type="button" title="Voltar">
          <img src={ArrowBack} alt="Voltar" />
        </button>
        <MovieTitle>{data?.title || data?.name}</MovieTitle>
        <MovieInfos>
          {data?.info?.year && `${data?.info?.year} - `}
          {data?.duration && time}
        </MovieInfos>
        {data?.description && <MovieDescription dangerouslySetInnerHTML={{ __html: marked(data?.description) }} />}
        {/* {data?.authors && <MovieDescription>Autor: {data?.authors.map((author: any) => author.name)}</MovieDescription>} */}
        {data?.info?.original_title && (
          <MovieDescription>Título Original: {data?.info?.original_title}</MovieDescription>
        )}
        {data?.info?.language && <MovieDescription>Idioma: {data?.info?.language}</MovieDescription>}
        {data?.info?.director && <MovieDescription>Direção: {data?.info?.director}</MovieDescription>}
        {data?.producer?.name && <MovieDescription>Produção: {data?.producer?.name}</MovieDescription>}
        {data?.info?.cast && <MovieDescription>Elenco: {data?.info?.cast}</MovieDescription>}
        {data?.info?.parental_rating && (
          <MovieDescription>Classificação Indicativa: {data?.info?.parental_rating}</MovieDescription>
        )}
        <WrapperButtons>
          {userHasPremiumPlan && (
            <PlayMovie to={linkPlayer} title="Assistir">
              <img src={IconPlay} alt="Play" />
              <p>Assistir</p>
            </PlayMovie>
          )}

          {!userHasPremiumPlan && !data?.info?.premium && (
            <PlayMovie to={linkPlayer} title="Assistir">
              <img src={IconPlay} alt="Play" />
              <p>Assistir</p>
            </PlayMovie>
          )}

          {!userHasPremiumPlan && data?.info?.premium && (
            <Sign
              onClick={async () => {
                setOpenModalPremium(true);
              }}
            >
              Assine agora
            </Sign>
          )}
          <AddToBookmarks data={data} bookmark={data?.user?.bookmark} />
        </WrapperButtons>
      </Details>
    </MovieWrapper>
  );
}
