import { useState } from 'react';
import { NavItem, WrapperSubMenu, SubMenuHover } from './style';

export default function Submenu({ children, title }: any) {
  const [showSubMenu, setShowSubMenu] = useState<boolean>();

  return (
    <NavItem onMouseOver={() => setShowSubMenu(true)}
      onMouseOut={() => setShowSubMenu(false)}>
      <p>{title}</p>

      <WrapperSubMenu>
        <SubMenuHover className={showSubMenu ? 'show' : ''}>
          {children}
        </SubMenuHover>
      </WrapperSubMenu>
    </NavItem>

  )
}