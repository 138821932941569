import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PlayerSambaVideos from 'components/PlayerSambaVideos';
import { Content } from 'services/contents/models/content-interface';
import { Trail } from 'services/trail/models/trail-interface';
import { Course } from 'services/courses/models/course-interface';
import {
  updateProgressTrailCourseContent as updateProgressTrailCourseContentService,
  finishSingleContent as finishSingleContentService,
  startTrailCourseContent as startTrailCourseContentService,
  finishTrailCourseContent,
  totalViewed as totalViewedService,
} from 'services/contents';
import { startCourse as startCourseService, finishCourse as finishCourseService } from 'services/courses';
import { getTrails, startTrail as startTrailService, finishTrail as finishTrailService } from 'services/trail';
import KeepWatchingCard from 'components/KeepWatchingCard';

import { ContainerButton, PlayerContentContainer, ReturnToDetailPage } from './style';
import BackArrow from 'assets/icons/arrow-back-black.svg';
import useTotalViewd from 'hooks/useTotalViewd';
import { setTimeout } from 'timers';

interface IPlayerParams {
  trailId: string;
  courseId: string;
  lessonId: string;
}

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface ExtendedWindow extends Window {
  hasStartedContent: boolean;
  hasStartedModule: boolean;
  hasStartedTrail: boolean;
  hasFinishedContent: boolean;
  hasFinishedModule: boolean;
  hasFinishedTrail: boolean;
}

declare let window: ExtendedWindow;

const PlayerContent: React.FC = () => {
  const { courseId, lessonId, trailId } = useParams() as IPlayerParams;
  const history = useHistory();

  const [contentUrl, setContentUrl] = useState<string>('');
  const [content, setContent] = useState<Content>();
  const [trail, setTrail] = useState<Trail>();
  const [course, setCourse] = useState<Course>();
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [showNextCard, setShowNextCard] = useState<boolean>(false);
  const [shouldShowPlayer, setShouldShowPlayer] = useState<boolean>(false);
  const [nextContent, setNextContent] = useState<Content | null>();
  const [hideBackButton, setHideBackButton] = useState(false);
  const [contentView, setContentView] = useState<string>();
  const [timesUp, setTimesUp] = useState(false);

  const { addContentId, addCourseId, addTrailId, checkView, tick, removeLocalStorageTime } = useTotalViewd(lessonId);

  const getNextLessonToShowCard = useCallback(
    (courses: Course[]) => {
      const allContents = courses
        .sort((a: { position: number }, b: { position: number }) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        })
        .map((course) =>
          course.contents.sort((a: { position: number }, b: { position: number }) => {
            if (a.position < b.position) {
              return -1;
            }
            if (a.position > b.position) {
              return 1;
            }
            return 0;
          })
        )
        .flat();

      const actualContent = allContents.find(
        (insideContent) =>
          insideContent?.content_id === lessonId &&
          insideContent.course_id === courseId &&
          insideContent.trail_id === trailId
      );

      if (actualContent) {
        const indexOfActualContent = allContents.indexOf(actualContent);

        if (indexOfActualContent > -1 && indexOfActualContent < allContents.length - 1) {
          const nextContent = allContents[indexOfActualContent + 1];
          setNextContent(nextContent);
        } else {
          setNextContent(null);
        }
      }
    },
    [lessonId, courseId, trailId]
  );

  const getActualContent = useCallback(async () => {
    const localTrail = await getTrails(trailId);
    const localCourse = localTrail?.courses.find((course) => course.course_id === courseId);
    const localContent = localCourse?.contents.find((content) => content.content_id === lessonId);
    const localContenView = localTrail?.user?.trail_course_user.find(
      (trail: any) => trail.content_id === localContent?.content_id
    );

    if (localTrail && localTrail.trail_id) {
      getNextLessonToShowCard(localTrail.courses);

      setContent(localContent);
      setTrail(localTrail);
      setCourse(localCourse);
      setContentView(localContenView ? localContenView.content_view : '0.00');
    }
  }, [trailId, courseId, lessonId, getNextLessonToShowCard]);

  useEffect(() => {
    window.hasStartedContent = false;
    window.hasFinishedContent = false;

    addContentId(lessonId);
    addCourseId(courseId);
    addTrailId(trailId);

    getActualContent();
  }, [getActualContent]);

  const resumeTime = useMemo(() => {
    if (!contentView) {
      return false;
    }
    if (contentView) {
      setLastWatchedTime(Number(contentView));
      return Number(contentView);
    }

    return false;
  }, [contentView]);

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateProgressTrailCourseContentService(lessonId, trailId, courseId, time);
    },
    [lessonId, courseId, trailId]
  );

  const updateWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setProgress(eventParam);
    }
    return null;
  }, []);

  const startTrail = async () => {
    if (trail) {
      try {
        trail.alreadyStarted = true;
        window.hasStartedTrail = true;

        await startTrailService(trailId || '');

        setTrail({ ...trail });
      } catch (error) {
        trail.alreadyStarted = false;
        window.hasStartedTrail = false;
      }
    }
  };

  const finishContent = async () => {
    if (content && trail && course) {
      if (!window.hasFinishedContent && !content.alreadyFinished) {
        try {
          window.hasFinishedContent = true;
          content.alreadyFinished = true;

          await finishTrailCourseContent(content.content_id, trail.trail_id, course.course_id);
        } catch (error: any | unknown) {
          window.hasFinishedContent = false;
          content.alreadyFinished = false;
        }
      }

      const foundCourse = trail?.courses?.find((course) => course.course_id === courseId);

      if (foundCourse) {
        if (
          foundCourse.contents?.every((content) => content.alreadyFinished) &&
          !foundCourse.alreadyFinish &&
          !window.hasFinishedModule
        ) {
          try {
            window.hasFinishedModule = true;
            foundCourse.alreadyFinish = true;

            await finishCourseService(course?.course_id, trail?.trail_id);
          } catch (error: any | unknown) {
            window.hasFinishedModule = false;
            foundCourse.alreadyFinish = false;
          }
        }
      }

      if (trail.courses?.every((course) => course.alreadyFinish) && !trail.alreadyFinish && !window.hasFinishedTrail) {
        try {
          trail.alreadyFinish = true;
          window.hasFinishedTrail = true;

          await finishTrailService(trail.trail_id);
        } catch (error: any | unknown) {
          trail.alreadyFinish = false;
          window.hasFinishedTrail = false;
        }
      }
    }
  };

  const startContent = async () => {
    if (content && trail && course) {
      if (!window.hasStartedTrail && !trail.alreadyStarted) {
        try {
          window.hasStartedTrail = true;
          trail.alreadyStarted = true;

          await startTrail();
        } catch {
          window.hasStartedContent = false;
          content.alreadyStarted = false;
        }

        if (!window.hasStartedModule) {
          try {
            window.hasStartedModule = true;

            await startCourseService(course?.course_id, trail.trail_id);
          } catch (error) {
            window.hasStartedModule = false;
          }
        }

        if (!window.hasFinishedContent && !content.alreadyStarted) {
          try {
            window.hasFinishedContent = false;
            content.alreadyStarted = true;

            await startTrailCourseContentService(
              content.content_id,
              trail.trail_id,
              course.course_id,
              Number(content.duration)
            );
          } catch (error) {
            window.hasFinishedContent = false;
            content.alreadyStarted = false;
          }
        }
      }

      setTrail({ ...trail });
    }
  };

  const goToNextContent = useCallback(() => {
    if (trail && content) {
      const allContents =
        trail.courses && trail.courses.length
          ? trail.courses
              .sort((a: { position: number }, b: { position: number }) => {
                if (a.position < b.position) {
                  return -1;
                }
                if (a.position > b.position) {
                  return 1;
                }
                return 0;
              })
              .map((course) =>
                course.contents?.sort((a: { position: number }, b: { position: number }) => {
                  if (a.position < b.position) {
                    return -1;
                  }
                  if (a.position > b.position) {
                    return 1;
                  }
                  return 0;
                })
              )
              .flat()
          : [];

      const actualContent = allContents.find(
        (insideContent) =>
          insideContent?.content_id === content?.content_id &&
          insideContent.course_id === content.course_id &&
          insideContent.trail_id === content.trail_id
      );

      if (actualContent) {
        const indexOfActualContent = allContents.indexOf(actualContent);

        if (indexOfActualContent > -1 && indexOfActualContent < allContents.length - 1) {
          const nextContent = allContents[indexOfActualContent + 1];

          history.push(
            `/show/${nextContent?.trail_id}/season/${nextContent?.course_id}/episode/${nextContent?.content_id}`
          );
        }
      }
    }
  }, [trail, content, history]);

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const numberCurrentTime = Math.floor(Number(currentTime.toFixed(0)));
    return numberCurrentTime === duration - 15;
  };

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }

    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [lastWatchedTime, progress, updateContentProgress]);

  let finishCallIntervalId: NodeJS.Timer;

  const callFinish = useCallback(async () => {
    await finishContent();
  }, [showNextCard]);

  useEffect(() => {
    if ((showNextCard && nextContent) || (nextContent === null && showNextCard)) {
      callFinish();
    }
  }, [callFinish, nextContent, showNextCard]);

  const getEventListeners = async (player: PlayerEventListener) => {
    if (window.location.href.endsWith(`/show/${trailId}/season/${courseId}/episode/${lessonId}`)) {
      if (content && content.content_id) {
        switch (player.event) {
          case 'onProgress':
            tick(player.event);
            if (hasReachedCompletionTime(player.duration, player.eventParam)) {
              setShowNextCard(true);
            }

            updateWatchTime(player);
            break;

          case 'onStart':
            startContent();
            break;

          case 'onPause':
            tick(player.event);
            break;

          case 'onFinish':
            tick(player.event);
            removeLocalStorageTime();
            goToNextContent();
            break;

          default:
            break;
        }
      }
    }
  };

  const handleReturnToDetails = () => {
    const isKids = localStorage.getItem('kids');

    removeLocalStorageTime();

    if (isKids) {
      history.push(`/kids-trail/${trailId}`);
    } else {
      history.push(`/trail/${trailId}`);
    }
  };

  useEffect(() => {
    setShouldShowPlayer(false);

    process.nextTick(() => {
      let localContentUrl = contentUrl;
      const validUrlStart = 'https://fast.player.liquidplatform.com/pApiv2/embed/';
      const isValidUrl = localContentUrl && localContentUrl.startsWith(validUrlStart);

      if (isValidUrl) {
        setShouldShowPlayer(true);
      }
    });
  }, [contentUrl, content?.content_id]);

  useEffect(() => {
    if (content !== undefined) {
      setContentUrl(content?.reference);
    }
  }, [content]);

  const getSelectedContent = useCallback(() => {
    if (trail) {
      const allContents =
        trail.courses && trail.courses.length ? trail.courses.map((course) => course.contents || []).flat() : [];
      const foundContent = allContents.find(
        (content) => content.content_id === lessonId && content.course_id === courseId && content.trail_id === trailId
      );

      if (foundContent && foundContent.content_id) {
        setContent(foundContent);
      }
    }
  }, []);

  useEffect(getSelectedContent, [getSelectedContent]);

  const closeCard = () => {
    setShowNextCard(false);
    setTimesUp(true);
  };

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      setHideBackButton(true);

      checkView();
    }, 5000);
  }, [checkView]);

  return (
    <PlayerContentContainer>
      {content && (
        <>
          <ContainerButton isHideButton={hideBackButton}>
            <ReturnToDetailPage onClick={handleReturnToDetails}>
              <img src={BackArrow} alt="back arrow" />
              <span>{content.title}</span>
            </ReturnToDetailPage>
          </ContainerButton>

          {showNextCard && nextContent && !timesUp ? (
            <KeepWatchingCard trailId={trailId} onClose={() => closeCard()} nextContent={nextContent} />
          ) : null}
        </>
      )}

      {shouldShowPlayer && (
        <PlayerSambaVideos
          contentUrl={`${contentUrl}?captionTheme=[fffff,null,pt-br,null]`}
          resume={resumeTime}
          controlsToEnable={[
            'play',
            'pause',
            'quality',
            'fullscreen',
            'time',
            'volume',
            'backward',
            'forward',
            'seekbar',
            'settings',
            'subtitles',
            'cast',
          ]}
          getEventListeners={getEventListeners}
        />
      )}
    </PlayerContentContainer>
  );
};

export default PlayerContent;
