import { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';

import { FiHome, FiSearch, FiPlayCircle, FiCheck, FiRadio } from 'react-icons/fi';
import { HiOutlineUser } from 'react-icons/hi';

import Logo from '../../../assets/logo.svg';
import Notification from '../../../assets/icon-notification.svg';
import User from '../../../assets/icon-user.svg';
import Search from '../../../assets/icon-search.svg';
// import { ReactComponent as LogoKids } from '../../../assets/logo-kids.svg';
import LogoKids from '../../../assets/image-logo-kids.png';
import {
  Nav,
  NavLogo,
  NavList,
  NavItem,
  NavSearch,
  ButtonWrapper,
  NavButton,
  SubMenu,
  MobileHeader,
  MobileNavigator,
  ProfileMenu,
  NotificationsContainer,
} from './style';
import { Desktop, Mobile } from '../../../utils/responsiveRules';
import { getNotifications } from '../../../services/notification';
import { memo } from 'react';
import Submenu from './Submenu';
import { getKidsToken } from 'services/userToken';
import { ContextRoute } from 'utils/context';
import { NotificationCard } from 'components/NotificationCard';
import { INotification } from 'components/NotificationCard/types';

function NavBar() {
  const { handleIsKidTrue } = useContext(ContextRoute);
  const [searchTerm, setSearchedTerm] = useState('');
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState<any>();
  const [hasNotification, setHasNotification] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const history = useHistory();
  const { term } = useParams<any>();
  const user = localStorage.getItem('user') || '{}';

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (searchTerm) history.replace(`/search/${searchTerm}`);
  };
  const updateTerm = (newTerm: string) => {
    setSearchedTerm(newTerm);
  };
  const getAllNotifications = async () => {
    const notifications = await getNotifications();
    if (notifications.length) setHasNotification(true)
    setNotifications(notifications);
  };

  const handleKidsProfile = async () => {
    const { user_id } = JSON.parse(user);
    const { api_token } = await getKidsToken(user_id);
    localStorage.setItem('api_token', api_token);
    localStorage.setItem('kids', 'true');
    handleIsKidTrue();
    history.push('/kids');
  };

  useEffect(() => {
    if (term) {
      updateTerm(term);
    }
    getAllNotifications();
  }, [term]);

  const activeProfile = showUserMenu || history.location.pathname.includes('profile');

  const handleLogout = () => {
    localStorage.removeItem('api_token');
    localStorage.removeItem('user');
    localStorage.removeItem('payment-token-teo');
    history.push('/');
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Desktop>
        <Nav isScrolled={isScrolled}>
          <NavLogo to="/home">
            <img src={Logo} alt="TeoMídia" />
          </NavLogo>
          <NavList>
            <NavItem>
              <NavLink to="/home">Início</NavLink>
            </NavItem>
            <Submenu title="Filmes">
              <NavLink to="/movies">Filmes</NavLink>
              <NavLink to="/series">Séries</NavLink>
              <NavLink to="/documentary">Documentários</NavLink>
            </Submenu>

            <Submenu title="Cursos">
              <NavLink to="/courses">Cursos</NavLink>
              <NavLink to="/lectures">Palestras</NavLink>
              <NavLink to="/sermon">Sermões</NavLink>
            </Submenu>

            <Submenu title="Músicas">
              <NavLink to="/shows">Shows, Apresentações e Videoclips</NavLink>
              <NavLink to="/albums">Álbuns Musicais</NavLink>
            </Submenu>

            <NavItem>
              <NavLink to="/live">Ao vivo</NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/my-list">Minha lista</NavLink>
            </NavItem>

            <NavItem>
              <button className="btn-infantil" onClick={handleKidsProfile}>
                <p>Infantil</p>
              </button>
            </NavItem>
          </NavList>
          <NavSearch onSubmit={(e) => handleSearch(e)}>
            <img src={Search} alt="" />
            <input
              type="text"
              placeholder="Busca"
              value={searchTerm}
              onChange={(e) => setSearchedTerm(e.target.value)}
            />
          </NavSearch>
          <ButtonWrapper>
            <NavButton
              onClick={() => {
                setShowNotifications(!showNotifications);
                setShowUserMenu(false);
              }}
            >
              {hasNotification ? <div className='notification-dot' /> : null}
              <img src={Notification} alt="" />
            </NavButton>
            {showNotifications && !showUserMenu && (
              <NotificationsContainer notifications={true}>

                {notifications?.map((notification: INotification) => (
                  <NotificationCard notification={notification} key={`${notification.id}`} />
                ))}
                {notifications?.length <= 0 && <p>Nenhuma nova notificação</p>}
              </NotificationsContainer>
            )}
            <NavButton
              onClick={() => {
                setShowUserMenu(!showUserMenu);
                setShowNotifications(false);
              }}
            >
              <img src={User} alt="Icon usuário" />
            </NavButton>
            {showUserMenu && !showNotifications && (
              <SubMenu>
                <Link to="/profile">Minha conta</Link>
                <button onClick={handleKidsProfile}>Ativar perfil Kids</button>
                <button onClick={handleLogout}>Sair</button>
              </SubMenu>
            )}
          </ButtonWrapper>
        </Nav>
      </Desktop>

      <Mobile>
        <MobileHeader>
          <Link to="/home">
            <img src={Logo} alt="Logo TeoMídia" style={{ width: 100 }} />
          </Link>
          <Link to="/search">
            <img src={Search} alt="Pesquisa" />
          </Link>
        </MobileHeader>
        <MobileNavigator>
          <ul>
            <li className={history.location.pathname === '/home' ? 'active' : ''}>
              <NavLink to="/home">
                <FiHome />
                <span>Home</span>
              </NavLink>
            </li>
            <li className={history.location.pathname === '/catalog' ? 'active' : ''}>
              <NavLink to="/catalog">
                <FiPlayCircle />
                <span>Catálogo</span>
              </NavLink>
            </li>
            <li className={history.location.pathname === '/live' ? 'active' : ''}>
              <NavLink to="/live">
                <FiRadio />
                <span>Live</span>
              </NavLink>
            </li>
            <li className={history.location.pathname === '/my-list' ? 'active' : ''}>
              <NavLink to="/my-list">
                <FiCheck />
                <span>Minha Lista</span>
              </NavLink>
            </li>
            <li className={history.location.pathname === '/profile' ? 'active' : ''}>
              <div onClick={() => setShowProfileMenu(!showProfileMenu)} className={showProfileMenu ? 'active' : ''}>
                <HiOutlineUser />
                <span className={showProfileMenu ? 'active' : ''}>Conta</span>
              </div>
              {showProfileMenu && (
                <ProfileMenu>
                  <NavLink to="/profile">
                    <p>Ver Perfil</p>
                  </NavLink>
                  <button type="button" onClick={handleKidsProfile}>
                    Ativar perfil Kids
                  </button>
                  <button onClick={handleLogout}>Sair</button>
                </ProfileMenu>
              )}
            </li>
          </ul>
        </MobileNavigator>
      </Mobile>
    </>
  );
}

export default memo(NavBar);
