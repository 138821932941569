import styled from 'styled-components';
import { px2rem } from '../../utils/fontUtil';

const ProfileWrapper = styled.div`
  background-color: var(--default-background);
  padding: ${px2rem(90)} 0 ${px2rem(120)};

  @media screen and (min-width: 998px) {
    padding: ${px2rem(220)} 0 ${px2rem(120)};
  }
`;

const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 998px) {
    flex-direction: row;
  }

  div {
    h1 {
      color: #fff;
      font-size: ${px2rem(24)};
    }
    .kids {
      background-color: transparent;
      text-transform: uppercase;
      color: var(--default-orange);
      border: none;
      font-family: 'Lato';
      margin: 20px 0;
    }
    > button.cancel {
      background-color: red;
      text-transform: uppercase;
      border: 1px solid #fff;
      height: ${px2rem(56)};
      padding: 0 ${px2rem(24)};
      border-radius: ${px2rem(8)};
      color: #fff;
      font-size: ${px2rem(18)};
      font-family: 'Lato';
      margin-top: ${px2rem(24)};
      margin-bottom: ${px2rem(48)};
      cursor: pointer;
      @media screen and (min-width: 998px) {
        margin-top: ${px2rem(100)};
        margin-bottom: ${px2rem(0)};
      }
    }
    > button.normal {
      background-color: transparent;
      text-transform: uppercase;
      border: 1px solid #fff;
      height: ${px2rem(56)};
      padding: 0 ${px2rem(24)};
      border-radius: ${px2rem(8)};
      color: #fff;
      font-size: ${px2rem(18)};
      font-family: 'Lato';
      margin-top: ${px2rem(24)};
      margin-bottom: ${px2rem(48)};
      cursor: pointer;
      @media screen and (min-width: 998px) {
        margin-top: ${px2rem(100)};
        margin-bottom: ${px2rem(0)};
      }
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: ${px2rem(16)};
      line-height: ${px2rem(14)};
      margin-top: ${px2rem(18)};
      color: #fff;
      span {
        color: var(--default-orange);
        font-weight: bold;
      }
    }
    @media screen and (min-width: 998px) {
      h1 {
        font-size: ${px2rem(48)};
      }
    }
  }

  div.buttons {
    display: flex;
    flex-direction: column;
  }
`;

export { ProfileWrapper, Content };
