import React, { useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';

import { BsExclamationOctagon } from 'react-icons/bs';

import { Container, Error, Form } from './style';
import ButtonLoading from 'components/ButtonLoading';
import Input from 'components/Input';
import { passwordPattern } from 'utils/regex';
import { changePassword } from 'services/cognito';

interface IProps {
  onFinish: (success: boolean) => void; onCancel: () => void; onClose: () => void
}

const ChangePassword: React.FC<IProps> = ({ onFinish, onCancel, onClose }: IProps) => {

  const [isLoading, setLoading] = useState(false);
  const [passwords, setPasswords] = useState({} as { current: string, new: string, confirm: string });
  const [errorPassword, setErrorPassword] = useState("");

  const handleEditPassword = useCallback(async (e) => {
    e.preventDefault();

    if (!passwords.current && !passwords.new && !passwords.confirm) {
      setErrorPassword("Informe os dados para alterar a sua senha.");
      return;
    } else {

      setErrorPassword("");

      if (!passwords.current) {
        setErrorPassword("Informe a senha atual.");
        return;
      }

      if (!passwordPattern.test(passwords.new)) {
        setErrorPassword("Sua senha deve conter maíusculas e minúsculas, números e símbolos.");
      }

      if (passwords.current.length < 8) {
        setErrorPassword("Sua senha deve conter no mínimo 8 caracteres.");
        return;
      }

      if (passwords.new !== passwords.confirm) {
        setErrorPassword("Nova senha e confirmação de nova senha não são iguais.");
        return;
      }

      try {
        setLoading(true);
        await changePassword({ old_password: passwords.current, new_password: passwords.new });
        onFinish(true);
      } catch (e) {
        setErrorPassword("Não foi possível alterar a sua senha. Confirme a sua senha atual.");
      } finally {
        setLoading(false);
      }
    }

  }, [onFinish, passwords])


  return (
    <Container>
      <div>
        <button onClick={onClose} >
          <FiX />
        </button>

        <h2>Alteração de Senha</h2>

        <div>
          <Form onSubmit={handleEditPassword} >

            {errorPassword && (<Error>
              <BsExclamationOctagon /> <p>{errorPassword}</p>
            </Error>)}

            <Input
              label="Senha Atual"
              type="password"
              value={passwords.current}
              autoFocus
              onChange={e => setPasswords({ ...passwords, current: e.target.value })}
            />

            <Input
              label="Nova Senha"
              type="password"
              value={passwords.new}
              onChange={e => setPasswords({ ...passwords, new: e.target.value })}
            />

            <Input
              label="Confirmação de Nova Senha"
              type="password"
              value={passwords.confirm}
              onChange={e => setPasswords({ ...passwords, confirm: e.target.value })}
            />

            <footer>
              <button type="button" onClick={onCancel} >Cancelar</button>
              <ButtonLoading type="submit" isLoading={isLoading} >Salvar</ButtonLoading>
            </footer>

          </Form>
        </div>
      </div>
    </Container>
  );
}

export default ChangePassword;