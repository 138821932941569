import { Wrapper, FlowTitle, Form, Grid, RightContent, LeftContent, WithFooterWrapper } from '../style';
import IconEye from 'assets/eye.svg';
import IconArrowBack from 'assets/icon-arrow-back.svg';
import { Link, useHistory } from 'react-router-dom';
import { Desktop } from 'utils/responsiveRules';
import { useState } from 'react';
import { useFormik } from 'formik';
import { LoginSchema } from './loginValidation';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/AuthContext'

export default function LoginBlock() {
  const [showPassword, setShowPassord] = useState(false);
  const history = useHistory();
  const { login } = useAuth()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    validateOnMount: true,
    onSubmit: async ({ email, password }) => {
      await login(email, password)
    },
  });

  return (
    <WithFooterWrapper>
      <Wrapper>
        <Grid>
          <RightContent>
            <Link to="/" className="back">
              <img src={IconArrowBack} alt="" />
            </Link>
            <FlowTitle className='title'>
              Acesse
              <br /> sua conta.
            </FlowTitle>
            <Link to="/register">
              <p className='registerLink'>Ainda não assinou? Junte-se a nós.</p>
            </Link>
          </RightContent>
          <LeftContent>
            <hr />
            <Form onSubmit={formik.handleSubmit}>
              <div className="default-input ">
                <input
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={formik.errors.email && formik.touched.email ? 'error' : ''}
                />
                <label>E-mail</label>
                <span className="error">{formik.errors.email}</span>
              </div>
              <div className="default-input">
                <input
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Senha"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.password && formik.touched.password ? 'error' : ''}
                />
                <label>Senha</label>
                <span className="error">{formik.errors.password}</span>
                <button type="button" onClick={() => setShowPassord(!showPassword)}>
                  <img src={IconEye} alt="" />
                </button>
                <Link to="/recover">
                  <p className='forgotPasswordLink'>Esqueci minha senha</p>
                </Link>
              </div>
              <div className="buttons">
                <Desktop>
                  <Link to="/">Cancelar</Link>
                </Desktop>
                <button type="submit" disabled={!formik.isValid}>
                  Entrar
                </button>
              </div>
            </Form>
          </LeftContent>
        </Grid>
      </Wrapper>
    </WithFooterWrapper>
  );
}
