import httpClient from '../../http';

const getAllCourses = async () => {
  const courses = (await httpClient.get('/trails?tags=cursos', {
    params: {
      is_active: true
    }
  })).data;
  return courses;
};

const getCourse = async (id: string) => {
  const course = (await httpClient.get(`/courses/${id}`)).data;
  return course;
};

const startCourse = async (courseId: string, trailId: string) => {
  const startResponse = httpClient.post('/trail-course-users', {
    course_id: courseId,
    trail_id: trailId,
  });

  return startResponse;
};

const finishCourse = async (courseId: string, trailId: string) => {
  const startResponse = httpClient.put('/trail-course-users/finish', {
    course_id: courseId,
    trail_id: trailId,
  });

  return startResponse;
};

export { getAllCourses, getCourse, startCourse, finishCourse };
