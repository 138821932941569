import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';
import BePremiumModal from 'components/Modals/BePremiumSmallSize';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getKidsToken } from 'services/userToken';
import cleanStorage from 'utils/cleanLocalStorage';
import { ContextBePremiumModal, ContextRoute } from 'utils/context';
import { Mobile } from 'utils/responsiveRules';
import Footer from '../../components/Commons/Footer';
import NavBar from '../../components/Commons/NavBar';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import ProfileForm from '../../components/Profile/ProfileForm';
import { cancelPayment } from '../../services/payment';
import { getDeactivateUser, getMe } from '../../services/user';
import { User } from '../../services/user/models/user-interface';
import { ProfileWrapper, Content } from './style';

export default function Profile() {
  const { handleIsKidTrue } = useContext(ContextRoute);
  const [user, setUser] = useState({} as User);
  const [openModal, setOpenModal] = useState(false);

  const [deactiveModal, setDeactiveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = localStorage.getItem('user') || '{}';
  const history = useHistory();

  const { openModalPremium } = useContext(ContextBePremiumModal);

  useEffect(() => {
    const getUser = async () => {
      const data = await getMe();
      setUser(data);
    };

    getUser();
  }, []);

  const handleCancelPlan = useCallback(async () => {
    setIsLoading(true);
    await cancelPayment();
    toast.success('Assinatura foi migrada com êxito para o Plano Básico');
    setOpenModal(false);

    const user = await getMe();
    const dateOfEnd = new Date(user.end_at);
    if (dateOfEnd.getTime() < new Date().getTime()) {
      setUser({ ...user, plan: 'free' });
    }
    setIsLoading(false);
    // history.go(0);
    history.push('/');
  }, [user]);

  const daysLeft = useMemo(() => {
    const dateOfEnd = new Date(user.end_at);
    const dateDiference = dateOfEnd.getTime() - new Date().getTime();
    const diffDays = Math.floor(dateDiference / (1000 * 60 * 60 * 24));
    return diffDays;
  }, [user]);

  const handleKidsProfile = async () => {
    const { user_id } = JSON.parse(userInfo);
    const { api_token } = await getKidsToken(user_id);
    localStorage.setItem('api_token', api_token);
    localStorage.setItem('kids', 'true');
    handleIsKidTrue();
    history.push('/kids');
  };

  const handleDeactiveUser = useCallback(async () => {
    setIsLoading(true);
    const user = await getMe();
    await getDeactivateUser(user.user_id);
    cleanStorage();
    setIsLoading(false);
    history.push('/');
  }, [history]);

  return (
    <div>
      {openModalPremium && <BePremiumModal />}
      <NavBar />

      {openModal && (
        <ConfirmModal
          title=""
          message="Ao migrar para o plano Básico você perderá o acesso ao conteúdo exclusivo, tem certeza que deseja fazer isso?"
          onClose={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          onConfirm={() => handleCancelPlan()}
          isLoadingButton={isLoading}
        />
      )}

      {deactiveModal && (
        <ConfirmModal
          title=""
          message="Você está cancelando a sua conta, tem certeza de que deseja fazer isso?"
          onClose={() => setDeactiveModal(false)}
          onCancel={() => setDeactiveModal(false)}
          onConfirm={() => handleDeactiveUser()}
          isLoadingButton={isLoading}
        />
      )}

      <ProfileWrapper>
        <Content>
          <div>
            <h1>Minha conta</h1>
            <Mobile>
              <button className="kids" type="button" onClick={handleKidsProfile}>
                Ativar perfil Kids
              </button>
            </Mobile>
            <div className="buttons">
              
              {!user.end_at ? (
                <>
                  {user.plan !== 'free' && (
                    <button
                      type="button"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                      className="normal"
                    >
                      Mudar para plano gratuito
                    </button>
                  )}
                </>
              ) : (
                <>
                  {user.plan !== 'free' && daysLeft > 0 && (
                    <p>
                      Restam{' '}
                      <span>
                        {daysLeft} dia{daysLeft !== 1 ? 's' : ''}
                      </span>{' '}
                      de assinatura premium
                    </p>
                  )}
                </>
              )}
              <button
                type="button"
                className="cancel"
                onClick={() => {
                  setDeactiveModal(true);
                }}
              >
                Cancelar Minha Conta
              </button>
            </div>
          </div>
          <ProfileForm user={user} daysLeft={daysLeft} />
        </Content>
      </ProfileWrapper>
      <Footer />
      <MobileNavigatorSpace />
    </div>
  );
}
