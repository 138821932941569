import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ebebeb;

  > section {
    min-height: calc(100vh - 135px);
    padding: 100px 0 60px;
  }
`;
