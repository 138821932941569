import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';

export const ErrorWrapper = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: 14px;
  margin-top: 4px;
  text-align: left;
`;

export const Error = styled.div`
  width: auto;
  color: var(--error-color);
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 32px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background: #222;
  line-height: 24px;

  svg {
    margin-right: 16px;
    font-size: 32px;
  }
`;

export const Form = styled.form`
  width: 100%;

  div.one-row {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
  }

  @media screen and (min-width: 998px) {
    div.default-input {
      input {
        font-size: ${px2rem(18)};
      }
      label {
        font-size: ${px2rem(18)};
      }
    }
  }
`;

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: ${px2rem(24)};
    line-height: ${px2rem(32)};
    margin-bottom: ${px2rem(32)};
    color: #fff;
  }

  > div {
    max-width: 500px;
    width: 100%;
    background: var(--default-background);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 80px 24px 24px;
    position: relative;

    > button {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid #fff;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: ${px2rem(24)};
      line-height: ${px2rem(32)};
      color: #fff;
      text-align: center;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      background-color: transparent;
      text-transform: uppercase;
      border: 1px solid #fff;
      height: ${px2rem(40)};
      padding: 0 ${px2rem(24)};
      border-radius: ${px2rem(8)};
      color: #fff;
      font-size: ${px2rem(18)};
      font-family: 'Lato';
      font-weight: 700;
      padding: 0 ${px2rem(20)};
      cursor: pointer;
    }

    button + button {
      background-color: var(--default-orange);
      border: none;
      margin-left: 16px;
    }
  }
`;
