import styled from 'styled-components';
import { px2rem } from '../../../utils/fontUtil';
import ArrowDrop from '../../../assets/arrow-drop.svg';

const SeasonWrapper = styled.div`
  padding: 0 20px;
  @media screen and (min-width:998px){
    margin-left: ${px2rem(108)};
    padding-top: ${px2rem(64)};
  }
`

const SeasonSelector = styled.select`
  border:1px solid #FFF;
  background-color: transparent;
  margin-bottom: ${px2rem(35)};
  height: ${px2rem(40)};
  padding: 0 ${px2rem(15)};
  width: ${px2rem(228)};
  border-radius: ${px2rem(6)};
  color: #FFF;
  background-image: url(${ArrowDrop});
  background-repeat: no-repeat;
  background-position: 95%;
  &, option{
    font-size: ${px2rem(16)};
    font-family: 'Lato';
    font-weight: 400;
  }
  option{
    color: #000;
  }
`;

export {

  SeasonWrapper,
  SeasonSelector
}