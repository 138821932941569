import Footer from 'components/Commons/Footer';
import NavBar from 'components/Commons/NavBar';
import MobileNavigatorSpace from 'components/Commons/MobileNavigatorSpace';
import RelatedCarousel from 'components/Commons/RelatedCarousel';
import MovieCard from 'components/Movies/MovieCard';
import { useEffect, useState } from 'react';
import { getContentTag, getTrailTag } from 'services/tags';
import { TitleList, ListContents } from './style';
import GridSkeleton from 'components/Commons/GridSkeleton';

export default function Catalog() {
  const [movies, setMovies] = useState<any>();
  const [lectures, setLectures] = useState<any>();
  const [sermon, setSermon] = useState<any>();
  const [series, setSeries] = useState<any>();
  const [documentary, setDocumentary] = useState<any>();
  const [music, setMusic] = useState<any>();
  const [courses, setCourses] = useState<any>();

  const getContents = async () => {
    const resultContent = await getContentTag('filmes');
    const lectures = await getContentTag('palestras');
    const sermon = await getContentTag('sermoes');
    const series = await getTrailTag('series');
    const documentary = await getContentTag('documentarios');
    const music = await getTrailTag('musicas');
    const courses = await getTrailTag('cursos');

    setMovies(resultContent.slice(0, 13));
    setLectures(lectures.slice(0, 13));
    setSermon(sermon.slice(0, 13));
    setSeries(series.slice(0, 13));
    setDocumentary(documentary.slice(0, 13));
    setMusic(music.slice(0, 13));
    setCourses(courses.slice(0, 13));
  };
  useEffect(() => {
    getContents();
  }, []);

  return (
    <>
      <NavBar />
      <ListContents>
        <TitleList>Catálogo</TitleList>
        {!movies && <GridSkeleton />}
        {movies?.length && (
          <RelatedCarousel title="Filmes" seeMore="/movies">
            {movies?.map((movie: any, key: any) => (
              <MovieCard to={`/movies/${movie?.content_id}`} data={movie} key={`movie-${key}`} />
            ))}
          </RelatedCarousel>
        )}
        {!lectures && <GridSkeleton />}
        {lectures?.length > 0 && (
          <RelatedCarousel title="Palestras" seeMore="/lectures">
            {lectures?.map((lecture: any, key: any) => (
              <MovieCard to={`/movies/${lecture?.content_id}`} data={lecture} key={`lectures-${key}`} />
            ))}
          </RelatedCarousel>
        )}
        {!sermon && <GridSkeleton />}
        {sermon?.length > 0 && (
          <RelatedCarousel title="Sermões" seeMore="/sermon">
            {sermon?.map((sermon: any, key: any) => (
              <MovieCard to={`/movies/${sermon?.content_id}`} data={sermon} key={`sermon-${key}`} />
            ))}
          </RelatedCarousel>
        )}
        {!series && <GridSkeleton />}
        {series?.length > 0 && (
          <RelatedCarousel title="Séries" seeMore="/series">
            {series?.map((serie: any, key: any) => (
              <MovieCard to={`/trail/${serie?.trail_id}`} data={serie} key={`serie-${key}`} />
            ))}
          </RelatedCarousel>
        )}
        {!documentary && <GridSkeleton />}
        {documentary?.length > 0 && (
          <RelatedCarousel title="Documentários" seeMore="/documentary">
            {documentary?.map((content: any, key: any) => (
              <MovieCard to={`/movie/${content?.trail_id}`} data={content} key={`serie-${key}`} />
            ))}
          </RelatedCarousel>
        )}
        {!music && <GridSkeleton />}
        {music?.length > 0 && (
          <RelatedCarousel title="Músicas" seeMore="/music">
            {music?.map((content: any, key: any) => (
              <MovieCard to={`/movie/${content?.trail_id}`} data={content} key={`serie-${key}`} />
            ))}
          </RelatedCarousel>
        )}
        {!courses && <GridSkeleton />}
        {courses?.length > 0 && (
          <RelatedCarousel title="Cursos" seeMore="/courses">
            {courses?.map((content: any, key: any) => (
              <MovieCard to={`/movie/${content?.trail_id}`} data={content} key={`serie-${key}`} />
            ))}
          </RelatedCarousel>
        )}
      </ListContents>
      <Footer />
      <MobileNavigatorSpace />
    </>
  );
}
