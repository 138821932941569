import { sendEventRdStation } from "services/rd";

const checkPathName = (pathname: string) => {
  if (pathname.includes('home')) {
    return '/home';
  } else if (pathname.includes('movie')) {
    return '/movies';
  } else if (pathname.includes('serie')) {
    return '/series';
  } else if (pathname.includes('documentary')) {
    return '/documentary';
  } else if (pathname.includes('course')) {
    return '/courses';
  } else if (pathname.includes('lecture')) {
    return '/lectures';
  } else if (pathname.includes('sermon')) {
    return '/sermon';
  } else if (pathname.includes('music')) {
    return '/music';
  } else if (pathname.includes('live')) {
    return '/live';
  } else if (pathname.includes('my-list')) {
    return '/my-list';
  } else if (pathname.includes('sermon')) {
    return '/sermon';
  } else if (pathname.includes('kids')) {
    return '/kids';
  } else if (pathname.includes('trail')) {
    return '/trail';
  } else if (pathname.includes('show')) {
    return '/show';
  } else if (pathname.includes('author')) {
    return '/author';
  } else {
    return '';
  }
}

export async function sendRdStation(pathname: string) {
  try {
    await sendEventRdStation({
      event_type: 'CONVERSION',
      event_family: 'CDP',
      payload: {
        conversion_identifier: checkPathName(pathname),
      },
    });
  } catch (error: any | unknown) {
    console.error(error);
  }
};
